import React, { useEffect, useState } from "react";
import "./Instructor.scss";
import {
  EyeOutlined,
  SearchOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from "@ant-design/icons";
import {
  Table,
  Button,
  Input,
  Modal,
  message,
  Card,
  Typography,
  Divider,
  Row,
  Col,
} from "antd";
import InstructorForm from "./InstructorForm/InstructorForm";
import Buttons from "../../Components/Buttons/Buttons";
import { getApi, postApi } from "../../Utlis/apiService";
import {
  APPROVE_INSTRUCTOR,
  GET_ALL_INSTRUCTORS,
  GET_INSTRUCTOR_SLOTS,
} from "../../Utlis/apiPath";
import { debounce } from "lodash";
import Loader from "../../Components/Loader/Loader";
import TextArea from "antd/es/input/TextArea";
import { errorToast, successToast } from "../../Services/ToastHelper";
import { useNavigate } from "react-router-dom";
const { Title, Text } = Typography;

const Instructor = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [showAddInstructorForm, setShowAddInstructorForm] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [isApproved, setIsApproved] = useState(true);
  const [isDenied, setIsDenied] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [denialMessage, setDenialMessage] = useState("");
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [modalType, setModalType] = useState("");
  const [pricePerHour, setPricePerHour] = useState("");
  const [updateData, setUpdateData] = useState();
  const Navigate = useNavigate();
  const [isAscending, setIsAscending] = useState("");
  const [pagination, setPagination] = useState({
    current: 1, // Ensure it starts from 1
    pageSize: 10,
    total: 0,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedInstructor, setSelectedInstructor] = useState(null);
  console.log(tableData, "tableData");

  const showInstructorDetails = (record) => {
    setSelectedInstructor(record);
    setIsModalOpen(true);
  };

  const getPageSizeOptions = () => {
    return totalRecords <= 50 ? ["10", "20", "50"] : ["15", "25", "50", "100"];
  };

  const handleAddNewInstructor = () => {
    setShowAddInstructorForm(true);
  };

  const handleCloseForm = () => {
    setShowAddInstructorForm(false);
  };
  const fetchTableData = async (page = 1, pageSize = 10, searchValue = "") => {
    setIsLoading(true);
    try {
      const pageIndex1 = page - 1; // Assuming API uses 0-based indexing
      const search1 = searchValue !== undefined ? searchValue : search;

      const response = await getApi(GET_ALL_INSTRUCTORS, {
        params: {
          pageSize,
          search: search1,
          pageIndex: pageIndex1,
          isApproved,
          isDenied,
          isAscending,
        },
      });

      if (response.status === 200) {
        setTableData(response.data?.instructors || []);
        setTotalRecords(response.data?.totalRecords);
        setPagination((prev) => ({
          ...prev,
          current: page,
          total: response.data?.totalRecords,
        }));
      } else if (response.status === 404) {
        setTableData([]);
        setTotalRecords(0);
        console.warn("No data found for the given criteria.");
      } else {
        setTableData([]);
      }
    } catch (error) {
      setTableData([]);
      console.error("Error fetching table data:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInstructorAccept = async (record, isApproved) => {
    setIsLoading(true);
    try {
      const { status, message } = await postApi(APPROVE_INSTRUCTOR, {
        instructorId: selectedRecord.instructorId,
        isApproved: true,
        isDenied: false,
      });

      if (status === 200) {
        fetchTableData(1, 10);
        handleModalClose();
        successToast(message);
      } else {
        debugger;
        errorToast(message);
      }
    } catch (error) {
      console.error("Error updating instructor status:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInstructorDenei = async (record, isApproved) => {
    setIsLoading(true);
    try {
      const { status } = await postApi(APPROVE_INSTRUCTOR, {
        instructorId: record.instructorId,
        isApproved: isApproved,
        isDenied: !isApproved,
        message: "Insufficient qualifications",
      });

      if (status === 200) {
        fetchTableData(1, 10);
      }
    } catch (error) {
      console.error("Error updating instructor status:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const showDenialModal = (record) => {
    setSelectedRecord(record);
    setModalType("deny");
    setIsModalVisible(true);
  };

  const showAcceptModal = (record) => {
    setSelectedRecord(record);
    setModalType("accept");
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);

    setDenialMessage("");
    setPricePerHour("");
  };

  const handleModalSubmit = async () => {
    if (!denialMessage.trim()) {
      errorToast("Please enter a denial message.");
      return;
    }
    setIsModalVisible(false);
    setIsLoading(true);
    try {
      const { status, message } = await postApi(APPROVE_INSTRUCTOR, {
        instructorId: selectedRecord.instructorId,
        isApproved: false,
        isDenied: true,
        message: denialMessage,
      });

      if (status === 200) {
        successToast(message);
        fetchTableData(1, 10);
        handleModalClose();
      } else {
        errorToast(message);
      }
    } catch (error) {
      console.error("Error denying instructor:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTableData(1, 10);
  }, [isAscending, isApproved, isDenied]);

  const handleSorting = () => {
    setIsAscending((prevState) => (prevState === "" ? false : !prevState));
  };

  const handleSearch = debounce((value) => {
    setSearchTerm(value);
  }, 300);

  const onSearchChange = (e) => {
    handleSearch(e.target.value);
  };

  const debouncedFetchClasses = debounce((searchValue) => {
    setSearch(searchValue);
    const page = 1;
    setPageIndex(1);
    fetchTableData(page, pageSize, searchValue);
  }, 500);

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    setPageIndex(1);
    setPageIndex(current);
    setPageSize(pageSize);
    fetchTableData(current, pageSize);
  };

  const handleTabChange = (tabKey) => {
    setActiveTab(tabKey);
    setPageIndex(1); // Reset page index to 1 when switching tabs
    setPagination((prev) => ({ ...prev, current: 1 })); // Reset Ant Design pagination

    if (tabKey === "1") {
      setIsApproved(true);
      setIsDenied(false);
    } else if (tabKey === "2") {
      setIsApproved(false);
      setIsDenied(false);
    } else if (tabKey === "3") {
      setIsApproved(false);
      setIsDenied(true);
    }
  };

  const handleManageInstructor = async (record) => {
    Navigate(`/manage-instructor/${record.instructorId}`);
  };
  const handleViewInstructor = async (record) => {
    Navigate(`/view-instructor/${record.instructorId}`);
  };

  const instructorListColumns = [
    {
      title: "SL No",
      key: "slNo",
      render: (text, record, index) =>
        (pagination.current - 1) * pagination.pageSize + (index + 1),
    },
    {
      title: "Instructor Name",
      render: (_, record) => `${record.firstName} ${record.lastName}`,
    },
    {
      title: "Class",
      dataIndex: "class",
      render: (_, record) => (
        <>
          <Button
            onClick={() => {
              handleViewInstructor(record);
            }}
            size="small"
            style={{ marginRight: "8px" }}
          >
            view Class
          </Button>
          {/* <Button size="small" type="default">
          Send Invite
        </Button> */}
        </>
      ),
    },
    // { title: "Calendar", dataIndex: "calendar" },
    // { title: "Mode", dataIndex: "mode" },
    { title: "Email", dataIndex: "mailId" },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <>
          <Button
            onClick={() => {
              handleManageInstructor(record);
            }}
            size="small"
            style={{ marginRight: "8px" }}
          >
            Manage
          </Button>
          {/* <Button size="small" type="default">
            Send Invite
          </Button> */}
          <div
            style={{
              display: "inline-block",
              padding: "4px 8px",
              border: "2px solid #1890ff",
              borderRadius: "4px",
              cursor: "pointer",
            }}
            onClick={() => showInstructorDetails(record)}
          >
            <EyeOutlined
              style={{
                fontSize: "16px",
                color: "#1890ff",
              }}
            />
          </div>
        </>
      ),
    },
  ];

  const instructorSignupColumns = [
    {
      title: "SL No",
      key: "slNo",
      render: (text, record, index) =>
        (pagination.current - 1) * pagination.pageSize + (index + 1),
    },
    {
      title: "Instructor Name",
      render: (_, record) => `${record.firstName} ${record.lastName}`, // Combine two fields
    },

    { title: "Mail ID", dataIndex: "mailId" },
    { title: "Mobile No", dataIndex: "mobileNo" },
    { title: "Gender", dataIndex: "gender" },
    { title: "Education", dataIndex: "education" },
    { title: "Experience", dataIndex: "experience" },
    {
      title: "Expertise",
      dataIndex: "expertise",
      render: (expertise) => expertise?.join(", "), // Display as comma-separated string
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <>
          <Button
            size="small"
            type="primary"
            style={{ marginRight: "8px" }}
            onClick={() => showAcceptModal(record)}
          >
            Accept
          </Button>
          <Button
            size="small"
            type="danger"
            onClick={() => showDenialModal(record)}
          >
            Deny
          </Button>
          <div
            style={{
              display: "inline-block",
              padding: "4px 8px",
              border: "2px solid #1890ff",
              borderRadius: "4px",
              cursor: "pointer",
            }}
            onClick={() => showInstructorDetails(record)}
          >
            <EyeOutlined
              style={{
                fontSize: "16px",
                color: "#1890ff",
              }}
            />
          </div>
        </>
      ),
    },
  ];

  const instructorDeniedColumns = [
    {
      title: "SL No",
      key: "slNo",
      render: (text, record, index) =>
        (pagination.current - 1) * pagination.pageSize + (index + 1),
    },
    {
      title: "Instructor Name",
      render: (_, record) => `${record.firstName} ${record.lastName}`,
    },
    { title: "Mail ID", dataIndex: "mailId" },
    { title: "Mobile No", dataIndex: "mobileNo" },
    { title: "Gender", dataIndex: "gender" },
    { title: "Education", dataIndex: "education" },
    { title: "Experience", dataIndex: "experience" },
    {
      title: "Expertise",
      dataIndex: "expertise",
      render: (expertise) => expertise?.join(", "),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <>
          <Button
            size="small"
            type="primary"
            style={{ marginRight: "8px" }}
            onClick={() => showAcceptModal(record)}
          >
            Accept
          </Button>
          <div
            style={{
              display: "inline-block",
              padding: "4px 8px",
              border: "2px solid #1890ff",
              borderRadius: "4px",
              cursor: "pointer",
            }}
            onClick={() => showInstructorDetails(record)}
          >
            <EyeOutlined
              style={{
                fontSize: "16px",
                color: "#1890ff",
              }}
            />
          </div>
        </>
      ),
    },
  ];

  return (
    <div className="inst">
      {isLoading && <Loader />}
      {!showAddInstructorForm && (
        <>
          <div className="inst_header">
            <h2>Instructor</h2>
            {!showAddInstructorForm && (
              <Buttons variant="primary" onClick={handleAddNewInstructor}>
                Add New Instructor
              </Buttons>
            )}
          </div>

          <div className="inst_content">
            <div className="inst_content_custom">
              <div
                className={activeTab === "1" ? "tab selected" : "tab"}
                onClick={() => handleTabChange("1")}
              >
                Instructor List
              </div>
              <div
                className={activeTab === "2" ? "tab selected" : "tab"}
                onClick={() => handleTabChange("2")}
              >
                Instructor Sign-up Request
              </div>
              <div
                className={activeTab === "3" ? "tab selected" : "tab"}
                onClick={() => handleTabChange("3")}
              >
                Denied List
              </div>
            </div>

            <div className="inst_content_search">
              <Input
                placeholder="Search"
                prefix={<SearchOutlined />}
                onChange={(e) => debouncedFetchClasses(e.target.value)}
              />
              <Button
                icon={
                  isAscending === true ? (
                    <SortAscendingOutlined />
                  ) : (
                    <SortDescendingOutlined />
                  )
                }
                className="sortButton"
                onClick={handleSorting}
              />
            </div>
          </div>

          <div className="inst_table">
            <Table
              columns={
                activeTab === "1"
                  ? instructorListColumns
                  : activeTab === "2"
                  ? instructorSignupColumns
                  : instructorDeniedColumns
              }
              dataSource={tableData}
              pagination={{
                // current: pageIndex,
                pageSize,
                total: totalRecords,
                showSizeChanger: true,
                pageSizeOptions: getPageSizeOptions(),
              }}
              loading={loading}
              onChange={handleTableChange}
              scroll={{ x: "max-content" }}
            />
          </div>
        </>
      )}
      {showAddInstructorForm && (
        <InstructorForm
          setShowAddInstructorForm={setShowAddInstructorForm}
          handleAddNewInstructor={handleAddNewInstructor}
          handleCloseForm={handleCloseForm}
          fetchTableData={fetchTableData}
          updateData={updateData}
          setUpdateData={setUpdateData}
        />
      )}
      <Modal
        title={modalType === "accept" ? "" : "Deny Instructor Request"}
        visible={isModalVisible}
        onCancel={handleModalClose}
        onOk={
          modalType === "accept" ? handleInstructorAccept : handleModalSubmit
        }
        okText="Submit"
        cancelButtonProps={{ style: { display: "none" } }}
      >
        {modalType === "deny" ? (
          <TextArea
            rows={4}
            placeholder="Enter denial reason"
            value={denialMessage}
            onChange={(e) => setDenialMessage(e.target.value)}
          />
        ) : (
          <p>Are you sure do you want to accept the instructor</p>
          // <Input
          //   type="number"
          //   placeholder="Enter price per hour"
          //   value={pricePerHour}
          //   onChange={(e) => setPricePerHour(e.target.value)}
          // />
        )}
      </Modal>
      <Modal
        title="Instructor Details"
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        centered
        width={500}
      >
        {selectedInstructor && (
          <Card bordered={false} style={{ padding: 20 }}>
            <Row justify="center" style={{ marginBottom: 16 }}>
              <img
                src={selectedInstructor.profileImage}
                alt={selectedInstructor.firstName}
                style={{
                  width: 120,
                  height: 120,
                  borderRadius: "50%",
                  border: "4px solid #1890ff",
                }}
              />
            </Row>
            <Row justify="center">
              <Title
                level={4}
              >{`${selectedInstructor.firstName} ${selectedInstructor.lastName}`}</Title>
            </Row>
            <Row justify="center" style={{ marginBottom: 16 }}>
              <Text type="secondary">{selectedInstructor.mailId}</Text>
            </Row>
            <Divider />
            <Row gutter={[16, 8]}>
              <Col span={12}>
                <Text>
                  <strong>📞 Mobile:</strong>
                </Text>
              </Col>
              <Col span={12}>
                <Text>
                  {selectedInstructor.countryCode} {selectedInstructor.mobileNo}
                </Text>
              </Col>
              <Col span={12}>
                <Text>
                  <strong>👤 Gender:</strong>
                </Text>
              </Col>
              <Col span={12}>
                <Text>{selectedInstructor.gender}</Text>
              </Col>
              <Col span={12}>
                <Text>
                  <strong>🎓 Education:</strong>
                </Text>
              </Col>
              <Col span={12}>
                <Text>{selectedInstructor.education}</Text>
              </Col>
              <Col span={12}>
                <Text>
                  <strong>💼 Experience:</strong>
                </Text>
              </Col>
              <Col span={12}>
                <Text>{selectedInstructor.experience} years</Text>
              </Col>
              <Col span={12}>
                <Text>
                  <strong>🏆 Expertise:</strong>
                </Text>
              </Col>
              <Col span={12}>
                <Text>{selectedInstructor.expertise.join(", ")}</Text>
              </Col>
              <Col span={12}>
                <Text>
                  <strong>✅ Status:</strong>
                </Text>
              </Col>
              <Col span={12}>
                <Text>{selectedInstructor.status}</Text>
              </Col>
            </Row>
          </Card>
        )}
      </Modal>
    </div>
  );
};

export default Instructor;
