import React, { useEffect, useState } from "react";
import "./Login.scss";
import CompanyLogo from "../../Assets/Images/logo1.jpg";
import Buttons from "../../Components/Buttons/Buttons";
import FormInputs from "../../Components/FormInputs/FormInputs";
import { useNavigate } from "react-router-dom";
import LoginBG from "../../Assets/Images/loginBg.jpg";
import { ADMIN_LOGIN_DETAILS } from "../../Utlis/apiPath";
import { getApi, postApi } from "../../Utlis/apiService";
import { errorToast, successToast } from "../../Services/ToastHelper";

const initialValues = {
  email: "",
  password: "",
};

const Login = () => {
  const navigate = useNavigate();
  const [login, setLogin] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    localStorage.clear();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setLogin({
      ...login,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const validateFields = () => {
    let errObj = { ...initialValues };

    if (!login.email) {
      errObj.email = "This field is required";
    } else if (/\s/.test(login.email)) {
      errObj.email = "Email should not contain spaces";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(login.email)) {
      errObj.email = "Please enter a valid email address";
    } else {
      errObj.email = "";
    }

    if (!login.password) {
      errObj.password = "This field is required";
    } else if (/\s/.test(login.password)) {
      errObj.password = "Password should not contain spaces";
    }
    // else if (
    //   !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/.test(login.password)
    // ) {
    //   errObj.password =
    //     "Password must be 8+ characters, with uppercase, lowercase, number, and special character.";
    // }
    else {
      errObj.password = "";
    }

    setErrors((prev) => ({ ...prev, ...errObj }));
    const data = Object.values(errObj).every((x) => x === "" || x === null);
    return data;
  };

  const handleLogin = async () => {
    // debugger
    if (validateFields()) {
      setIsLoading(true);
      const payload = {
        email: login.email,
        password: login.password,
      };
      const { status, data, message } = await postApi(
        ADMIN_LOGIN_DETAILS,
        payload
      );
      console.log(status,data,message, "status");
      if (status === 200) {
        // debugger;
        localStorage.setItem("accessToken", data);
        setIsLoading(false);
        successToast(message);
        navigate("/dashboard");
      } else {
        setIsLoading(false);
        errorToast(message);
      }
      // if (status === 401) {
      //   errorToast(message);
      // }
    }
  };

  return (
    <div className="login">
      <div className="login_cont">
        <div className="login_cont_sec">
          <div className="login_cont_sec_left">
            <img src={LoginBG} alt="Login Image" />
          </div>
          <div className="login_cont_sec_right">
            <div className="login_cont_sec_right_section">
              <div className="login_cont_sec_right_section_top">
                <img src={CompanyLogo} alt="CompanyLogo" />
                <h2>Log In to your Account</h2>
              </div>
              <div className="login_cont_sec_right_section_center">
                <div className="login_cont_sec_right_section_center_inputs">
                  <FormInputs
                    type={"email"}
                    placeholder={"Email"}
                    name="email"
                    value={login.email}
                    onChange={handleChange}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleLogin();
                      }
                    }}
                  />
                  {errors.email && (
                    <span className="error">{errors.email}</span>
                  )}
                </div>
                <div className="login_cont_sec_right_section_center_inputs">
                  <FormInputs
                    type={"password"}
                    placeholder={"Password"}
                    name="password"
                    value={login.password}
                    onChange={handleChange}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleLogin();
                      }
                    }}
                  />
                  {errors.password && (
                    <span className="error">{errors.password}</span>
                  )}
                </div>
              </div>
              <div className="login_cont_sec_right_section_bottom">
                <Buttons
                  style={{ width: "100%" }}
                  variant="primary"
                  onClick={handleLogin}
                  className="btn"
                >
                  Login
                </Buttons>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
