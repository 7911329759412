import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import Button from 'antd/lib/button';
import Typography from 'antd/lib/typography';
import theme from 'antd/lib/theme';
import Select from 'antd/lib/select';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Calendar from 'antd/lib/calendar';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import TimeSelector from './TimeSelector';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import './ModalCalendar.css'; 

dayjs.extend(require('dayjs/plugin/localeData'));

const ModalCalendar = ({ setModalTwoIsOpen }) => {
    const { token } = theme.useToken();
    const [currentDate, setCurrentDate] = useState("");
    const [currentMonth, setCurrentMonth] = useState(dayjs());
    const [showTimeSelector, setShowTimeSelector] = useState(false);

    const changeMonth = (direction) => {
        const updatedMonth = direction === "next" ? currentMonth.add(1, "month") : currentMonth.subtract(1, "month");
        setCurrentMonth(updatedMonth);
        updateDate(updatedMonth);
    };

    const handleShowTimeSelector = () => {
        setShowTimeSelector(true);
    };

    const onPanelChange = (date) => {
        setCurrentMonth(date);
        updateDate(date);
    };

    const updateDate = (date) => {
        const formattedDate = date.format("MMMM YYYY");
        setCurrentDate(formattedDate);
    };

    useEffect(() => {
        updateDate(dayjs());
    }, []);

    return (
        <div className="wrapper">
            <Calendar
                onSelect={handleShowTimeSelector}
                value={currentMonth}
                fullscreen={false}
                headerRender={({ value }) => (
                    <div className="calendar-header">
                        <Typography.Title level={4} className="calendar-header-title">
                            Select the date(s) you want to assign specific hours
                        </Typography.Title>
                        <Row gutter={8} justify="space-between" align="middle" className="calendar-header-row">
                            <Col>{currentDate}</Col>
                            <Col className="calendar-header-col">
                                <Button className="calendar-button" onClick={() => changeMonth("prev")}>
                                    <ChevronLeft />
                                </Button>
                                <Button className="calendar-button calendar-button-next" onClick={() => changeMonth("next")}>
                                    <ChevronRight />
                                </Button>
                            </Col>
                        </Row>
                    </div>
                )}
                onPanelChange={onPanelChange}
            />
            {showTimeSelector && (
                <div className="time-selector-container">
                    <div className="time-selector-content">
                        <h3>What hours are you available?</h3>
                        <div className="time-selector-row">
                            <div className="time-selector-actions">
                                <div className="flex gap-1 items-center">
                                    <TimeSelector /> - <TimeSelector />
                                </div>
                                <Button className="time-selector-button"><CloseOutlined /></Button>
                            </div>
                            <Button className="time-selector-button"><PlusOutlined /></Button>
                        </div>
                    </div>
                    <div className="time-selector-footer">
                        <Button className="time-selector-footer-button" onClick={() => setModalTwoIsOpen(false)}>Cancel</Button>
                        <Button className="time-selector-footer-button">Apply</Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ModalCalendar;
