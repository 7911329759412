import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "../Pages/Login/Login";
import Dashboard from "../Pages/Dashboard/Dashboard";
import CustomLayout from "../Components/CustomLayout/CustomLayout";
import ClassManagement from "../Pages/ClassManagement/ClassManagement";
import Instructor from "../Pages/Instructor/Instructor";
import Bookings from "../Pages/Bookings/Bookings";
import Orders from "../Pages/Orders/Orders";
import Students from "../Pages/Students/Students";
import CalenderPage from "../Pages/calender";
import CategoryManagement from "../Pages/CategoryManagement/CategoryManagement";
import Products from "../Pages/Products/Products";
import BankDetails from "../Pages/BankDetails/BankDetails";
import ManageInstructorForm from "../Pages/Instructor/ManageInstructor/ManageInstructorForm";
import Reschedule from "../Pages/Reschedule/Reschedule";
import ViewInstructorClass from "../Pages/Instructor/ViewInstructor/ViewInstructorClass";
import ImageCropper from "../Pages/Crop/ImageCropper";
import Enquiry from "../Pages/Enquiry/Enquiry";

const ProtectedRoutes = () => (
  <CustomLayout>
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/class-management" element={<ClassManagement />} />
      <Route path="/category-management" element={<CategoryManagement />} />
      <Route path="/instructor" element={<Instructor />} />
      <Route path="/booking" element={<Bookings />} />
      <Route path="/crop" element={<ImageCropper />} />

      <Route path="/manage-instructor/:id" element={<ManageInstructorForm />} />
      <Route path="/view-instructor/:id" element={<ViewInstructorClass />} />

      <Route path="/reschedule" element={<Reschedule />} />
      <Route path="//enquiry" element={<Enquiry />} />

      <Route path="/order" element={<Orders />} />
      <Route path="/students" element={<Students />} />
      <Route path="/products" element={<Products />} />
      <Route path="/calender" element={<CalenderPage />} />
      <Route path="/bank-details" element={<BankDetails />} />
      {/* <Route path="/payouts" element={<Payouts />} /> */}
    </Routes>
  </CustomLayout>
);

const RouterComponent = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/*" element={<ProtectedRoutes />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RouterComponent;
