import React, { useEffect, useState } from "react";
import { Button, Card, Tabs } from "antd";
import "./InstructorForm.scss";
import Buttons from "../../../Components/Buttons/Buttons";
import FormInputs from "../../../Components/FormInputs/FormInputs";
import TextArea from "antd/es/input/TextArea";
import { LeftCircleOutlined } from "@ant-design/icons";
import SelectInputs from "../../../Components/SelectInputs/SelectInputs";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { getApi, postApi } from "../../../Utlis/apiService";
import {
  ADD_NEW_INSTRUCTOR,
  GET_INSTRUCTOR_SLOTS,
  UPDATE_INSTRUCTORS,
} from "../../../Utlis/apiPath";
import { errorToast, successToast } from "../../../Services/ToastHelper";
import Loader from "../../../Components/Loader/Loader";
import { Checkbox, Select, Switch } from "antd";
import { TimePicker } from "antd";
import dayjs from "dayjs";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { BackArrowIcon } from "../../../Components/Icons/BackArrowIcon";
import MultiSelectInput from "../../../Components/MultiSelectInput/MultiSelectInput";

const { TabPane } = Tabs;

const initialValues = {
  firstName: "",
  lastName: "",
  emailId: "",
  mobileNumber: "",
  countryCode: "+1",
  aboutMe: "",
  gender: "",
  education: "",
  experience: "",
  expertise: "",
};
const InstructorForm = ({
  setShowAddInstructorForm,
  updateData,
  setUpdateData,
  fetchTableData,
}) => {
  const [formData, setFormData] = useState(initialValues);
  const [selectedValue, setSelectedValue] = useState(null);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const initialSchedule = {
    Monday: { isActive: false, slots: [] },
    Tuesday: { isActive: false, slots: [] },
    Wednesday: { isActive: false, slots: [] },
    Thursday: { isActive: false, slots: [] },
    Friday: { isActive: false, slots: [] },
    Saturday: { isActive: false, slots: [] },
    Sunday: { isActive: false, slots: [] },
  };

  console.log(formData, "formData");

  const patchFormData = (updateData) => {
    const updatedValues = {
      firstName: updateData.firstName || "",
      lastName: updateData.lastName || "",
      emailId: updateData.mailId || "",
      mobileNumber: updateData.mobileNo || "",
      countryCode: "+1", // Default or retain existing value
      aboutMe: updateData.aboutMe || "",
      gender: updateData.gender || "",
      education: updateData.education || "",
      experience: updateData.experience || "",
      expertise: updateData.expertise || "",
    };

    setFormData((prevState) => ({
      ...prevState,
      ...updatedValues,
    }));
  };

  useEffect(() => {
    fetchInstructorSlots(updateData?.instructorId);
  }, [updateData]);

  const patchScheduleData = (slotsData) => {
    const updatedSchedule = { ...initialSchedule };

    slotsData.forEach((slot) => {
      const day = slot.day;
      if (updatedSchedule[day]) {
        updatedSchedule[day].isActive = true;
        updatedSchedule[day].slots.push({
          start: slot.startTime,
          end: slot.endTime,
        });
      }
    });

    setSchedule(updatedSchedule);
  };
  const fetchInstructorSlots = async (id) => {
    setIsLoading(true);
    const params = {
      instructorId: id,
    };
    const { status, data } = await getApi(GET_INSTRUCTOR_SLOTS, {
      params,
    });
    if (status === 200) {
      // setSlotsData(data);
      patchScheduleData(data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  // Call the function with the first entry in updateData
  useEffect(() => {
    if (updateData?.length > 0) {
      patchFormData(updateData[0]);
    }
  }, [updateData]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const [schedule, setSchedule] = useState({
    Monday: { isActive: false, slots: [] },
    Tuesday: { isActive: false, slots: [] },
    Wednesday: { isActive: false, slots: [] },
    Thursday: { isActive: false, slots: [] },
    Friday: { isActive: false, slots: [] },
    Saturday: { isActive: false, slots: [] },
    Sunday: { isActive: false, slots: [] },
  });

  const handleToggle = (day) => {
    setSchedule((prev) => ({
      ...prev,
      [day]: { ...prev[day], isActive: !prev[day].isActive },
    }));
  };

  const handleTimeChange = (day, index, time, type) => {
    setSchedule((prev) => {
      const updatedSlots = [...prev[day].slots];
      const newTime = time.format("HH:mm");

      // Prevent overlapping
      if (type === "start") {
        if (index > 0 && newTime < updatedSlots[index - 1].end) {
          errorToast("Start time cannot overlap with the previous slot.");
          return prev;
        }
      } else if (type === "end") {
        if (
          index < updatedSlots.length - 1 &&
          newTime > updatedSlots[index + 1].start
        ) {
          errorToast("End time cannot overlap with the next slot.");
          return prev;
        }
      }

      updatedSlots[index] = { ...updatedSlots[index], [type]: newTime };
      return { ...prev, [day]: { ...prev[day], slots: updatedSlots } };
    });
  };

  const addTimeSlot = (day) => {
    setSchedule((prev) => ({
      ...prev,
      [day]: {
        ...prev[day],
        slots: [...prev[day].slots, { start: "", end: "" }],
      },
    }));
  };

  const removeTimeSlot = (day, index) => {
    setSchedule((prev) => {
      const updatedSlots = prev[day].slots.filter((_, i) => i !== index);
      return { ...prev, [day]: { ...prev[day], slots: updatedSlots } };
    });
  };

  const transformScheduleData = () => {
    const slots = Object.keys(schedule).map((day) => {
      if (schedule[day].isActive && schedule[day].slots.length > 0) {
        return {
          days: day,
          startEndTime: schedule[day].slots.map((slot) => ({
            startTime: slot.start,
            endTime: slot.end,
          })),
        };
      }
      return null;
    });
    return slots.filter((slot) => slot !== null);
  };

  const handlePhoneChange = (value, country) => {
    setFormData({
      ...formData,
      mobileNumber: value.slice(country.dialCode.length),
      countryCode: `+${country.dialCode}`,
    });
    if (formData.mobileNumber) {
      setErrors((prevErrors) => ({ ...prevErrors, mobileNumber: "" }));
    }
  };

  const validateFields = () => {
    let errObj = {};

    if (!formData.firstName.trim()) {
      errObj.firstName = "First Name is required";
    }
    if (!formData.lastName.trim()) {
      errObj.lastName = "Last Name is required";
    }
    if (!formData.emailId.trim()) {
      errObj.emailId = "Email is required";
    } else if (/\s/.test(formData.emailId)) {
      errObj.emailId = "Email should not contain spaces";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.emailId)) {
      errObj.emailId = "Please enter a valid email address";
    }
    if (!formData.mobileNumber.trim()) {
      errObj.mobileNumber = "Mobile Number is required";
    }
    // if (!formData.aboutMe.trim()) {
    //   errObj.aboutMe = "This field is required";
    // }
    if (!formData.gender.trim()) {
      errObj.gender = "Gender is required";
    }
    if (!formData.education.trim()) {
      errObj.education = "Education is required";
    }
    if (!formData.experience.trim()) {
      errObj.experience = "Experience is required";
    }
    // if (!formData.expertise.trim()) {
    //   errObj.expertise = "Expertise is required";
    // }

    const isValid = Object.keys(errObj).length === 0;

    setErrors(errObj);
    return isValid;
  };

  const handleSubmit = async () => {
    if (validateFields()) {
      const mappedSlots = Object.keys(schedule)
        .map((day) => {
          if (schedule[day].isActive && schedule[day].slots.length > 0) {
            return {
              days: day,
              startEndTime: schedule[day].slots.map((slot) => ({
                startTime: slot.start,
                endTime: slot.end,
              })),
            };
          }
          return null;
        })
        .filter((slot) => slot !== null);

      const payload = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.emailId,
        countryCode: formData.countryCode,
        phoneNumber: formData.mobileNumber,
        gender: formData.gender,
        aboutMe: formData.aboutMe,
        education: formData.education,
        experienceYears: formData.experience,
        experties: JSON.stringify(formData.expertise),
        slots: mappedSlots,
      };

      setIsLoading(true);
      try {
        const { status, message } = await postApi(ADD_NEW_INSTRUCTOR, payload);
        if (status === 200) {
          setShowAddInstructorForm(false);
          successToast(message);
          fetchTableData();
        } else {
          errorToast(message);
        }
      } catch (error) {
        console.error("Error adding instructor:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleUpdate = async () => {
    debugger
    if (validateFields()) {
      // Proceed only if validation passes
      const mappedSlots = Object.keys(schedule)
        .map((day) => {
          if (schedule[day].isActive && schedule[day].slots.length > 0) {
            return {
              days: day,
              startEndTime: schedule[day].slots.map((slot) => ({
                startTime: slot.start,
                endTime: slot.end,
              })),
            };
          }
          return null;
        })
        .filter((slot) => slot !== null);

      const payload = {
        instructorId: updateData[0]?.instructorId,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.emailId,
        countryCode: formData.countryCode,
        phoneNumber: formData.mobileNumber,
        gender: formData.gender,
        aboutMe: formData.aboutMe,
        education: formData.education,
        experienceYears: formData.experience,
        experties: JSON.stringify(formData.expertise),
        slots: mappedSlots,
      };

      setIsLoading(true);
      try {
        const { status, message } = await postApi(UPDATE_INSTRUCTORS, payload);
        if (status === 200) {
          setShowAddInstructorForm(false);
          successToast(message);
          fetchTableData();
          setUpdateData();
        } else {
          errorToast(message);
        }
      } catch (error) {
        console.error("Error updating instructor:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const hanldeBack = () => {
    setShowAddInstructorForm(false);
  };

  const handleSelectChange = (value) => {
    setSelectedValue(value);
  };

  const options = [
    { value: "1", label: "YinYoga" },
    { value: "2", label: "Meditation" },
    { value: "3", label: "Hatha" },
    { value: "4", label: "Vinyasa Flow" },
  ];

  return (
    <div className="instform">
      {isLoading && <Loader />}
      <div className="instform_header">
        <h2
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
          onClick={hanldeBack}
        >
          <BackArrowIcon />
          Instructor
        </h2>
        {updateData ? (
          <Buttons variant="primary" onClick={handleUpdate}>
            Update
          </Buttons>
        ) : (
          <Buttons variant="primary" onClick={handleSubmit}>
            Save
          </Buttons>
        )}
      </div>

      <Tabs defaultActiveKey="1">
        <div>
          <div className="instform_content">
            <div className="instform_content_details">
              {/* <div className="instform_content_details_top_profile">
                <div className="instform_content_details_top_profile_imge">
                  {imageUrl ? (
                    <img src={imageUrl} alt="Profile" />
                  ) : (
                   
                  )}
                   <h2>JP</h2>
                </div>
                <button className="instform_content_details_top_profile_button upload">
                  Upload
                </button>
                <button className="instform_content_details_top_profile_button remove">
                  Remove
                </button>
              </div> */}
            </div>
            <div className="instform_wrapper">
              <div className="instform_content_details">
                <div className="instform_content_details_top">
                  <div className="form_field">
                    <FormInputs
                      title="First Name*"
                      placeholder="Enter First Name"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                    />
                    {errors.firstName && (
                      <span className="error">{errors.firstName}</span>
                    )}
                  </div>
                  <div className="form_field">
                    <FormInputs
                      title="Last Name*"
                      placeholder="Enter Last Name"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                    />
                    {errors.lastName && (
                      <span className="error">{errors.lastName}</span>
                    )}
                  </div>
                  <div className="form_field">
                    <FormInputs
                      title="Email Id*"
                      placeholder="Enter Email Id"
                      name="emailId"
                      value={formData.emailId}
                      onChange={handleChange}
                    />
                    {errors.emailId && (
                      <span className="error">{errors.emailId}</span>
                    )}
                  </div>
                  <div className="form_field phone-input-container">
                    <label>Mobile Number*</label>
                    <PhoneInput
                      country={"us"}
                      value={`${formData.countryCode}${formData.mobileNumber}`}
                      onChange={handlePhoneChange}
                      inputStyle={{
                        width: "100%",
                        borderRadius: "5px",
                        borderColor: "#d9d9d9",
                      }}
                    />
                    {errors.mobileNumber && (
                      <span className="error">{errors.mobileNumber}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="instform_content_textarea">
                <label htmlFor="notes">About*</label>
                <TextArea
                  id="notes"
                  placeholder="Enter notes here"
                  rows={4}
                  name="aboutMe"
                  value={formData.aboutMe}
                  onChange={handleChange}
                  style={{
                    fontWeight: 600,
                    color: "#717171",
                    fontSize: "16px",
                  }}
                />
                {errors.aboutMe && (
                  <span className="error">{errors.aboutMe}</span>
                )}
              </div>
              <div className="instform_content_details">
                <div className="instform_content_details_top">
                  <div className="form_field">
                    <SelectInputs
                      title="Gender*"
                      name="gender"
                      placeholder="Select Gender"
                      options={[
                        { label: "Male", value: "Male" },
                        { label: "Female", value: "Female" },
                      ]}
                      value={formData.gender || null} // Ensure placeholder shows when no value is selected
                      onChange={(value) => {
                        setFormData({ ...formData, gender: value });

                        // Clear error when gender is selected
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          gender: "",
                        }));
                      }}
                      size="large"
                      style={{ width: "100%" }}
                    />
                    {errors.gender && (
                      <span className="error">{errors.gender}</span>
                    )}
                  </div>
                  <div className="form_field">
                    <FormInputs
                      title="Education*"
                      placeholder="Enter Education"
                      name="education"
                      value={formData.education}
                      onChange={handleChange}
                    />
                    {errors.education && (
                      <span className="error">{errors.education}</span>
                    )}
                  </div>
                  <div className="form_field">
                    <FormInputs
                      type="number"
                      title="Experience*"
                      placeholder="Enter Experience"
                      name="experience"
                      value={formData.experience}
                      onChange={handleChange}
                    />
                    {errors.experience && (
                      <span className="error">{errors.experience}</span>
                    )}
                  </div>
                  <div className="form_field">
                    <MultiSelectInput
                      title="Expertise*"
                      options={[
                        "Yin Yoga",
                        "Meditation",
                        "Hatha",
                        "Vinyasa Flow",
                      ]} // Predefined options
                      value={formData.expertise || []} // Ensure it's always an array
                      onChange={(selectedValues) => {
                        setFormData((prev) => ({
                          ...prev,
                          expertise: selectedValues, // Updates expertise with selected & entered values
                        }));

                        // Clear error when expertise is selected
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          expertise:
                            selectedValues.length > 0
                              ? ""
                              : prevErrors.expertise,
                        }));
                      }}
                      placeholder="Select or enter your expertise"
                    />

                    {/* {errors.expertise && (
                      <span className="error">{errors.expertise}</span>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Card className="instform_contentModal_data">
            <div className="weekly_schedule_container">
              <h2>Weekly Schedule</h2>
              <p>Set your Weekly hours</p>
              {Object.keys(schedule).map((day) => (
                <div key={day} className="weekly_schedule_day">
                  <div className="weekly_schedule_day_header">
                    <Switch
                      checked={schedule[day].isActive}
                      onChange={() => handleToggle(day)}
                    />
                    <h4 className="day_name">{day}</h4>
                  </div>
                  {schedule[day].isActive ? (
                    <div className="time_slots">
                      {schedule[day].slots.map((slot, index) => (
                        <div key={index} className="time_slot">
                          <TimePicker
                            value={
                              slot.start ? dayjs(slot.start, "HH:mm") : null
                            }
                            placeholder="Start Time"
                            format="HH:mm"
                            onChange={(time) =>
                              handleTimeChange(day, index, time, "start")
                            }
                            allowClear={false}
                          />
                          <TimePicker
                            value={slot.end ? dayjs(slot.end, "HH:mm") : null}
                            placeholder="End Time"
                            format="HH:mm"
                            onChange={(time) =>
                              handleTimeChange(day, index, time, "end")
                            }
                            allowClear={false}
                          />
                          <Button
                            type="danger"
                            icon={<DeleteOutlined />}
                            onClick={() => removeTimeSlot(day, index)}
                            style={{
                              backgroundColor: "#ff4d4f",
                              borderRadius: "10%",
                              width: "30px",
                              height: "30px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              border: "none",
                              color: "white",
                            }}
                          />
                        </div>
                      ))}
                      <Button
                        type="dashed"
                        icon={<PlusOutlined />}
                        onClick={() => addTimeSlot(day)}
                        className="add_slot_button"
                      >
                        Add Slot
                      </Button>
                    </div>
                  ) : (
                    <p>Unavailable</p>
                  )}
                </div>
              ))}
            </div>
          </Card>
        </div>
      </Tabs>
    </div>
  );
};

export default InstructorForm;
