import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  locationData: null,
  currencyData:null,
  error: null,
};

const geoSlice = createSlice({
  name: 'geo',
  initialState,
  reducers: {
    setLocation: (state, action) => {
      state.locationData = action.payload;
    },
    setCurrency:(state, action) => {
      state.currencyData = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { setLocation, setCurrency, setError } = geoSlice.actions;

export default geoSlice.reducer;
