import React, { useEffect, useState } from "react";
import {
  Layout,
  Input,
  Button,
  Empty,
  Space,
  Typography,
  Table,
  Popconfirm,
  message,
  Tooltip,
} from "antd";
import {
  PlusOutlined,
  SearchOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from "@ant-design/icons";
import "./CategoryManagement.scss";
import Buttons from "../../Components/Buttons/Buttons";
import CategoryManagementForm from "./CategoryManagementForm/CategoryManagementForm";
import {
  DELETE_CATEGORY_BY_ID,
  GET_ALL_CATEGORY,
  GET_CATEGORY_BY_ID,
} from "../../Utlis/apiPath";
import { deleteApi, getApi } from "../../Utlis/apiService";
import Loader from "../../Components/Loader/Loader";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { errorToast, successToast } from "../../Services/ToastHelper";
import { debounce } from "lodash";
import { BackArrowIcon } from "../../Components/Icons/BackArrowIcon";

const { Content } = Layout;
const { Title, Text } = Typography;

const CategoryManagement = () => {
  const [addCategoryForm, setAddCategoryForm] = useState(false);
  const [editData, setEditData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [isAscending, setIsAscending] = useState("");
  const [noDataFound, setNoDataFound] = useState(false);
  const handleAddCategory = () => {
    setEditData(null);
    setAddCategoryForm(true);
  };

  const debouncedFetchClasses = debounce((searchValue) => {
    setSearch(searchValue);
    const page = 1;
    setPageIndex(1);
    fetchAllCategorys(page, pageSize, searchValue);
  }, 500);

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    setPageIndex(current);
    setPageSize(pageSize);
    fetchAllCategorys(current, pageSize);
  };

  const getPageSizeOptions = () => {
    if (totalRecords <= 50) {
      return ["10", "20", "50"];
    } else {
      return ["15", "25", "50", "100"];
    }
  };

  const fetchAllCategorys = async (
    page = null,
    pageSize = null,
    searchValue
  ) => {
    setIsLoading(true);
    const pageIndex1 = page ? page - 1 : pageIndex - 1;
    const search1 = searchValue || searchValue === "" ? searchValue : search;
    const params = {
      search: search1,
      pageIndex: pageIndex1,
      pageSize,
      isAscending,
    };
    const { status, data } = await getApi(GET_ALL_CATEGORY, {
      params,
    });
    if (status === 200) {
      setTableData(data.categories);
      setTotalRecords(data?.totalRecords);
      setNoDataFound(data.categories.length === 0 && search1 !== "");
      setIsLoading(false);
    } else {
      setTableData([]);
      setIsLoading(false);
    }
  };

  const getCategoryById = async (record) => {
    try {
      const { status, message, data } = await getApi(
        `${GET_CATEGORY_BY_ID}?id=${record.id}`
      );
      if (status === 200) {
        setEditData(data);
        setAddCategoryForm(true);
      } else {
        errorToast(message);
      }
    } catch (error) {
      console.error(error);
      errorToast("An error occurred while deleting the category.");
    }
  };

  useEffect(() => {
    fetchAllCategorys(1, 10);
  }, [isAscending]);

  const columns = [
    {
      title: "SL No",
      key: "slNo",
      render: (text, record, index) => (pageIndex - 1) * pageSize + (index + 1),
    },
    {
      title: "Category Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 250, // Adjust width as needed
      render: (text) => {
        if (!text) return "N/A"; // Handle empty values

        const shouldTruncate = text.length > 40;
        const displayText = shouldTruncate ? `${text.slice(0, 20)}...` : text;

        return shouldTruncate ? (
          <Tooltip title={text}>
            <span>{displayText}</span>
          </Tooltip>
        ) : (
          <span>{text}</span>
        );
      },
    },
    {
      title: "Image",
      dataIndex: "images",
      key: "images",
      render: (images) =>
        images && images?.length > 0 ? (
          <img
            src={images[0]}
            alt="category-image"
            style={{
              width: "50px",
              height: "50px",
              objectFit: "cover",
              borderRadius: "5px",
            }}
          />
        ) : (
          "No Image"
        ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div style={{ display: "flex", gap: "20px" }}>
          <EditOutlined
            style={{ cursor: "pointer", color: "blue", fontSize: "20px" }}
            onClick={() => getCategoryById(record)}
          />
          <Popconfirm
            title="Are you sure you want to delete this category?"
            onConfirm={() => handleDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined
              style={{ cursor: "pointer", color: "red", fontSize: "20px" }}
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const handleSorting = () => {
    setIsAscending((prevState) => (prevState === "" ? false : !prevState)); // Toggle between false and true
  };
  const handleEdit = (record) => {
    setAddCategoryForm(true);
  };

  const handleDelete = async (id) => {
    try {
      const { status, message } = await deleteApi(
        `${DELETE_CATEGORY_BY_ID}/${id}`
      );
      if (status === 200) {
        successToast(message);
        fetchAllCategorys();
      } else {
        errorToast(message);
      }
    } catch (error) {
      message.error("An error occurred while deleting the category.");
    }
  };

  return (
    <div>
      {isLoading && <Loader />}
      {!addCategoryForm && (
        <div className="container">
          <div className="headers">
            <Space
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Title level={3} style={{ margin: 0 }}>
                Category Management
              </Title>
              <Buttons variant="primary" onClick={handleAddCategory}>
                <div onClick={() => setAddCategoryForm(false)} />
                Add Category
              </Buttons>
            </Space>
          </div>

          <div>
            <div className="contentWrapper">
              <div className="contentWrapper_main">
                <Input
                  placeholder="Search"
                  prefix={<SearchOutlined />}
                  className="searchInput"
                  onChange={(e) => debouncedFetchClasses(e.target.value)}
                />
                <Button
                  icon={
                    isAscending === true ? (
                      <SortAscendingOutlined />
                    ) : (
                      <SortDescendingOutlined />
                    )
                  }
                  className="sortButton"
                  onClick={handleSorting}
                />
              </div>

              {tableData.length > 0 ? (
                <div className="table-data">
                  <Table
                    columns={columns}
                    dataSource={tableData}
                    pagination={{
                      current: pageIndex,
                      pageSize,
                      total: totalRecords,
                      showSizeChanger: true,
                      pageSizeOptions: getPageSizeOptions(),
                    }}
                    onChange={handleTableChange}
                    // scroll={{ x: "max-content" }}
                  />
                </div>
              ) : (
                <Empty
                  className="emptydata"
                  description={
                    <div>
                      <Title level={4}>No Data Found</Title>
                      <Text>
                        Please try searching with a different keyword.
                      </Text>
                    </div>
                  }
                  imageStyle={{
                    height: 60,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      )}
      {addCategoryForm && (
        <CategoryManagementForm
          setAddCategoryForm={setAddCategoryForm}
          editData={editData}
          fetchAllCategorys={fetchAllCategorys}
        />
      )}
    </div>
  );
};

export default CategoryManagement;
