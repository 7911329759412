import React, { useEffect, useState } from "react";
import "./Products.scss";
import Buttons from "../../Components/Buttons/Buttons";
import { SearchOutlined, SortAscendingOutlined } from "@ant-design/icons";
import { Table, Tabs, Button, Input, Tag } from "antd";
import { debounce } from "lodash";
import Loader from "../../Components/Loader/Loader";
import AddProducts from "./AddProducts/AddProducts";

const dummyData = [
  {
    key: "1",
    product: "Wireless Headphones",
    status: "Active",
    inventory: 145,
    category: "Electronics",
    price: "$129.99",
  },
  {
    key: "2",
    product: "Gaming Mouse",
    status: "Active",
    inventory: 23,
    category: "Electronics",
    price: "$59.99",
  },
  {
    key: "3",
    product: "Mechanical Keyboard",
    status: "Active",
    inventory: 89,
    category: "Electronics",
    price: "$149.99",
  },
  {
    key: "4",
    product: "Coffee Maker",
    status: "Active",
    inventory: 0,
    category: "Appliances",
    price: "$79.99",
  },
];
const Products = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [isApproved, setIsApproved] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [tableData, setTableData] = useState(dummyData);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: dummyData.length,
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showAddProducts, setShowAddProducts] = useState(false);
  const onSelectChange = (selectedKeys) => {
    setSelectedRowKeys(selectedKeys);
  };

  const handleTabChange = (tabKey) => {
    setActiveTab(tabKey);
    setIsApproved(tabKey === "1");
  };

  const onSearchChange = (e) => {
    handleSearch(e.target.value);
  };

  const handleSearch = debounce((value) => {
    setSearchTerm(value);
  }, 300);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleTableChange = (newPagination) => {
    setPagination(newPagination);
    // fetchTableData({
    //   current: newPagination.current,
    //   pageSize: newPagination.pageSize,
    // });
  };

  const columns = [
    { title: "Product", dataIndex: "product" },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status) => {
        let color = "green"; // Default color for 'Active'
        if (status === "Inactive") color = "volcano";
        else if (status === "Pending") color = "geekblue";

        return <Tag color={color}>{status.toUpperCase()}</Tag>;
      },
    },
    { title: "Inventory", dataIndex: "inventory" },
    { title: "Category", dataIndex: "category" },
    { title: "Price", dataIndex: "price" },
    // { title: "Action", dataIndex: "action" },
    // { title: "Expertise", dataIndex: "expertise" },
    {
      title: "Action",
      dataIndex: "action",
      render: () => (
        <>
          <Button size="small" style={{ marginRight: "8px" }}>
            Manage
          </Button>
          {/* <Button size="small" type="default">
                Send Invite
              </Button> */}
        </>
      ),
    },
  ];

  useEffect(() => {
    setTableData(dummyData);
  }, []);

  const handleAddProduct = () => {
    setShowAddProducts(true);
  };

  const handleCloseProduct = () => {
    setShowAddProducts(false);
  };

  return (
    <div className="products">
      {isLoading && <Loader />}

      {!showAddProducts && (
        <>
          <div className="product_header">
            <h2>Product</h2>
            <Buttons variant="primary" onClick={handleAddProduct}>
              Add Products
            </Buttons>
          </div>
          <div className="product_content">
            <div className="product_content_custom">
              <div
                className={activeTab === "1" ? "tab selected" : "tab"}
                onClick={() => handleTabChange("1")}
              >
                All
              </div>
              {/* <div
                className={activeTab === "2" ? "tab selected" : "tab"}
                onClick={() => handleTabChange("2")}
              >
                productructor Sign up Request
              </div> */}
            </div>

            <div className="inst_content_search">
              <Input
                placeholder="Search"
                prefix={<SearchOutlined />}
                onChange={onSearchChange}
              />
              <Button icon={<SortAscendingOutlined />} />
            </div>
          </div>
          <div>
            {activeTab === "1" && (
              <Table
                columns={columns}
                dataSource={tableData}
                pagination={{
                  current: pagination.current,
                  pageSize: pagination.pageSize,
                  total: pagination.total,
                  showSizeChanger: true,
                }}
                loading={loading}
                onChange={handleTableChange}
                rowSelection={rowSelection}
              />
            )}
            {/* {activeTab === "2" && (
                        <Table
                          columns={columns}
                          dataSource={tableData}
                          pagination={{
                            current: pagination.current,
                            pageSize: pagination.pageSize,
                            total: pagination.total,
                            showSizeChanger: true,
                          }}
                          loading={loading}
                          onChange={handleTableChange}
                        />
                      )} */}
          </div>
        </>
      )}

      {showAddProducts && (
        <AddProducts
          setShowAddProducts={setShowAddProducts}
          handleAddProduct={handleAddProduct}
          handleCloseProduct={handleCloseProduct}
        />
      )}
    </div>
  );
};

export default Products;
