import React from 'react'
import "./BankDetails.scss";
import { Card } from 'antd';
import FormInputs from '../../Components/FormInputs/FormInputs';
import Buttons from '../../Components/Buttons/Buttons';


const BankDetails = () => {
  return (
    <div className="b_details">
        <div className="b_details_heading">
            <h2>Bank Account details</h2>
        </div>
        
            <Card>
            <div className="b_details_content">
            <div className="b_details_content_bank">
              <FormInputs title="Bank Holder Name" placeholder="Enter Name" />
              <FormInputs
                title="Account Number"
                placeholder="Enter Account Number"
              />
              <FormInputs title="IFSC Code" placeholder="Enter IFSC Code" />
              <FormInputs title="Branch Name" placeholder="Branch Name" />
            </div>
            <div className="b_details_content_btn">
            <Buttons variant="primary">Verify</Buttons>
            </div>
          
          </div>
            </Card>
     
    </div>
  )
}

export default BankDetails
