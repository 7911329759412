import { ToastContainer } from "react-toastify";
import "./App.css";
import RouterComponent from "./App/RouterComponents/RouterComponent";
import { useDispatch, useSelector } from "react-redux";
import { setLocation, setCurrency, setError } from "./App/redux/geoSlice";
import { useEffect } from "react";

function App() {
  const dispatch = useDispatch();
  const geoData = useSelector((state) => state);
  // console.log(geoData);

  useEffect(() => {
    fetch("http://ip-api.com/json")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        dispatch(setLocation(data));
      })
      .catch((error) => {
        console.error("Error fetching IP location:", error);
        dispatch(setError(error.message));
      });

      fetch("https://api.exchangerate.host/convert?from=USD&to=INR&amount=100")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        dispatch(setCurrency(data));
      })
      .catch((error) => {
        console.error("Error fetching IP location:", error);
        dispatch(setError(error.message));
      });
  }, [dispatch]);
  return (
    <div className="App">
      <RouterComponent />
      <ToastContainer theme="colored" position="top-right" autoClose={5000} />
    </div>
  );
}

export default App;
