import React, { useState } from "react";
import "./Orders.scss";
import { SearchOutlined, SortAscendingOutlined } from "@ant-design/icons";
import { Table, Tabs, Button, Input } from "antd";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

const allBookings = [
  { title: "Order ID", dataIndex: "orderId" },
  { title: "Date & Time", dataIndex: "dateTime" },
  { title: "Customer Name", dataIndex: "customerName" },
  { title: "Product Count", dataIndex: "productCount" },
  { title: "Order Total", dataIndex: "orderTotal" },
  { title: "Payment Status", dataIndex: "paymentStatus" },
  { title: "Fulfillment Status", dataIndex: "fulfillmentStatus" },
];

const allBookingsData = [
  {
    key: "1",
    orderId: "#1106",
    dateTime: "Aug 30th 2024 8-10am",
    customerName: "Amith Chiefss",
    productCount: "02",
    orderTotal: "20,000",
    paymentStatus: "Payment Pending",
    fulfillmentStatus: "Unfulfilled",
  },
  {
    key: "2",
    orderId: "#1106",
    dateTime: "Aug 30th 2024 8-10am",
    customerName: "Amith Chiefss",
    productCount: "25",
    orderTotal: "20,000",
    paymentStatus: "Refunded",
    fulfillmentStatus: "Unfulfilled",
  },
];

const Orders = () => {
  return (
    <div className="order">
      <div className="order_header">
        <h2>Orders</h2>
      </div>
      <div class="order_sec">
        <div class="order_sec_card">
          <div class="order_sec_card_data">
            <div class="content">
              <h3>Total orders count </h3>
              <h2>120</h2>
            </div>
          </div>
          <div class="order_sec_card_data">
            <div class="content">
              <h3>Returs</h3>
              <h2>10</h2>
            </div>
          </div>
          <div class="order_sec_card_data">
            <div class="content">
              <h3>Total Revenue</h3>
              <h2>₹1,20,000</h2>
            </div>
          </div>
          <div class="order_sec_card_data">
            <div class="content">
              <h3>Total refund amount</h3>
              <h2>₹20,000</h2>
            </div>
          </div>
          <div class="order_sec_card_data">
            <div class="content"></div>
          </div>
        </div>
      </div>

      <div className="order_content">
        <div>
          <Input placeholder="Search" prefix={<SearchOutlined />} />
        </div>
        <div>
          <Button icon={<SortAscendingOutlined />} />
        </div>
      </div>
      <div>
        <Table
          columns={allBookings}
          dataSource={allBookingsData}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default Orders;
