import React, { useEffect, useState } from "react";
import "./CustomLayout.scss";
import { useNavigate } from "react-router-dom";
import {
  ApartmentOutlined,
  BankOutlined,
  BellOutlined,
  CalendarOutlined,
  ContainerOutlined,
  DashboardOutlined,
  DownloadOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ProductOutlined,
  SearchOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Layout, Menu, Modal, theme } from "antd";
import CompanyLogo from "../../Assets/Images/CompanyLogo.jpg";
import Reschedule from "../../Pages/Reschedule/Reschedule";

const { Header, Sider, Content } = Layout;

const CustomLayout = ({ children }) => {
  const [selectedKey, setSelectedKey] = useState("1");
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [isLogoutModalVisible, setLogoutModalVisible] = useState(false);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    const savedKey = localStorage.getItem("selectedMenuKey");
    if (savedKey) {
      setSelectedKey(savedKey);
    }
  }, []);

  const handleMenuClick = (menuKey) => {
    setSelectedKey(menuKey);
    localStorage.setItem("selectedMenuKey", menuKey);

    const pathMap = {
      1: "/dashboard",
      2: "/class-management",
      3: "/category-management",
      4: "/booking",
      // 5: "/order",
      6: "/instructor",
      7: "/students",
      // 8: "/products",
      9: "/calender",
      // 10: "/bank-details",
      // 11: "/payouts",
      11: "/reschedule",
      12: "/enquiry",

    };
    const path = pathMap[menuKey];
    if (path) {
      navigate(path);
    }
  };

  useEffect(() => {
    fetch("http://ip-api.com/json")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
 
      })
      .catch((error) => {
        console.error("Error fetching IP location:", error);
      });
  }, []);

  const handleLogout = () => {
    navigate("/");
    setLogoutModalVisible(false);
    // Add your logout logic here
  };

  const showLogoutModal = () => {
    setLogoutModalVisible(true);
  };

  const closeLogoutModal = () => {
    setLogoutModalVisible(false);
  };

  return (
    <Layout className="layout">
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        className="sidebar"
      >
        <div className="sidebar_logo">
          <img src={CompanyLogo} alt="Company Logo" />
        </div>
        <Menu
          theme="light"
          mode="inline"
          selectedKeys={[selectedKey]}
          defaultOpenKeys={["sub1", "sub2"]}
          onClick={({ key }) => handleMenuClick(key)}
          items={[
            {
              key: "1",
              icon: <DashboardOutlined />,
              label: "Dashboard",
            },
            {
              key: "2",
              icon: <ApartmentOutlined />,
              label: "Class Management",
            },
            {
              key: "3",
              icon: <ContainerOutlined />,
              label: "Category Management",
            },
            {
              key: "sub1",
              icon: <DownloadOutlined />,
              label: "Bookings",
              children: [
                {
                  key: "4",
                  label: "Booking",
                },
                // {
                //   key: "5",
                //   label: "Order",
                // },
              ],
            },
            {
              key: "6",
              icon: <UserOutlined />,
              label: "Instructor",
            },
            {
              key: "11",
              icon: <UserOutlined />,
              label: "Reschedule",
            },
            {
              key: "12",
              icon: <UserOutlined />,
              label: "Enquiry",
            },
            {
              key: "7",
              icon: <UsergroupAddOutlined />,
              label: "Students",
            },
            // {
            //   key: "8",
            //   icon: <ProductOutlined />,
            //   label: "Product",
            // },
            {
              key: "9",
              icon: <CalendarOutlined />,
              label: "Calendar",
            },

            // {
            //   key: "sub2",
            //   icon: <BankOutlined />,
            //   label: "Finance",
            //   children: [
            //     {
            //       key: "10",
            //       label: "Bank Details",
            //     },
            //     {
            //       key: "11",
            //       label: "Payouts",
            //     },
            //   ],
            // },
          ]}
        />
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            background: "#f0f2f5",
          }}
        >
          <div className="header">
            <div className="header_left">
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  fontSize: "16px",
                  width: 64,
                  height: 64,
                }}
              />
            </div>
            <div className="header_right">
              {/* <SearchOutlined style={{ fontSize: "20px", cursor: "pointer" }} /> */}
              {/* <BellOutlined style={{ fontSize: "20px", cursor: "pointer" }} /> */}
              <div
                className="header_right_user"
                style={{ cursor: "pointer" }}
                onClick={showLogoutModal}
              >
                <h6>J</h6>
              </div>
            </div>
          </div>
          <Modal
            title="Logout Confirmation"
            visible={isLogoutModalVisible}
            onOk={handleLogout}
            onCancel={closeLogoutModal}
            okText="Logout"
            cancelText="Cancel"
            width={300}
          >
            <p>Are you sure you want to log out?</p>
          </Modal>
        </Header>
        <Content
          style={{
            // margin: "24px 16px",
            padding: 24,
            minHeight: 280,
            background: "#f5f5f5",
            borderRadius: borderRadiusLG,
            overflowY: "auto",
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default CustomLayout;
