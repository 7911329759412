import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import "./Dashboard.scss";
import { Loader } from "lucide-react";
import { getApi } from "../../Utlis/apiService";
import { GET_ALL_DASHBOARD_DATA } from "../../Utlis/apiPath";

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState([]);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await getApi(GET_ALL_DASHBOARD_DATA);
      if (!response) {
        throw new Error("No response received from API");
      }
      setDashboardData(response.data); // This is the key change - use response.data
    } catch (error) {
      console.error("🚨 API call error:", error);
      setError(error.message);
      setDashboardData(null);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (isLoading) {
    return (
      <div className="dashboard-loader">
        <Loader size={50} />
      </div>
    );
  }

  // if (!dashboardData) {
  //   return <div className="dashboard-error">Error loading data.</div>;
  // }

  const timeLabels = [
    { label: "15 Days", key: "last15DaysRevenue" },
    { label: "30 Days", key: "last30DaysRevenue" },
    { label: "45 Days", key: "last45DaysRevenue" },
    { label: "60 Days", key: "last60DaysRevenue" },
    { label: "75 Days", key: "last75DaysRevenue" },
    { label: "90 Days", key: "last90DaysRevenue" },
  ];

  const timeLabels2 = [
    { label: "15 Days", key: "last15DaysBookingCount" },
    { label: "30 Days", key: "last30DaysBookingCount" },
    { label: "45 Days", key: "last45DaysBookingCount" },
    { label: "60 Days", key: "last60DaysBookingCount" },
    { label: "75 Days", key: "last75DaysBookingCount" },
    { label: "90 Days", key: "last90DaysBookingCount" },
  ];

  const revenueChartData = timeLabels.map(({ label, key }) => ({
    time: label,
    revenue: Number(dashboardData?.revenueVsTime?.[key] || 0),
  }));

  const bookingChartData = timeLabels2.map(({ label, key }) => ({
    time: label,
    bookings: Number(dashboardData?.bookingCountVsTime?.[key] || 0),
  }));

  const topClassesData =
    dashboardData?.topBookedClasses?.map((item) => ({
      name: item.title,
      bookings: Number(item.count),
    })) || [];

  const topInstructorsData =
    dashboardData?.topInstructors?.map((item) => ({
      name: item.instructorName,
      bookings: Number(item.count),
    })) || [];

  const subscribersOverTimeData = Object.keys(
    dashboardData?.subscribersOverTime || {}
  ).map((key) => ({
    time: key.replace("DaysBookingCount", " Days"), // Add space and remove 'Booking'
    subscribers: Number(dashboardData?.subscribersOverTime[key] || 0),
  }));

  const averageBookingValueData = Object.entries(
    dashboardData?.averageBookingValue || {}
  ).map(([key, value]) => ({ time: key, value: Number(value) }));

  const averageClassBookingCountData = Object.entries(
    dashboardData?.averageClassBookingCount || {}
  ).map(([key, value]) => ({ time: key, count: Number(value) }));

  return (
    <div className="dashboard">
      <header className="dashboard-header">
        <h1>Welcome to Yogipur</h1>
        <div className="tab-buttons">
          <button className="active">Classes</button>
          {/* <button>Product</button> */}
        </div>
      </header>

      <div className="stats-cards">
        <div className="stat-card">
          <div className="stat-info">
            <h3>{dashboardData?.totalClassBooking?.count || 0}</h3>
            <p>Total Class Booking</p>
          </div>
        </div>
        <div className="stat-card">
          <div className="stat-info">
            <h3>{dashboardData?.totalRevenue?.count || 0}</h3>
            <p>Total Revenue</p>
          </div>
        </div>
        <div className="stat-card">
          <div className="stat-info">
            <h3>{dashboardData?.subscriptionEarnings?.count || 0}</h3>
            <p>Subscription Earnings</p>
          </div>
        </div>
        <div className="stat-card">
          <div className="stat-info">
            <h3>{dashboardData?.oneTimeEarnings?.count || 0}</h3>
            <p>One-Time Earnings</p>
          </div>
        </div>
      </div>

      <h2>Classes Analytics Last 90 days</h2>

      <div className="charts-grid ">
        <div className="chart-container">
          <h3>Revenue vs Time</h3>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={revenueChartData}>
              <XAxis dataKey="time" />
              <YAxis />
              <Tooltip />
              <Legend />
              <CartesianGrid strokeDasharray="3 3" />
              <Line type="monotone" dataKey="revenue" stroke="#4bc0c0" />
            </LineChart>
          </ResponsiveContainer>
        </div>

        <div className="chart-container">
          <h3>Booking Count vs Time</h3>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={bookingChartData}>
              <XAxis dataKey="time" />
              <YAxis />
              <Tooltip />
              <Legend />
              <CartesianGrid strokeDasharray="3 3" />
              <Line type="monotone" dataKey="bookings" stroke="#3572eb" />
            </LineChart>
          </ResponsiveContainer>
        </div>

        <div className="chart-container">
          <h3>Top Booked Classes</h3>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={topClassesData} layout="vertical">
              <XAxis type="number" />
              <YAxis dataKey="name" type="category" />
              <Tooltip />
              <Legend />
              <Bar dataKey="bookings" fill="rgba(75, 192, 192, 0.8)" />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className="chart-container">
          <h3>Subscribers Over Time</h3>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={subscribersOverTimeData}>
              <XAxis dataKey="time" />
              <YAxis />
              <Tooltip />
              <Legend />
              <CartesianGrid strokeDasharray="3 3" />
              <Line type="monotone" dataKey="subscribers" stroke="#36a2eb" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="charts-grid ">
        {/* Top Instructors by Booking Count */}
        <div className="chart-container">
          <h3>Top Instructors by Count</h3>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={topInstructorsData} layout="vertical">
              <XAxis type="number" />
              <YAxis dataKey="name" type="category" />
              <Tooltip />
              <Legend />
              <Bar dataKey="bookings" fill="#ff6384" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        {/* Subscribers Over Time */}
      
        <div className="cards-grid ">
          <div className="card">
            <h3>Avg booking value</h3>
            <div className="card-content">
              <div className="icon-placeholder" />
              <div>
              
                  <p className="value">
                    $
                    {Number(
                      dashboardData?.averageBookingValue?.amount || 0
                    ).toLocaleString()}
                  </p>
               
              </div>
            </div>
          </div>

          <div className="card">
            <h3>Avg class booking count</h3>
            <div className="card-content">
              <p className="value">
                {dashboardData?.averageClassBookingCount || 0}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
