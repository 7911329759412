import { useState } from "react";
import gCalendar from "../../Assets/Images/google-calendar (3).png";
import outCalendar from "../../Assets/Images/google-calendar (3).png";
import zoomIng from "../../Assets/Images/google-calendar (3).png";
import Calendar from "../../Components/calendar/Calendar";

const CalendarPage = ({ maintainState }) => {
  const handleCalendar = () => {
    maintainState(true);
  };

  return (
    <div className="px-[100px] pt-[20px]">
      <h2 className="text-[30px] font-normal mb-8">Calendar Integration</h2>
      <div>
        {[
          { img: gCalendar, title: "Google Calendar" },
          { img: outCalendar, title: "Outlook Calendar" },
          { img: zoomIng, title: "Zoom" },
        ].map((item, index) => (
          <div key={index} className="flex justify-between my-7">
            <div className="w-[30%] pt-4">
              <div>
                <img src={item.img} alt={item.title} width={45} height={45} />
                <h2 className="font-bold">{item.title}</h2>
                <p className="font-thin text-sm text-[#BBBBBB] mt-2">
                  No account Created
                </p>
              </div>
            </div>
            <div className="w-[60%] bg-white shadow-lg p-4 rounded-xl">
              <div className="flex justify-between mb-5">
                <h3>Connect to {item.title}</h3>
                <button
                  className="bg-black text-white text-sm py-2 px-4 rounded"
                  onClick={handleCalendar}
                >
                  Connect
                </button>
              </div>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit,
                quos necessitatibus rerum nostrum dolores excepturi impedit
                maxime sed qui ut dolorum molestias fuga iusto recusandae
                maiores ea quis doloribus aperiam ullam, repellendus numquam
                soluta?
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Dashboard = () => {
  const [open, setOpen] = useState(false);
  return <>{<Calendar />}</>;
};

export default Dashboard;

export async function getServerSideProps() {
  return { props: {} };
}
