import { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import Modal from "antd/lib/modal";
import Button from "antd/lib/button";
import CalendarOutlined from "@ant-design/icons/CalendarOutlined";
import PlusOutlined from "@ant-design/icons/PlusOutlined";
import SearchOutlined from "@ant-design/icons/SearchOutlined";
import Input from "antd/lib/input";
import ModalCalendar from "./ModalCalendar";
import { GET_ALL_CALENDERDATA } from "../../Utlis/apiPath";
import { getApi } from "../../Utlis/apiService";

const Calendar = () => {
  // const [events, setEvents] = useState([
  //     { title: 'Yoga for back pain', date: '2025-01-01', description: 'Details about Event 1', start: '2025-01-01T14:30:00', end: '2025-01-01T15:30:00' },
  //     { title: 'Yoga for flexibility', date: '2025-01-05', description: 'Details about Event 2', start: '2025-01-05T14:30:00', end: '2025-01-05T15:30:00' },
  //     { title: 'Yoga for back pain', date: '2025-01-18', description: 'Details about Event 3', start: '2025-01-18T14:30:00', end: '2025-01-18T15:30:00' },
  //     // Multiple events for Jan 22
  //     { title: 'Morning Yoga', date: '2025-01-22', description: 'Start your day with yoga', start: '2025-01-22T08:00:00', end: '2025-01-22T09:00:00' },
  //     { title: 'Yoga for flexibility', date: '2025-01-22', description: 'Afternoon session', start: '2025-01-22T12:00:00', end: '2025-01-22T13:00:00' },
  //     { title: 'Evening Meditation', date: '2025-01-22', description: 'Relax your mind', start: '2025-01-22T17:00:00', end: '2025-01-22T18:00:00' },
  // ]);

  const [events, setEvents] = useState([]);



  const [filters, setFilters] = useState(false);
  const [type, setType] = useState("monthly");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalTwoIsOpen, setModalTwoIsOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [currentDate, setCurrentDate] = useState(
    new Date().toISOString().split("T")[0]
  );


  const formatTime = (date) => {
    const options = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true, // 12-hour format with AM/PM
    };
    const formatter = new Intl.DateTimeFormat("en-US", options);
    return formatter.format(date);
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const formattedDate = date.toLocaleDateString("en-US", {
      weekday: "short",
      month: "short",
      day: "2-digit",
    });

    return formattedDate;
  };
  // const SecondformatDate = (date: Date) => {
  //     const year = date.getFullYear();
  //     const month = String(date.getMonth() + 1).padStart(2, '0');
  //     const day = String(date.getDate()).padStart(2, '0');
  //     return `${year}-${month}-${day}`;
  // };
  const SecondformatDate = (date) => {
    // Get the local time by correcting the UTC offset
    const localDate = new Date(
      date.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
    ); // Adjust the time zone if needed

    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, "0");
    const day = String(localDate.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };
  const handleEventClick = (info) => {
   

    const startFormatted = formatTime(new Date(info.event.start));
    const endFormatted = formatTime(new Date(info.event.end));

    // Extract date and extendedProps (instructor, description)
    const eventDate = new Date(info.event.start).toISOString().split("T")[0]; // Extracts only YYYY-MM-DD
    const extendedProps = info.event.extendedProps || {};

    setSelectedEvent({
      title: info.event.title,
      start: startFormatted,
      end: endFormatted,
      date: eventDate, // Extracted date
      instructor: extendedProps.instructor || "Unknown",
      description: extendedProps.description || "No description",
    });

    setModalIsOpen(true);
  };
  const handleViewChange = (info) => {
    
    const { type } = info.view;
    type === "timeGridDay" ? setFilters(true) : setFilters(false);

    if (type === "dayGridMonth") {
      setType("monthly");
    } else if (type === "dayGridWeek") {
      setType("weekly");
    } else if (type === "timeGridDay") {
      setType("daily");
    } else {
      setType("monthly");
    }

    setCurrentDate(
      SecondformatDate(info.view.calendar.currentData.currentDate)
    );
  };


  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedEvent(null);
  };
  const openModalTwo = () => {
    setModalTwoIsOpen(true);
  };
  const closeModalTwo = () => {
    setModalTwoIsOpen(false);
  };
  const fetchCalendarData = async () => {
    try {
      const params = {
        type: type,
        date: currentDate,
      };

      const { status, data } = await getApi(GET_ALL_CALENDERDATA, { params });

      if (status === 200) {
        const info = data || [];

        if (params.type === "monthly" || params.type === "weekly") {
          const formattedEvents = info
            ?.filter((event) => event.classes && event.classes.length > 0) // ✅ Remove empty class lists
            ?.flatMap((event) =>
              event.classes
                .filter((cls) => cls.startTime && cls.endTime) // ✅ Exclude invalid time entries
                .map((cls) => ({
                  title: cls.className,
                  date: event.date,
                  start: `${event.date}T${cls.startTime}`,
                  end: `${event.date}T${cls.endTime}`,
                  description: cls.categoryType,
                  instructor: cls.instructor, // ✅ Added instructor
                }))
            );

          setEvents((prev) =>
            JSON.stringify(prev) !== JSON.stringify(formattedEvents)
              ? formattedEvents
              : prev
          );
        }

        if (params.type === "daily") {
          const formattedEvents = info.map((cls) => ({
            title: cls.className,
            date: currentDate,
            start: `${currentDate}T${cls.startTime}`,
            end: `${currentDate}T${cls.endTime}`,
            description: cls.categoryType,
            instructor: cls.instructor, // ✅ Added instructor
          }));

          setEvents((prev) =>
            JSON.stringify(prev) !== JSON.stringify(formattedEvents)
              ? formattedEvents
              : prev
          );
        }
      }
    } catch (error) {
      console.error("Error fetching calendar data:", error);
    }
  };

  useEffect(() => {
    fetchCalendarData();
  }, [fetchCalendarData, type, currentDate]);

  return (
    <div>
      {/* ...........Header in Calendar.................. */}
      <div className="calendar-header">
        <h1 className="calendar-title">My Calendar</h1>
        <div className="calendar-actions">
          <p className="calendar-status">Google calendar connected</p>
          <p className="calendar-change">Change</p>
        </div>
      </div>

      {/* ...........Calendar body....................... */}
      <div className="calendar-container">
        {/* Calendar Subheader */}
        <div className="calendar-subheader">
          {filters && (
            <Button
              className="calendar-add-btn"
              size="large"
              onClick={openModalTwo}
            >
              <PlusOutlined />
              Add date specific hours
            </Button>
          )}
          <Input
            size="large"
            placeholder="Filter by class"
            prefix={<SearchOutlined />}
            className="calendar-filter-input"
          />
        </div>

        {/* Calendar Component */}
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin]}
          initialView="dayGridMonth"
          events={events}
          eventClick={handleEventClick}
          datesSet={handleViewChange}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,dayGridWeek,timeGridDay",
          }}
          editable={true} // Enables drag & drop
        />
      </div>

      {/* Modal to show event details */}
      <Modal
        // title="Event Details"
        open={modalIsOpen}
        onCancel={closeModal}
        footer={null}
        mask={false}
        centered={true}
        width="auto"
      >
        {selectedEvent && (
          <div className="event-container">
            <div className="event-img">IMG</div>
            <div className="event-details">
              <div className="event-title">
                <h2>{selectedEvent.title}</h2>
                <p>Instructor: {selectedEvent.instructor}</p>
              </div>
              <div className="event-time">
                <span>
                  <CalendarOutlined />
                </span>
                <span>{selectedEvent.date}</span>
                <span>|</span>
                <span>{`${selectedEvent.start}-${selectedEvent.end}`}</span>
              </div>
              <div className="event-buttons">
                {/* <button className="btn-outline">Reschedule Class</button>
                <button className="btn-primary">Join Class</button> */}
              </div>
            </div>
          </div>
        )}
      </Modal>
      <Modal
        // title="Event Details"
        open={modalTwoIsOpen}
        onCancel={closeModalTwo}
        footer={null}
        mask={false}
        centered={true}
        width="auto"
      >
        <ModalCalendar setModalTwoIsOpen={setModalTwoIsOpen} />
      </Modal>
    </div>
  );
};

export default Calendar;
