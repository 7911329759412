import React, { useRef, useState } from "react";
import "./AddProducts.scss";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Loader from "../../../Components/Loader/Loader";
import Buttons from "../../../Components/Buttons/Buttons";
import { LeftCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Card, Input, Select, Upload } from "antd";
import FormInputs from "../../../Components/FormInputs/FormInputs";
import SelectInputs from "../../../Components/SelectInputs/SelectInputs";
const { Option } = Select;
const AddProducts = ({ setShowAddProducts }) => {
  const fileInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState([]);
  const [errors, setErrors] = useState(false);
  const [editorContent, setEditorContent] = useState(false);
  const [images, setImages] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [price, setPrice] = useState("");
  const [comparePrice, setComparePrice] = useState("");
  const [chargeTax, setChargeTax] = useState(false);
  const [costPerItem, setCostPerItem] = useState(false);
  const [profit, setProfit] = useState(false);
  const [margin, setMargin] = useState(false);
  const [selectedInventoryId, setSelectedInventoryId] = useState(null);
  const [selectedInventory, setSelectedInventory] = useState();
  const [checkQuantity, setCheckQuantity] = useState(false);
  const [statusId, setStatusId] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [productType, setProductType] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [collections, setCollections] = useState([]);
  const [tags, setTags] = useState([]);
  const hanldeBack = () => {
    setShowAddProducts(false);
  };
  const handleImageRemove = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleChange = (info) => {
    let newFileList = [...info.fileList];

    newFileList = newFileList.slice(-2);

    newFileList = newFileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    setFileList(newFileList);
  };
  const props = {
    action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
    onChange: handleChange,
    multiple: true,
  };

  return (
    <div className="addproducts">
      {isLoading && <Loader />}
      <div className="addproducts_header">
        <h2>
          <LeftCircleOutlined
            style={{ cursor: "pointer" }}
            onClick={hanldeBack}
          />{" "}
          Add New Products
        </h2>
        <Buttons variant="primary">Save</Buttons>
      </div>
      <div className="addproducts_container">
        <div className="addproducts_container_left">
          <div className="addproducts_container_left_content">
            <Card>
              <div className="form-group">
                <label>Title</label>
                <FormInputs
                  type="text"
                  placeholder="yoga class"
                  className="title-input"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                {errors.title && <p className="error">{errors.title}</p>}
              </div>
              <div className="form-group">
                <label>Description</label>
                <ReactQuill
                  theme="snow"
                  value={editorContent}
                  onChange={setEditorContent}
                  placeholder="Write your class description here..."
                />
                {errors.editorContent && (
                  <p className="error">{errors.editorContent}</p>
                )}
              </div>
              <div className="form-group">
                <label>Media</label>
                <div className="media-section">
                  <div className="media-preview">
                    {images.map((image, index) => (
                      <div key={index} className="image-preview-cont">
                        <div className="uploaded-image">
                          <img src={image} alt={`Preview ${index + 1}`} />
                        </div>
                        <button
                          className="remove-image"
                          onClick={() => handleImageRemove(index)}
                        >
                          ×
                        </button>
                      </div>
                    ))}
                  </div>
                  <div className="media-upload">
                    {/* <input
                    ref={fileInputRef}
                    type="file"
                    accept="image/*"
                    onChange={handleFileSelect}
                    style={{ display: "none" }}
                  />
                  <button
                    className="upload-button"
                    onClick={() => fileInputRef.current?.click()}
                  >
                    + Upload  Images & Videos
                  </button> */}
                    <Upload {...props} fileList={fileList}>
                      <Button className=" upload_btn" icon={<UploadOutlined />}>
                        Upload Images & Videos
                      </Button>
                    </Upload>
                  </div>

                  <div className="form-group">
                    <label style={{ display: "block", marginBottom: "8px" }}>
                      Select category
                    </label>
                    <div
                      className="category-section"
                      style={{
                        display: "flex",
                        gap: "16px",
                        alignItems: "center",
                      }}
                    >
                      <Select
                        placeholder="Select category"
                        name="category"
                        size="large"
                        style={{ width: "40%" }}
                        onChange={handleChange}
                        value={selectedCategoryId}
                      >
                        {selectedCategory?.map((category) => (
                          <Option key={category.id} value={category.id}>
                            {category.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
            <Card style={{ marginTop: "10px" }} >
              <div className="addcls_cont_sec_main">
                <h3 className="addcls_cont_sec_main_title">Pricing</h3>
                <div
                  className="addcls_cont_sec_main_title_inputs"
                  style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}
                >
                  <div className="addcls_cont_sec_main_title_inputs_group">
                    <div className="price-field">
                      <FormInputs
                        type="number"
                        title="Price"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        placeholder="20.00"
                      />
                      {errors.price && <p className="error">{errors.price}</p>}
                    </div>
                  </div>

                  <div className="addcls_cont_sec_main_title_inputs_group">
                    <div className="price-field">
                      <FormInputs
                        type="number"
                        value={comparePrice}
                        title="Compare-at price"
                        onChange={(e) => setComparePrice(e.target.value)}
                        placeholder="30.00"
                      />
                      {errors.comparePrice && (
                        <p className="error">{errors.comparePrice}</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="addcls_cont_sec_main_option">
                  <label className="addcls_cont_sec_main_option_cont">
                    <input
                      type="checkbox"
                      checked={chargeTax}
                      onChange={(e) => setChargeTax(e.target.checked)}
                    />
                    <span className="checkmark"></span>
                    Charge tax on this product
                  </label>
                </div>
                <hr />
                <div
                  className="addcls_cont_sec_main_title_inputs"
                  style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}
                >
                  <div className="addcls_cont_sec_main_title_inputs_group">
                    <div className="price-field">
                      <FormInputs
                        type="number"
                        title="Cost Per Item"
                        value={costPerItem}
                        onChange={(e) => setCostPerItem(e.target.value)}
                        placeholder="$ 00.00"
                      />
                      {errors.costPerItem && (
                        <p className="error">{errors.costPerItem}</p>
                      )}
                    </div>
                  </div>

                  <div className="addcls_cont_sec_main_title_inputs_group">
                    <div className="price-field">
                      <FormInputs
                        type="number"
                        value={profit}
                        title="Profit"
                        onChange={(e) => setProfit(e.target.value)}
                        placeholder="--"
                      />
                      {errors.profit && (
                        <p className="error">{errors.profit}</p>
                      )}
                    </div>
                  </div>
                  <div className="addcls_cont_sec_main_title_inputs_group">
                    <div className="price-field">
                      <FormInputs
                        type="number"
                        value={margin}
                        title="Margin"
                        onChange={(e) => setMargin(e.target.value)}
                        placeholder="--"
                      />
                      {errors.margin && (
                        <p className="error">{errors.margin}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Card>
            <Card style={{ marginTop: "10px" }} >
                   <div className="form-group">
                <label>Inventory</label>
                <p>Inventory will be stocked at</p>
                  <Select
                        placeholder="Select category"
                        name="category"
                        size="large"
                        style={{ width: "40%" }}
                        onChange={handleChange}
                        value={selectedInventoryId}
                      >
                        {selectedInventory?.map((category) => (
                          <Option key={category.id} value={category.id}>
                            {category.name}
                          </Option>
                        ))}
                      </Select>
                {errors.selectedInventoryId && <p className="error">{errors.selectedInventoryId}</p>}
                <div className="addcls_cont_sec_main_option">
                  <label className="addcls_cont_sec_main_option_cont">
                    <input
                      type="checkbox"
                      checked={checkQuantity}
                      onChange={(e) => setCheckQuantity(e.target.checked)}
                    />
                    <span className="checkmark"></span>
                    Track quantity
                  </label>
                </div>
                <div style={{display:"flex",justifyContent:"space-between",width:"100%"}}>
                  <label className="addcls_cont_sec_main_option_cont">
              Inventory
                  </label>
                  <FormInputs
                  type="text"
                  placeholder="0"
                  className="title-input"
                //   value={title}
                //   onChange={(e) => setTitle(e.target.value)}
                />
                </div>
              </div>
            </Card>
          </div>
        </div>
        <div className="addproducts_container_right">
            <Card>
            <div className="form-group">
                <label>Status</label>
             
                <Select
                        placeholder="Active"
                        name="category"
                        size="large"
                        style={{ width: "40%" }}
                        onChange={handleChange}
                        value={statusId}
                      >
                        {selectedStatus?.map((category) => (
                          <Option key={category.id} value={category.id}>
                            {category.name}
                          </Option>
                        ))}
                      </Select>
                {errors.statusId && <p className="error">{errors.statusId}</p>}
              </div>
            </Card>

            <Card style={{marginTop:"10px"}}>
            <div className="form-group1">
                <h3>Product Organization</h3>
                <label>Product Type</label>
                <FormInputs
                  type="text"
                  placeholder="----"
                  className="title-input"
                  value={productType}
                  onChange={(e) => setProductType(e.target.value)}
                />
                {errors.productType && <p className="error">{errors.productType}</p>}
              
              </div>
              <div className="form-group1">
                
                <label>Vendor</label>
                <FormInputs
                  type="text"
                  placeholder="----"
                  className="title-input"
                  value={vendor}
                  onChange={(e) => setVendor(e.target.value)}
                />
                {errors.vendor && <p className="error">{errors.vendor}</p>}
              
              </div>
              <div className="form-group1">
               
                <label>Collections</label>
                <FormInputs
                  type="text"
                  placeholder="----"
                  className="title-input"
                  value={collections}
                  onChange={(e) => setCollections(e.target.value)}
                />
                {errors.collections && <p className="error">{errors.collections}</p>}
              
              </div>
              <div className="form-group1">
               
               <label>Tags</label>
               <FormInputs
                 type="text"
                 placeholder="----"
                 className="title-input"
                 value={tags}
                 onChange={(e) => setTags(e.target.value)}
               />
               {errors.tags && <p className="error">{errors.tags}</p>}
             
             </div>
            </Card>
        </div>
      </div>
    </div>
  );
};

export default AddProducts;
