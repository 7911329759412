import axios from "axios";
import { BASE_ADMIN_API_URI } from "./constant";

axios.defaults.baseURL = BASE_ADMIN_API_URI;

axios.interceptors.request.use(config => {
  const authToken = localStorage.getItem("accessToken");
  if (authToken) {
    config.headers.Authorization = `Bearer ${authToken}`;
  }
  return config;
});

axios.interceptors.response.use(
  response => {
    // console.log("✅ Response Interceptor: Success", response);

    // Check if response body contains a 401 error
    if (response.data?.status === 401) {
      // console.warn("⚠️ Token expired inside a 200 response. Redirecting to Login...");
      localStorage.removeItem("accessToken");

      if (window.location.pathname !== "/") {
        window.location.href = "/";
      }
      return Promise.reject(new Error(response.data.message || "Unauthorized"));
    }

    return response.data;
  },
  error => {
    // console.log("❌ Response Interceptor: Error", error);

    if (!error.response) {
      // console.error("🚨 Error has no response object!", error);
      return Promise.reject(error);
    }

    const { status } = error.response;

    if (status === 401) {
      // console.warn("🔴 Unauthorized: Redirecting to Login...");
      localStorage.removeItem("accessToken");
      window.location.href = "/login";
      return;
    }

    return Promise.reject(error);
  }
);



export const apiRequest = async ({ method, url, req, params }) => {
  try {
    if (method === "get") return await axios[method](url, params);
    return await axios[method](url, req, params);
  } catch (error) {
    return { error: error.message };
  }
};


export const getApi = async (url, params) => await apiRequest({ method: "get", url, params });
export const postApi = async (url, req, params) => await apiRequest({ method: "post", url, req, params });
export const putApi = async (url, req, params) => await apiRequest({ method: "put", url, req, params });
export const deleteApi = async (url, req, params) => await apiRequest({ method: "delete", url, params });
export const patchApi = async (url, req, params) => await apiRequest({ method: "patch", url, req, params });
