import React, { useEffect, useState, useCallback } from "react";
import "./Students.scss";
import { SearchOutlined, SortAscendingOutlined } from "@ant-design/icons";
import { Table, Button, Input, Pagination } from "antd";
import { GET_ALL_ACTIVE_STUDENTS } from "../../Utlis/apiPath";
import { getApi } from "../../Utlis/apiService";
import debounce from "lodash.debounce"; // Import lodash debounce

const Students = () => {
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState(""); // Debounced search state
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [tableData, setTableData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  // Debounce function
  const debounceSearch = useCallback(
    debounce((value) => {
      setDebouncedSearch(value);
      setPageIndex(1); // Reset to first page when searching
    }, 500),
    []
  );

  useEffect(() => {
    fetchAllActiveStudents();
  }, [debouncedSearch, pageIndex, pageSize]);

  const fetchAllActiveStudents = async () => {
    setIsLoading(true);
    try {
      const url = `${GET_ALL_ACTIVE_STUDENTS}`;
      const params = {
        search: debouncedSearch, // Pass search query
        pageIndex,
        pageSize,
      };

      const { status, data } = await getApi(url, { params });
      if (status === 200) {
        const { students, totalRecords } = data;
        const updatedData = students.map((student, index) => ({
          ...student,
          slNo: (pageIndex - 1) * pageSize + index + 1,
        }));
        setTableData(updatedData);
        setTotalRecords(totalRecords);
      } else {
        setTableData([]);
        setTotalRecords(0);
      }
    } catch (error) {
      console.error("Error fetching active students:", error);
      setTableData([]);
      setTotalRecords(0);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    debounceSearch(e.target.value);
  };

  const handlePageChange = (page, size) => {
    setPageIndex(page);
    setPageSize(size);
  };

  const columns = [
    { title: "Sl. No", dataIndex: "slNo", key: "slNo" },
    { title: "Student Name", dataIndex: "studentName", key: "studentName" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Contact Number", dataIndex: "contactNumber", key: "contactNo" },
    { title: "Class Name", dataIndex: "classes", key: "className" },
    {
      title: "Amount Spent",
      dataIndex: "totalAmountSpent",
      key: "amountSpent",
    },
  ];

  return (
    <div className="student">
      <div className="student_header">
        <h2>Students</h2>
      </div>

      <div className="student_content">
        <Input
          placeholder="Search"
          prefix={<SearchOutlined />}
          value={search}
          onChange={handleSearch}
          style={{ marginRight: 10, width: "200px" }}
        />
        <Button icon={<SortAscendingOutlined />} />
      </div>

      <Table
        columns={columns}
        dataSource={tableData}
        loading={isLoading}
        pagination={false}
        rowKey="id"
      />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Pagination
          current={pageIndex}
          total={totalRecords}
          pageSize={pageSize}
          showSizeChanger
          onChange={handlePageChange}
          onShowSizeChange={handlePageChange}
          style={{ marginTop: 16 }}
        />
      </div>
    </div>
  );
};

export default Students;
