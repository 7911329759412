// Admin Auth
export const ADMIN_LOGIN_DETAILS = "auth/login";

//Category
export const ADD_NEW_CATEGORY = "category";
export const GET_ALL_CATEGORY = "category";
export const UPDATE_CATEGORY = "category";
export const DELETE_CATEGORY_BY_ID = "category";
export const GET_CATEGORY_BY_ID = "category/byid";


//Instructor
export const ADD_NEW_INSTRUCTOR = "instructors/create";
export const GET_ALL_INSTRUCTORS = "instructors";
export const APPROVE_INSTRUCTOR = "instructors/approve-deny";
export const GET_INSTRUCTOR_DROPDOWN = "instructors/dropdown"
export const UPDATE_INSTRUCTORS = "instructors/manage";
export const GET_INSTRUCTOR_SLOTS = "instructors/slots"
export const GET_AVAILABLE_INSTRUCTOR_SLOTS = "instructors/available-slots"
export const GET_ASSIGNED_CLASSES = "instructors/assigned-classes"
export const UPDATE_INSTRUCTOR_SLOTS = "instructors/update-slots"




//class

export const GET_ALL_CLASSESS = "classes";
export const DELETE_CLASS_BY_ID = "classes";
export const ADD_NEW_CLASS = "classes"
export const GET_CATEGORY_DROPDOWN = "classes/categories/dropdown"
export const UPDATE_CLASS = "classes"



//students

export const GET_ALL_ACTIVE_STUDENTS = "student/activebooking"


//bookings

export const GET_ALL_BOOKINGS = "booking/bookedclassdetail"
export const GET_ALL_CLASSESS_COUNT = "booking/bookedclasscounts"


// calender
export const GET_ALL_CALENDERDATA = "classes/calendar"

// Reschedule

export const GET_ALL_RESCHEDULELIST = "classes/rescheduled"
export const APPROVED_RESCHEDULE_CLASS = "classes/approve-reschedule"

//Dashboad

export const GET_ALL_DASHBOARD_DATA = "dashboard"

//Enquiry


export const GET_ALL_ENQUIRY_LIST = "demo/enquiries"
