import React, { useEffect, useState } from "react";
import "./Enquiry.scss";
import {
  SearchOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from "@ant-design/icons";
import { Table, Button, Input } from "antd";
import { getApi } from "../../Utlis/apiService";
import { GET_ALL_ENQUIRY_LIST } from "../../Utlis/apiPath";
import { debounce } from "lodash";
import Loader from "../../Components/Loader/Loader";
import { Modal, Typography, Row, Col } from "antd";
import { EyeOutlined } from "@ant-design/icons";
const { Text } = Typography;

const Enquiry = () => {
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [isAscending, setIsAscending] = useState("");
  const [selectedInstructor, setSelectedInstructor] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const getPageSizeOptions = () => {
    return totalRecords <= 50 ? ["10", "20", "50"] : ["15", "25", "50", "100"];
  };

  const fetchTableData = async (page = 1, pageSize = 10, searchValue = "") => {
    setIsLoading(true);
    try {
      const response = await getApi(GET_ALL_ENQUIRY_LIST);
      if (response.status === 200) {
        setTableData(response.data);
        setTotalRecords(response.data);
      } else {
        setTableData([]);
        setTotalRecords(0);
      }
    } catch (error) {
      setTableData([]);
      console.error("Error fetching table data:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setTableData([]);
    fetchTableData(1, 10);
  }, [isAscending]);

  const handleSorting = () => {
    setIsAscending((prevState) => (prevState === "" ? false : !prevState));
  };

  const handleSearch = debounce((value) => {
    setSearch(value);
    fetchTableData(1, pageSize, value);
  }, 500);

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    setPageIndex(current);
    setPageSize(pageSize);
    fetchTableData(current, pageSize);
  };

  const showEnquiryDetails = (record) => {
    setSelectedInstructor(record);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedInstructor(null);
  };

  const instructorListColumns = [
    {
      title: "SL No",
      key: "slNo",
      render: (text, record, index) => (pageIndex - 1) * pageSize + index + 1,
    },
    { title: "Full Name", dataIndex: "fullName", key: "fullName" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Mobile Number", dataIndex: "mobileNumber", key: "mobileNumber" },
    { title: "Category", dataIndex: "category", key: "category" },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        // <Button
        //   icon={<EyeOutlined />}
        //   onClick={() => showEnquiryDetails(record)}
        // />
        <div
          style={{
            display: "inline-block",
            padding: "4px 8px",
            border: "2px solid #1890ff",
            borderRadius: "4px",
            cursor: "pointer",
          }}
          onClick={() => showEnquiryDetails(record)}
        >
          <EyeOutlined
            style={{
              fontSize: "16px",
              color: "#1890ff",
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="inst">
      {isLoading && <Loader />}
      <div className="inst_header">
        <h2>Enquiry</h2>
      </div>
      <div className="inst_content">
        <div className="inst_content_search">
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <Button
            icon={
              isAscending === true ? (
                <SortAscendingOutlined />
              ) : (
                <SortDescendingOutlined />
              )
            }
            className="sortButton"
            onClick={handleSorting}
          />
        </div>
      </div>
      <div className="inst_table">
        <Table
          columns={instructorListColumns}
          dataSource={tableData}
          pagination={{
            pageSize,
            total: totalRecords,
            showSizeChanger: true,
            pageSizeOptions: getPageSizeOptions(),
          }}
          loading={isLoading}
          onChange={handleTableChange}
          scroll={{ x: "max-content" }}
        />
      </div>
      <Modal
        title="Instructor Details"
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
      >
        {selectedInstructor && (
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Text strong>Full Name:</Text>
              <Text> {selectedInstructor.fullName}</Text>
            </Col>
            <Col span={12}>
              <Text strong>Email:</Text>
              <Text> {selectedInstructor.email}</Text>
            </Col>
            <Col span={12}>
              <Text strong>Mobile Number:</Text>
              <Text> {selectedInstructor.mobileNumber}</Text>
            </Col>
            <Col span={12}>
              <Text strong>Category:</Text>
              <Text> {selectedInstructor.category}</Text>
            </Col>
            <Col span={12}>
              <Text strong>Status:</Text>
              <Text>
                {" "}
                {selectedInstructor.isActive ? "Active" : "Inactive"}
              </Text>
            </Col>
            <Col span={12}>
              <Text strong>Created At:</Text>
              <Text>
                {" "}
                {new Date(selectedInstructor.createdAt).toLocaleDateString()}
              </Text>
            </Col>
            <Col span={12}>
              <Text strong>Message:</Text>
              <Text> {selectedInstructor.message}</Text>
            </Col>
          </Row>
        )}
      </Modal>
    </div>
  );
};

export default Enquiry;
