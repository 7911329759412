import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import { DatePicker } from "antd";
import { Tabs } from "antd";
import "react-quill/dist/quill.snow.css";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import "./ClassManagementForm.scss";
import Buttons from "../../../Components/Buttons/Buttons";
import FormInputs from "../../../Components/FormInputs/FormInputs";
import { Checkbox, Select, Switch } from "antd";
import { Modal, Divider, Button, Row, Col, Typography } from "antd";
import { TimePicker } from "antd";
import { Card, Radio } from "antd";
import SelectInputs from "../../../Components/SelectInputs/SelectInputs";
import {
  PlusOutlined,
  DeleteOutlined,
  LeftCircleOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import {
  ADD_NEW_CLASS,
  GET_AVAILABLE_INSTRUCTOR_SLOTS,
  GET_CATEGORY_DROPDOWN,
  GET_INSTRUCTOR_DROPDOWN,
  GET_INSTRUCTOR_SLOTS,
  UPDATE_CLASS,
  UPDATE_INSTRUCTOR_SLOTS,
} from "../../../Utlis/apiPath";
import { getApi, postApi, putApi } from "../../../Utlis/apiService";
import { errorToast, successToast } from "../../../Services/ToastHelper";
import { BackArrowIcon } from "../../../Components/Icons/BackArrowIcon";
const { Option } = Select;
const { Title, Paragraph } = Typography;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

const ClassManagementForm = ({
  setAddClassForm,
  fetchAllClasses,
  classEditedData,
}) => {
  const [editorContent, setEditorContent] = useState("");
  const [title, setTitle] = useState("");
  const [totalAttendence, setTotalAttendence] = useState(1);
  const [selectedOption, setSelectedOption] = useState("");
  const [classAddress, setClassAddress] = useState("");
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedInstructor, setSelectedInstructor] = useState();
  const [customerSchedule, setCustomerSchedule] = useState();
  const [joiningLink, setJoiningLink] = useState("");
  // const [crop, setCrop] = useState({ unit: "%", width: 90, aspect: 1 });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [price, setPrice] = useState("");
  const [comparePrice, setComparePrice] = useState("");
  const [usdPrice, setUsdPrice] = useState();
  const [compareUsdPrice, setCompareUsdPrice] = useState();
  const [dateError, setDateError] = useState("");
  const [chargeTax, setChargeTax] = useState(false);
  const [checked, setChecked] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedInstructorId, setSelectedInstructorId] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const [slotsData, setSlotsData] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [croppedImages, setCroppedImages] = useState([]);
  const [description, setDescription] = useState("");
  const fileInputRef = useRef(null);
  const [removedImageIds, setRemovedImageIds] = useState([]);
  const [newlyAddedSlots, setNewlyAddedSlots] = useState([]);
  const [removedSlotIds, setRemovedSlotIds] = useState([]);
  const [assignedSlots, setAssignedSlots] = useState();
  const [assignedSlotId, setAssignedSlotsId] = useState();
  const [subscriptionPrice, setSubscriptionPrice] = useState(null);
  const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
  const [slotIds, setSlotIds] = useState([]);
  const [selectedSlotIds, setSelectedSlotIds] = useState([]);
  const [newlycreatedSlotIds, setNewlycreatedSlotIds] = useState();
  const [newInstructor, setNewInstructor] = useState();
  const [crop, setCrop] = useState({
    unit: "px", // Use pixels
    width: 200, // Set fixed width
    height: 200, // Set fixed height
    x: 50,
    y: 50,
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [schedule, setSchedule] = useState({
    Monday: { isActive: false, slots: [] },
    Tuesday: { isActive: false, slots: [] },
    Wednesday: { isActive: false, slots: [] },
    Thursday: { isActive: false, slots: [] },
    Friday: { isActive: false, slots: [] },
    Saturday: { isActive: false, slots: [] },
    Sunday: { isActive: false, slots: [] },
  });

  console.log(schedule, "schedule");
  // console.log(startDate, "startDate");
  // console.log(endDate, "endDate");

  // console.log(selectedValue, "selectedValue");

  useEffect(() => {
    if (selectedImage) {
      setCrop({
        unit: "px",
        width: 300,
        height: 300,
        x: 50,
        y: 50,
      });
    }
  }, [selectedImage]);

  const handleToggleSlot = (day, slot) => {
    const slotId = slot.id;

    setSelectedSlotIds((prev) => {
      if (prev.includes(slotId)) {
        // If already selected, remove it
        const updatedSlots = prev.filter((id) => id !== slotId);
        // console.log("Selected Slot IDs:", updatedSlots);
        return updatedSlots;
      } else {
        // Otherwise, add it
        const updatedSlots = [...prev, slotId];
        // console.log("Selected Slot IDs:", updatedSlots);
        return updatedSlots;
      }
    });
  };

  const isSlotSelected = (slotId) => selectedSlotIds.includes(slotId);

  const [newRemovedSlotIds, setNewRemovedSlotIds] = useState([]);

  const handleRemoveSlot = (day, slotId) => {
    setAssignedSlots((prevSlots) => {
      const updatedSlots = { ...prevSlots };
      updatedSlots[day] = updatedSlots[day].filter(
        (slot) => slot.id !== slotId
      );

      if (updatedSlots[day].length === 0) {
        delete updatedSlots[day];
      }

      setNewRemovedSlotIds((prevIds) => [...prevIds, slotId]);
      return updatedSlots;
    });
  };

  // console.log("assignedSlotId:", assignedSlotId);
  // console.log("newRemovedSlotIds:", newRemovedSlotIds);
  // console.log("selectedSlotIds:", selectedSlotIds);
  // console.log("newlycreatedSlotIds:", newlycreatedSlotIds);

  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const filteredDays = Object.keys(schedule).filter((day) => {
    if (selectedOption === "hourly" && startDate && endDate) {
      const dayDate = dayjs(day, "dddd"); // Convert day name to date
      return dayDate.isAfter(startDate) && dayDate.isBefore(endDate);
    }
    return true; // Show all days for non-hourly classes
  });
  const handleStartDateChange = (date) => {
    setStartDate(date);
    if (date) setDateError(""); // Clear error when a date is selected
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    if (date) setDateError(""); // Clear error when a date is selected
  };

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      setStartDate(dates[0]);
      setEndDate(dates[1]);
      setDateError(""); // Clear error when valid dates are selected
    } else {
      setStartDate(null);
      setEndDate(null);
      setDateError("Please select a valid date range.");
    }
  };

  const updateSchedule = (start, end) => {
    if (!start || !end) return;

    const startDay = dayjs(start).day();
    const endDay = dayjs(end).day();

    const filteredSchedule = {};

    weekdays.forEach((day, index) => {
      if (
        (startDay <= endDay && index >= startDay && index <= endDay) ||
        (startDay > endDay && (index >= startDay || index <= endDay))
      ) {
        filteredSchedule[day] = schedule[day]
          ? { ...schedule[day] }
          : { isActive: false, slots: [] };
      }
    });

    setSchedule(filteredSchedule);
  };

  useEffect(() => {
    updateSchedule(startDate, endDate);
  }, [startDate, endDate, classEditedData, isModalVisible]);

  useEffect(() => {
    if (classEditedData) {
      const data = classEditedData[0];
      setTitle(data.title || "");
      setEditorContent(data.description || "");
      setPrice(data.price || "");
      setComparePrice(data.compareAtPrice || "");
      setCompareUsdPrice(data.compareUsdPrice || "");
      setUsdPrice(data.usdPrice || "");
      setEndDate(dayjs(data.hourlyenddate || ""));
      setStartDate(dayjs(data.hourlystartdate || ""));
      setChargeTax(data.chargeTax || false);
      setSelectedOption(data.bookingType || "");
      setClassAddress(data.classAddress || "");
      setSelectedValue(data.joinType || null);
      setJoiningLink(data.joiningLink || "");
      setTotalAttendence(data.totalAttendees || "");
      setSubscriptionPrice(data.subscription || "");
      setIsChecked(data.addReview || false);
      setSelectedCategoryIds(
        data.categories?.map((category) => category.id) || []
      );
      setSelectedInstructorId(data.instructor?.id || null);
      setSelectedGroup(data.classType || null);

      setDuration({
        hours: `${data.durationHour || 0} Hour`,
        minutes: `${data.durationMunite || 0} minutes`,
      });
      setBuffer({
        hours: `${data.bufferHour || 0} Hour`,
        minutes: `${data.bufferMunite || 0} minutes`,
      });

      const mappedImages = (data.classImages || []).map((media) => ({
        type: "existing",
        id: media.id,
        url: `${media.url}`,
      }));
      setCroppedImages(mappedImages);
      const groupedSlots = data?.slots?.reduce((acc, slot) => {
        if (!acc[slot.day]) {
          acc[slot.day] = [];
        }
        acc[slot.day].push({
          id: slot.id,
          startTime: slot.startTime,
          endTime: slot.endTime,
        });
        return acc;
      }, {});

      setAssignedSlots(groupedSlots);
      const slotIds = Object.values(groupedSlots)
        .flat()
        .map((slot) => slot.id);
      setAssignedSlotsId(slotIds);
    }
  }, [classEditedData]);

  const [duration, setDuration] = useState({
    hours: "0 Hour",
    minutes: "30 minutes",
  });
  const [increment, setIncrement] = useState({
    hours: "0 Hour",
    minutes: "30 minutes",
  });
  const [buffer, setBuffer] = useState({
    hours: "0 Hour",
    minutes: "30 minutes",
  });
  const [checkboxes, setCheckboxes] = useState({
    checkbox1: false,
    checkbox2: false,
  });

  const initialSchedule = {
    Monday: { isActive: false, slots: [] },
    Tuesday: { isActive: false, slots: [] },
    Wednesday: { isActive: false, slots: [] },
    Thursday: { isActive: false, slots: [] },
    Friday: { isActive: false, slots: [] },
    Saturday: { isActive: false, slots: [] },
    Sunday: { isActive: false, slots: [] },
  };

  // console.log("schedule:", schedule);
  const [errors, setErrors] = useState({
    title: "",
    editorContent: "",
    price: "",
    usdPrice: "",
    comparePrice: "",
    compareUsdPrice: "",
    totalAttendence: "",
    customerSchedule: "",
    joiningLink: "",
    selectedInstructorId: "",
    selectedCategoryIds: "",
    selectedValue: "",
    classAddress: "",
    selectedGroup: "",
  });

  const handleJoiningLinkChange = (e) => {
    setJoiningLink(e.target.value);
  };

  const handleChange = (value) => {
    setSelectedCategoryIds(value); // Update with an array of selected IDs
  };

  const handleInstructorChange = (value) => {
    setAssignedSlots();
    setAssignedSlotsId();
    setSelectedInstructorId(value);

    // fetchInstructorSlots(value);
    // setIsModalVisible(true);
  };

  const handleReviewChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleToggle = (day) => {
    setSchedule((prev) => {
      const isCurrentlyActive = prev[day].isActive;

      if (isCurrentlyActive) {
        const slotIds = prev[day].slots.map((slot) => slot.id);
        setRemovedSlotIds((prevIds) => [...prevIds, ...slotIds]);
      }

      return {
        ...prev,
        [day]: { ...prev[day], isActive: !isCurrentlyActive },
      };
    });
  };

  const addTimeSlot = (day) => {
    setSchedule((prev) => {
      const existingSlots = prev[day].slots;

      // Sort slots by start time
      existingSlots.sort((a, b) =>
        dayjs(a.start, "HH:mm").diff(dayjs(b.start, "HH:mm"))
      );

      // Default new slot to start after the last existing slot
      let lastSlot = existingSlots[existingSlots.length - 1];
      let newStart = lastSlot
        ? dayjs(lastSlot.end, "HH:mm").add(1, "minute")
        : dayjs("00:00", "HH:mm");
      let newEnd = newStart.add(1, "hour");

      // Prevent exceeding 24 hours
      if (newEnd.isAfter(dayjs("23:59", "HH:mm"))) {
        errorToast("Cannot add slot beyond 24-hour range.");
        return prev;
      }

      return {
        ...prev,
        [day]: {
          ...prev[day],
          slots: [
            ...existingSlots,
            { start: newStart.format("HH:mm"), end: newEnd.format("HH:mm") },
          ],
        },
      };
    });
  };

  const handleTimeChange = (day, index, time, type) => {
    setSchedule((prev) => {
      let updatedSlots = [...prev[day].slots];
      let newTime = time.format("HH:mm");

      // Update the selected time
      updatedSlots[index] = { ...updatedSlots[index], [type]: newTime };

      // Sort slots after updating
      updatedSlots.sort((a, b) =>
        dayjs(a.start, "HH:mm").diff(dayjs(b.start, "HH:mm"))
      );

      // Check for overlapping slots
      for (let i = 0; i < updatedSlots.length - 1; i++) {
        let currentEnd = dayjs(updatedSlots[i].end, "HH:mm");
        let nextStart = dayjs(updatedSlots[i + 1].start, "HH:mm");

        if (currentEnd.isAfter(nextStart)) {
          errorToast("Slots cannot overlap. Please adjust the timing.");
          return prev;
        }
      }

      return { ...prev, [day]: { ...prev[day], slots: updatedSlots } };
    });
  };

  const removeTimeSlot = (day, index) => {
    setSchedule((prev) => {
      const slotToRemove = prev[day].slots[index];
      const updatedSlots = prev[day].slots.filter((_, i) => i !== index);

      if (slotToRemove?.id) {
        setRemovedSlotIds((prevIds) => [...prevIds, slotToRemove.id]);
      }

      return { ...prev, [day]: { ...prev[day], slots: updatedSlots } };
    });
  };

  const handleSave = () => {
    if (selectedOption === "hourly" && (!startDate || !endDate)) {
      setDateError("Start Date and End Date are required.");
      return;
    }

    setIsModalVisible(false);

    if (newlyAddedSlots.length > 0 || newRemovedSlotIds.length > 0) {
      UpdateInstructorSlots();
    }
  };

  const handleRadioChange = (value) => {
    setSelectedOption(value);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxes((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const hoursOptions = ["0 Hour"];
  const DurationHoursOptions = ["0 Hour", "1 Hour"];
  const minutesOptions = [
    "0 minutes",
    "15 minutes",
    "30 minutes",
    "45 minutes",
  ];

  const renderSelect = (value, onChange, options) => (
    <Select value={value} onChange={onChange} style={{ width: 120 }}>
      {options.map((opt) => (
        <Option key={opt} value={opt}>
          {opt}
        </Option>
      ))}
    </Select>
  );

  const handleDurationChange = (field, value) => {
    const updatedDuration = { ...duration, [field]: value };
    setDuration(updatedDuration);
  };

  const handleBufferChange = (field, value) => {
    const updatedBuffer = { ...buffer, [field]: value };
    setBuffer(updatedBuffer);
  };

  const handleSwitchChange = (checked) => {
    if (checked == true) {
      setChecked(1);
    }
  };

  // Image handling functions

  const handleSelectChange = (value) => {
    setSelectedValue(value);
    if (value === "online") {
      setClassAddress("");
    } else if (value === "offline") {
      setJoiningLink("");
    }
  };
  const handleClassAddressChange = (e) => {
    setClassAddress(e.target.value);
  };

  const options = [
    { id: "online", name: "Online" },
    { id: "offline", name: "Offline" },
  ];

  const options2 = [
    { id: "group", name: "Group" },
    { id: "one-on-one", name: "One On One" },
    { id: "premium", name: "premium" },
    { id: "custom", name: "custom" },
  ];

  const handleClassTypeChange = (value) => {
    if (value === "one-on-one") {
      setTotalAttendence(1);
      setSelectedGroup(value);
    } else {
      setSelectedGroup(value);
    }
  };

  const hanldeBack = () => {
    setAddClassForm(false);
  };

  const validateFields = () => {
    let valid = true;
    const newErrors = { ...errors };

    // Mandatory Fields Validation
    if (!title) {
      newErrors.title = "Title is required.";
      valid = false;
    } else {
      newErrors.title = "";
    }

    if (!editorContent) {
      newErrors.editorContent = "Description is required.";
      valid = false;
    } else {
      newErrors.editorContent = "";
    }

    if (!price) {
      newErrors.price = "Price is required.";
      valid = false;
    } else {
      newErrors.price = "";
    }
    if (!selectedValue) {
      newErrors.selectedValue = "Join Type is required.";
      valid = false;
    } else {
      newErrors.selectedValue = "";
    }

    if (!comparePrice) {
      newErrors.comparePrice = "Compare-at price is required.";
      valid = false;
    } else {
      newErrors.comparePrice = "";
    }
    if (!usdPrice) {
      newErrors.usdPrice = "usd Price price is required.";
      valid = false;
    } else {
      newErrors.usdPrice = "";
    }
    if (!compareUsdPrice) {
      newErrors.compareUsdPrice = "compare Usd Price price is required.";
      valid = false;
    } else {
      newErrors.compareUsdPrice = "";
    }

    // if (!totalAttendence) {
    //   newErrors.totalAttendence = "Total attendance is required.";
    //   valid = false;
    // } else {
    //   newErrors.totalAttendence = "";
    // }
    if (!joiningLink && selectedValue === "online") {
      newErrors.joiningLink = "Joining link is required for online classes.";
      valid = false;
    } else {
      newErrors.joiningLink = "";
    }

    if (!classAddress && selectedValue === "offline") {
      newErrors.classAddress = "Class address is required for offline classes.";
      valid = false;
    } else {
      newErrors.classAddress = "";
    }

    if (!selectedInstructorId) {
      newErrors.assignStaff = "Assigning staff is required.";
      valid = false;
    } else {
      newErrors.assignStaff = "";
    }

    if (!selectedCategoryIds.length > 0) {
      newErrors.selectedCategoryIds = "Category is required.";
      valid = false;
    } else {
      newErrors.selectedCategoryIds = "";
    }

    if (!selectedGroup) {
      newErrors.selectedGroup = "Class type is required."; // Set error under selectedGroup
      valid = false;
    } else {
      newErrors.selectedGroup = "";
    }

    // if (!duration) {
    //   newErrors.duration = "Duration is required.";
    //   valid = false;
    // } else {
    //   newErrors.duration = "";
    // }

    if (!selectedOption) {
      newErrors.bookingOption = "Please select either 'Hourly' or 'Monthly'.";
      valid = false;
    } else {
      newErrors.bookingOption = "";
    }

    setErrors(newErrors);
    return valid;
  };

  useEffect(() => {
    fetchCategoryDropDown();
  }, []);

  const fetchCategoryDropDown = async () => {
    setIsLoading(true);
    try {
      const url = `${GET_CATEGORY_DROPDOWN}`;
      const { status, message, data } = await getApi(url);
      if (status === 200) {
        setSelectedCategory(data);
      } else {
        setSelectedCategory([]);
      }
    } catch (error) {
      console.error("Error fetching classes:", error);
      setSelectedCategory([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchInstructorDropDown();
  }, []);

  const fetchInstructorDropDown = async () => {
    setIsLoading(true);
    try {
      const url = `${GET_INSTRUCTOR_DROPDOWN}`;
      const { status, message, data } = await getApi(url);
      if (status === 200) {
        setSelectedInstructor(data);
      } else {
        setSelectedInstructor([]);
      }
    } catch (error) {
      console.error("Error fetching classes:", error);
      setSelectedInstructor([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchInstructorSlots = async (id) => {
    setIsLoading(true);
    const params = { instructorId: id };
    const { status, data } = await getApi(GET_AVAILABLE_INSTRUCTOR_SLOTS, {
      params,
    });

    if (status === 200) {
      const ids = data.map((slot) => slot.id);
      setSlotIds(ids);
      patchScheduleData(data);
    }

    // else {
    //   patchScheduleData(schedule);
    // }
    setIsLoading(false);
  };

  useEffect(() => {
    const formattedArray = Object.entries(schedule).reduce(
      (acc, [day, data]) => {
        if (data.slots.length > 0) {
          const filteredSlots = data.slots.filter((slot) => !slot.id); // Filter out slots that have an id

          if (filteredSlots.length > 0) {
            acc.push({
              days: day,
              startEndTime: filteredSlots.map((slot) => ({
                startTime: slot.start,
                endTime: slot.end,
              })),
            });
          }
        }
        return acc;
      },
      []
    );

    setNewlyAddedSlots(formattedArray, null, 2);
  }, [schedule]);

  const UpdateInstructorSlots = async () => {
    setIsLoading(true);

    try {
      const payload = {
        instructorId: selectedInstructorId,
        slots: newlyAddedSlots,
        prevSlotIds: newRemovedSlotIds,
      };

      const { status, data } = await postApi(UPDATE_INSTRUCTOR_SLOTS, payload);
      const ids = data.newSlots.map((slot) => slot.id);
      setNewlycreatedSlotIds(ids);

      if (status === 200) {
        fetchInstructorSlots(selectedInstructorId);
        setNewlyAddedSlots();
      }
    } catch (error) {
      console.error("Error updating instructor slots:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const patchScheduleData = (slotsData) => {
    const updatedSchedule = { ...initialSchedule };
    slotsData.forEach((slot) => {
      const day = slot.day;
      if (updatedSchedule[day]) {
        updatedSchedule[day].isActive = true;
        updatedSchedule[day].slots.push({
          id: slot.id,
          start: slot.startTime,
          end: slot.endTime,
        });
      }
    });
    setSchedule(updatedSchedule);
  };

  const handleFileSelect = (e) => {
    if (croppedImages.length >= 4) {
      errorToast("You can only upload up to 4 images.");
      return;
    }

    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
        fileInputRef.current.value = ""; // Reset file input to allow re-selection of same file
      };
      reader.readAsDataURL(file);
    }
  };

  const getCroppedImg = (
    sourceImage,
    crop,
    fixedWidth = 500,
    fixedHeight = 500
  ) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = sourceImage;
      image.crossOrigin = "anonymous"; // Avoid CORS issues if needed

      image.onload = () => {
        // Create a temporary canvas for resizing
        const tempCanvas = document.createElement("canvas");
        tempCanvas.width = fixedWidth;
        tempCanvas.height = fixedHeight;
        const tempCtx = tempCanvas.getContext("2d");

        // Resize the image to fixed dimensions
        tempCtx.drawImage(image, 0, 0, fixedWidth, fixedHeight);

        // Now use the resized image for cropping
        const canvas = document.createElement("canvas");
        const scaleX = fixedWidth / image.width;
        const scaleY = fixedHeight / image.height;

        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
          tempCanvas, // Use the resized image
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );

        // Convert the cropped image to a blob
        canvas.toBlob(
          (blob) => {
            if (!blob) {
              console.error("Canvas is empty");
              reject(new Error("Canvas is empty"));
              return;
            }

            blob.name = "cropped.jpeg";
            resolve(blob);
          },
          "image/jpeg",
          1
        );
      };

      image.onerror = (err) => {
        reject(err);
      };
    });
  };

  const handleCropComplete = async () => {
    try {
      const croppedBlob = await getCroppedImg(selectedImage, crop);
      setCroppedImages((prev) => [...prev, { type: "new", blob: croppedBlob }]);
      setSelectedImage(null);
    } catch (e) {
      console.error("Error cropping image:", e);
    }
  };

  const handleRemoveImage = (index) => {
    setCroppedImages((prev) => {
      const updatedImages = [...prev];
      const removedImage = updatedImages.splice(index, 1)[0];

      if (removedImage.type === "existing") {
        setRemovedImageIds((prevIds) => [...prevIds, removedImage?.id]); // Add ID to removedImageIds
      }

      return updatedImages;
    });
  };

  const handleSlotsVisible = () => {
    updateSchedule(startDate, endDate);
    setIsModalVisible(true);
    fetchInstructorSlots(selectedInstructorId);
  };

  const handleSaveButtonClick = async () => {
    // debugger;
    if (validateFields()) {
      setIsLoading(true);

      try {
        const formData = new FormData();

        // Add basic fields
        formData.append("title", title);
        formData.append("description", editorContent);
        formData.append("categoryIds", selectedCategoryIds);
        formData.append("price", parseFloat(price));
        formData.append("currency", "USD");
        formData.append("usdPrice", parseFloat(usdPrice));
        formData.append("compareAtPrice", parseFloat(comparePrice));
        formData.append("compareUsdPrice", parseFloat(compareUsdPrice));

        formData.append("chargeTax", chargeTax);
        formData.append("joinType", selectedValue);
        formData.append("classType", selectedGroup);

        // formData.append(
        //   "hourlystartdate",
        //   new Date(startDate).toISOString().split("T")[0]
        // );
        // formData.append(
        //   "hourlyenddate",
        //   new Date(endDate).toISOString().split("T")[0]
        // );

        formData.append(
          "hourlystartdate",
          new Date(startDate).toLocaleDateString("en-CA")
        );
        formData.append(
          "hourlyenddate",
          new Date(endDate).toLocaleDateString("en-CA")
        );

        formData.append("bookingType", selectedOption);
        formData.append("status", "upcoming");
        formData.append("joiningLink", joiningLink);
        formData.append("classAddress", classAddress);
        formData.append("instructorId", selectedInstructorId);
        formData.append("totalAttendees", totalAttendence);
        formData.append("subscription", subscriptionPrice);
        formData.append("durationHour", duration.hours.split(" ")[0]);
        formData.append("durationMunite", duration.minutes.split(" ")[0]);
        formData.append("bufferHour", buffer.hours.split(" ")[0]);
        formData.append("bufferMunite", buffer.minutes.split(" ")[0]);
        formData.append("addReview", isChecked);

        const updatedSlotIds = slotIds.filter(
          (id) => !removedSlotIds.includes(id)
        );

        const allSlotIds = [
          ...(selectedSlotIds || []),
          ...(newlycreatedSlotIds || []),
        ];
        formData.append("slots", allSlotIds);

        croppedImages.forEach((image, index) => {
          if (image.type === "new") {
            formData.append(`imageUpload`, image.blob, `image_${index}.jpeg`);
          }
        });

        // console.log("FormData to save:", Array.from(formData.entries()));

        // API call
        const response = await postApi(ADD_NEW_CLASS, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        const { status, message } = response;
        if (status === 200) {
          successToast(message);
          setAddClassForm(false);
          fetchAllClasses();

          // Reset fields
          setTitle("");
          setEditorContent("");
          setPrice("");
          setComparePrice("");
          setCompareUsdPrice("");
          setUsdPrice("");
          setChargeTax(false);
          setSelectedValue("");
          setSelectedOption("");
          setJoiningLink("");
          setTotalAttendence("");
          setDuration({ hours: "0 hours", minutes: "0 minutes" });
          setBuffer({ hours: "0 hours", minutes: "0 minutes" });
          setIsChecked(false);
          setSchedule({});
          setEndDate(null);
          setStartDate(null);
        } else {
          errorToast(message);
        }
      } catch (error) {
        errorToast("An error occurred while saving the class.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const updatedSlotIds = slotIds.filter((id) => !removedSlotIds.includes(id));

    // console.log(updatedSlotIds, "updatedSlotIdsupdatedSlotIdsupdatedSlotIds");
  }, [removedSlotIds, slotIds]);

  const handleUpdateButtonClick = async () => {
    if (validateFields()) {
      setIsLoading(true);

      try {
        const formData = new FormData();

        // Add basic fields
        formData.append("classId", classEditedData[0].id);
        formData.append("title", title);
        formData.append("description", editorContent);
        formData.append("categoryIds", selectedCategoryIds);
        formData.append("price", parseFloat(price));
        formData.append("currency", "USD");
        formData.append("usdPrice", parseFloat(usdPrice));
        formData.append("compareAtPrice", parseFloat(comparePrice));
        formData.append("compareUsdPrice", parseFloat(compareUsdPrice));

        formData.append("chargeTax", chargeTax);
        formData.append("joinType", selectedValue);
        formData.append("classType", selectedGroup);
        // formData.append(
        //   "hourlystartdate",
        //   new Date(startDate).toISOString().split("T")[0]
        // );
        // formData.append(
        //   "hourlyenddate",
        //   new Date(endDate).toISOString().split("T")[0]
        // );
        formData.append(
          "hourlystartdate",
          new Date(startDate).toLocaleDateString("en-CA")
        );
        formData.append(
          "hourlyenddate",
          new Date(endDate).toLocaleDateString("en-CA")
        );
        formData.append("bookingType", selectedOption);
        formData.append("status", "UPCOMING");
        formData.append("joiningLink", joiningLink);
        formData.append("classAddress", classAddress);
        formData.append("instructorId", selectedInstructorId);
        formData.append("totalAttendees", totalAttendence);
        formData.append("subscription", subscriptionPrice);
        formData.append("durationHour", duration.hours.split(" ")[0]);
        formData.append("durationMinute", duration.minutes.split(" ")[0]);
        formData.append("bufferHour", buffer.hours.split(" ")[0]);
        formData.append("bufferMinute", buffer.minutes.split(" ")[0]);
        formData.append("addReview", isChecked);
        formData.append("removeSlotIds", newRemovedSlotIds);

        // Map slots
        const updatedSlotIds = slotIds.filter(
          (id) => !removedSlotIds.includes(id)
        );

        const commonIds = assignedSlotId?.filter((id) =>
          newRemovedSlotIds.includes(id)
        );

        const updatedAssignedSlotId = assignedSlotId?.filter(
          (id) => !commonIds.includes(id)
        );

        const allSlotIds = [
          ...(updatedAssignedSlotId || []),
          ...(selectedSlotIds || []),
          ...(newlycreatedSlotIds || []),
        ];

        formData.append("slots", allSlotIds);

        // Append new images
        croppedImages.forEach((image, index) => {
          if (image.type === "new") {
            formData.append(`imageUpload`, image.blob, `image_${index}.jpeg`);
          }
        });
        const previousImageIds = [...new Set(removedImageIds)].join(",");

        if (previousImageIds) {
          formData.append("previousImageIds", previousImageIds);
        }

        // console.log("FormData to update:", Array.from(formData.entries()));

        // API call
        const response = await putApi(UPDATE_CLASS, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        const { status, message } = response;

        if (status === 200) {
          successToast(message);
          setAddClassForm(false);
          fetchAllClasses();

          // Reset fields
          setTitle("");
          setEditorContent("");
          setPrice("");
          setComparePrice("");
          setUsdPrice("");
          setChargeTax(false);
          setSelectedValue("");
          setSelectedOption("");
          setJoiningLink("");
          setTotalAttendence("");
          setDuration({ hours: "0 hours", minutes: "0 minutes" });
          setBuffer({ hours: "0 hours", minutes: "0 minutes" });
          setIsChecked(false);
          setSchedule({});
          setEndDate(null);
          setStartDate(null);
        } else {
          errorToast(message);
        }
      } catch (error) {
        errorToast("An error occurred while updating the class.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <div className="addcls">
        <div className="addcls_cont">
          <div className="addcls_cont_header">
            <h2
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
              onClick={hanldeBack}
            >
              <BackArrowIcon />
              Class Management
            </h2>
            {classEditedData ? (
              <Buttons variant="primary" onClick={handleUpdateButtonClick}>
                Update
              </Buttons>
            ) : (
              <Buttons
                variant="primary"
                onClick={handleSaveButtonClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </Buttons>
            )}
          </div>

          <Card style={{ marginBottom: "10px" }} className="addcls_cont_form">
            <div className="form-group">
              <label>Title*</label>
              <FormInputs
                type="text"
                placeholder="Class Title"
                className="title-input"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    title: e.target.value ? "" : prevErrors.title,
                  }));
                }}
              />
              {errors.title && <span className="error">{errors.title}</span>}
            </div>

            <div className="form-group">
              <label>Description*</label>
              <ReactQuill
                theme="snow"
                value={editorContent}
                onChange={(value) => {
                  setEditorContent(value);
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    editorContent: value.trim() ? "" : prevErrors.editorContent,
                  }));
                }}
                placeholder="Write your class description here..."
              />
              {errors.editorContent && (
                <p className="error">{errors.editorContent}</p>
              )}
            </div>

            <div className="form-group">
              <label>Media</label>
              <div className="media-section">
                <div className="media-preview">
                  {croppedImages.map((image, index) => (
                    <div key={index} className="image-preview-cont">
                      <div className="uploaded-image">
                        {image.type === "existing" ? (
                          <img src={image?.url} alt="Preview" />
                        ) : (
                          <img
                            src={URL.createObjectURL(image.blob)}
                            alt="Preview"
                          />
                        )}
                      </div>
                      <button
                        className="remove-image"
                        onClick={() => handleRemoveImage(index)}
                        aria-label="Remove Image"
                      >
                        {/* <i className="fas fa-times"></i>  */}X
                      </button>
                    </div>
                  ))}
                </div>
                <div className="media-upload">
                  <input
                    ref={fileInputRef}
                    type="file"
                    accept="image/*"
                    onChange={handleFileSelect}
                    style={{ display: "none" }}
                  />
                  <button
                    className="upload-button"
                    onClick={() => fileInputRef.current?.click()}
                    // disabled={croppedImages.length >= 4}
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
            {selectedImage && (
              <div
                style={{
                  maxWidth: "100%", // Ensure it doesn't exceed the container width
                  maxHeight: "100vh", // Set a max height for scrolling
                  overflowY: "auto", // Enable vertical scrolling
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="image-cropper-modal"
              >
                <ReactCrop
                  crop={crop}
                  onChange={(newCrop) => setCrop(newCrop)}
                  aspect={1} // Ensures square crop
                  minWidth={100} // Prevents tiny crops
                  minHeight={100}
                >
                  <img
                    style={{
                      maxWidth: "100%", // Limit image width
                      maxHeight: "100%", // Limit image height
                      objectFit: "contain", // Avoid stretching
                      overscrollBehavior: "scroll",
                    }}
                    src={selectedImage}
                    alt="Crop preview"
                  />
                </ReactCrop>
                <div className="cropper-actions">
                  <button onClick={handleCropComplete} className="save-button">
                    Save
                  </button>
                  <button
                    onClick={() => setSelectedImage(null)}
                    className="cancel-button"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}

            <div className="form-group">
              <label style={{ display: "block", marginBottom: "8px" }}>
                Select category
              </label>
              <div
                className="category-section"
                style={{ display: "flex", gap: "16px", alignItems: "center" }}
              >
                <Select
                  mode="multiple"
                  placeholder="Select category"
                  name="category"
                  size="large"
                  value={selectedCategoryIds}
                  onChange={(values) => {
                    setSelectedCategoryIds(values);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      selectedCategoryIds:
                        values.length > 0 ? "" : prevErrors.selectedCategoryIds,
                    }));
                  }}
                >
                  {selectedCategory?.map((category) => (
                    <Option key={category.id} value={category.id}>
                      {category.name}
                    </Option>
                  ))}
                </Select>
              </div>
              {errors.selectedCategoryIds && (
                <p className="error">{errors.selectedCategoryIds}</p>
              )}
            </div>
          </Card>
          <Card
            style={{ marginBottom: "10px" }}
            className="addcls_cont_Jdetails"
          >
            <div className="heading">
              <div className="heading_h1">Joining Details</div>
              <div className="heading_cont">
                Please provide the necessary details to complete the joining
                process. Select the appropriate mode of joining, assign a staff
                member, and specify the class type.
              </div>
            </div>
            <div className="join">
              <label style={{ display: "block", marginBottom: "8px" }}>
                Join Type
              </label>
              <Select
                placeholder="Select mode"
                name="mode"
                size="large"
                style={{ width: "100%" }}
                value={selectedValue}
                onChange={(value) => {
                  handleSelectChange(value);
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    selectedValue: value ? "" : prevErrors.selectedValue,
                  }));
                }}
              >
                {options?.map((option) => (
                  <Option key={option.id} value={option.id}>
                    {option.name}
                  </Option>
                ))}
              </Select>
              {errors.selectedValue && (
                <p className="error">{errors.selectedValue}</p>
              )}
            </div>

            {selectedValue === "online" && (
              <div className="join">
                <label style={{ display: "block", marginBottom: "8px" }}>
                  Joining Link
                </label>
                <FormInputs
                  type="text"
                  value={joiningLink}
                  onChange={(e) => {
                    handleJoiningLinkChange(e);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      joiningLink: e.target.value ? "" : prevErrors.joiningLink,
                    }));
                  }}
                  placeholder="Enter joining link"
                />
                {errors.joiningLink && (
                  <p className="error">{errors.joiningLink}</p>
                )}
              </div>
            )}

            {selectedValue === "offline" && (
              <div className="join">
                <label style={{ display: "block", marginBottom: "8px" }}>
                  Class Address
                </label>
                <FormInputs
                  type="text"
                  value={classAddress}
                  onChange={(e) => {
                    handleClassAddressChange(e);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      classAddress: e.target.value
                        ? ""
                        : prevErrors.classAddress,
                    }));
                  }}
                  placeholder="Enter class address"
                />
                {errors.classAddress && (
                  <p className="error">{errors.classAddress}</p>
                )}
              </div>
            )}

            <div className="join">
              <label style={{ display: "block", marginBottom: "8px" }}>
                Assign Staff*
              </label>
              <Select
                placeholder="Assign Staff"
                name="instructor"
                size="large"
                style={{ width: "100%" }}
                value={selectedInstructorId}
                onChange={(value) => {
                  handleInstructorChange(value);
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    assignStaff: value ? "" : prevErrors.assignStaff,
                  }));
                }}
              >
                {selectedInstructor?.map((category) => (
                  <Option key={category.id} value={category.id}>
                    {category.name}
                  </Option>
                ))}
              </Select>
              {errors.assignStaff && (
                <p className="error">{errors.assignStaff}</p>
              )}
            </div>

            <div className="join">
              <label style={{ display: "block", marginBottom: "8px" }}>
                Class Type*
              </label>
              <Select
                placeholder="Select Class Type"
                name="mode"
                size="large"
                style={{ width: "100%" }}
                value={selectedGroup}
                onChange={(value) => {
                  handleClassTypeChange(value);
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    selectedGroup: value ? "" : prevErrors.selectedGroup,
                  }));
                }}
              >
                {options2?.map((option) => (
                  <Option key={option.id} value={option.id}>
                    {option.name}
                  </Option>
                ))}
              </Select>
              {errors.selectedGroup && (
                <p className="error">{errors.selectedGroup}</p>
              )}
            </div>
          </Card>

          <Card
            style={{ marginBottom: "10px" }}
            className="addcls_cont_booking"
          >
            <p className="heading">Booking Type</p>
            <p className="content">
              Select your preferred booking type, You can choose between an
              Hourly or Monthly booking plan.
            </p>
            <div className="addcls_cont_booking_radio">
              <div className="radioone">
                <input
                  type="radio"
                  name="bookingOption"
                  value="hourly"
                  checked={selectedOption === "hourly"}
                  onChange={() => {
                    handleRadioChange("hourly");
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      bookingOption: "", // Clear error when a selection is made
                    }));
                  }}
                />
                <p>Hourly</p>
              </div>
              <div className="radioone">
                <input
                  type="radio"
                  name="bookingOption"
                  value="monthly"
                  checked={selectedOption === "monthly"}
                  onChange={() => {
                    handleRadioChange("monthly");
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      bookingOption: "", // Clear error when a selection is made
                    }));
                  }}
                />
                <p>Monthly</p>
              </div>
            </div>
            {errors.bookingOption && (
              <p className="error">{errors.bookingOption}</p>
            )}

            <div className="addcls_cont_booking_attendence">
              {selectedGroup !== "one-on-one" && (
                <div className="one">
                  <FormInputs
                    type="number"
                    title="Total number of attendees*"
                    value={totalAttendence}
                    onChange={(e) => {
                      setTotalAttendence(e.target.value);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        totalAttendence: e.target.value
                          ? ""
                          : prevErrors.totalAttendence,
                      }));
                    }}
                    placeholder="00"
                  />
                  {errors.totalAttendence && (
                    <p className="error">{errors.totalAttendence}</p>
                  )}
                </div>
              )}
            </div>
          </Card>
          <Card style={{ marginBottom: "10px" }} className="addcls_cont_sec">
            <div className="addcls_cont_sec_main">
              <h3 className="addcls_cont_sec_main_title">Pricing</h3>
              <div
                className="addcls_cont_sec_main_title_inputs"
                style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}
              >
                <div className="addcls_cont_sec_main_title_inputs_group">
                  <div className="price-field">
                    <FormInputs
                      type="number"
                      title={
                        selectedOption === "hourly"
                          ? "Class Price*"
                          : "Subscription Price*"
                      }
                      value={price}
                      onChange={(e) => {
                        setPrice(e.target.value);
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          price: e.target.value ? "" : prevErrors.price,
                        }));
                      }}
                      placeholder="00.00"
                    />
                    {errors.price && <p className="error">{errors.price}</p>}
                  </div>
                </div>

                <div className="addcls_cont_sec_main_title_inputs_group">
                  <div className="price-field">
                    <FormInputs
                      type="number"
                      value={comparePrice}
                      title={
                        selectedOption === "hourly"
                          ? "Compare-at Class Price*"
                          : "Subscription Compare-at Price*"
                      }
                      onChange={(e) => {
                        setComparePrice(e.target.value);
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          comparePrice: e.target.value
                            ? ""
                            : prevErrors.comparePrice,
                        }));
                      }}
                      placeholder="00.00"
                    />
                    {errors.comparePrice && (
                      <p className="error">{errors.comparePrice}</p>
                    )}
                  </div>
                </div>

                <div className="addcls_cont_sec_main_title_inputs_group">
                  <div className="price-field">
                    <FormInputs
                      type="number"
                      value={usdPrice}
                      title={
                        selectedOption === "hourly"
                          ? "USD Class Price*"
                          : "Subscription USD Price*"
                      }
                      onChange={(e) => {
                        setUsdPrice(e.target.value);
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          usdPrice: e.target.value ? "" : prevErrors.usdPrice,
                        }));
                      }}
                      placeholder="$ 00.00"
                    />
                    {errors.usdPrice && (
                      <p className="error">{errors.usdPrice}</p>
                    )}
                  </div>
                </div>

                <div className="addcls_cont_sec_main_title_inputs_group">
                  <div className="price-field">
                    <FormInputs
                      type="number"
                      value={compareUsdPrice}
                      title={
                        selectedOption === "hourly"
                          ? "Compare-at USD Class Price*"
                          : "Subscription Compare-at USD Price*"
                      }
                      onChange={(e) => {
                        setCompareUsdPrice(e.target.value);
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          compareUsdPrice: e.target.value
                            ? ""
                            : prevErrors.compareUsdPrice,
                        }));
                      }}
                      placeholder="$ 00.00"
                    />
                    {errors.compareUsdPrice && (
                      <p className="error">{errors.compareUsdPrice}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="addcls_cont_sec_main_option">
                <label className="addcls_cont_sec_main_option_cont">
                  <input
                    type="checkbox"
                    checked={chargeTax}
                    onChange={(e) => setChargeTax(e.target.checked)}
                  />
                  <span className="checkmark"></span>
                  Charge tax on this product
                </label>
              </div>
            </div>
          </Card>

          <Card
            style={{ marginBottom: "10px" }}
            className="addcls_cont_timeslots"
          >
            <div className="heading">
              <div className="heading_main">Time Slot settings</div>
              <div className="heading_content">
                Configure the duration of each session and allocate buffer time
                to ensure a smooth schedule.
              </div>
            </div>
            <div style={{ padding: 20 }}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Title level={5}>Duration</Title>
                  {renderSelect(
                    duration.hours,
                    (value) => handleDurationChange("hours", value),
                    DurationHoursOptions
                  )}
                  <span style={{ marginRight: "12px" }} />
                  {renderSelect(
                    duration.minutes,
                    (value) => handleDurationChange("minutes", value),
                    minutesOptions
                  )}
                </Col>
              </Row>

              <Divider />

              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Title level={5}>Buffer time</Title>
                  <div style={{ display: "flex", gap: "12px" }}>
                    {renderSelect(
                      buffer.hours,
                      (value) => handleBufferChange("hours", value),
                      hoursOptions
                    )}
                    {renderSelect(
                      buffer.minutes,
                      (value) => handleBufferChange("minutes", value),
                      minutesOptions
                    )}
                  </div>
                </Col>

                <Col span={12}>
                  <Title level={5}>Working days and hrs</Title>
                  <Button onClick={handleSlotsVisible} type="default" block>
                    Set Working Days and Hrs
                  </Button>
                </Col>
              </Row>
            </div>
          </Card>
          <Card style={{ marginBottom: "10px", borderRadius: "8px" }}>
            <Title level={5} strong>
              Others
            </Title>
            <Paragraph style={{ marginBottom: "12px" }}>
              Enable in-class review sessions to assess progress and provide
              feedback.
            </Paragraph>
            <Checkbox checked={isChecked} onChange={handleReviewChange}>
              Add Review In Class
            </Checkbox>
          </Card>
        </div>
      </div>

      <Modal
        title="Weekly Schedule"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleSave}
        okText="Submit"
        width={800}
      >
        {selectedOption === "hourly" && (
          <div style={{ marginBottom: "10px" }}>
            <label>Select Date Range:</label>
            <RangePicker
              value={startDate && endDate ? [startDate, endDate] : null}
              onChange={handleDateChange}
              disabledDate={(current) =>
                current && current < new Date().setHours(0, 0, 0, 0)
              }
            />
            {dateError && (
              <p style={{ color: "red", marginTop: "5px" }}>{dateError}</p>
            )}
          </div>
        )}
        <Tabs defaultActiveKey="1">
          {/* Tab 1: Assigned Slots */}
          {classEditedData?.length > 0 && (
            <TabPane tab="Assigned Slots" key="1">
              <p>Assigned slots of the class:</p>
              <div className="instform_contentModal_data">
                {assignedSlots && Object.keys(assignedSlots).length > 0 ? (
                  Object.entries(assignedSlots).map(([day, slots]) => (
                    <div key={day} className="schedule-day">
                      <h4 className="schedule-day-title">{day}</h4>
                      {slots?.map((slot, index) => (
                        <div
                          key={index}
                          className="schedule-slot "
                          style={{ marginTop: "10px" }}
                        >
                          <TimePicker
                            value={
                              slot.startTime
                                ? dayjs(slot.startTime, "HH:mm")
                                : null
                            }
                            placeholder="Start Time"
                            format="HH:mm"
                            disabled
                            allowClear={false}
                          />
                          <TimePicker
                            value={
                              slot.endTime ? dayjs(slot.endTime, "HH:mm") : null
                            }
                            placeholder="End Time"
                            format="HH:mm"
                            disabled
                            allowClear={false}
                          />
                          <Button
                            type="primary"
                            icon={<DeleteOutlined />}
                            onClick={() => handleRemoveSlot(day, slot.id)}
                            style={{
                              backgroundColor: "#ff4d4f",
                              borderRadius: "10%",
                              width: "30px",
                              height: "30px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              border: "none",
                              color: "white",
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  ))
                ) : (
                  <p>No assigned slots available.</p>
                )}
              </div>
            </TabPane>
          )}
          {/* Tab 2: Available Slots of Selected Instructor */}
          <TabPane tab="Available Slots (Instructor)" key="2">
            <p>Available slots of the selected instructor:</p>
            <div className="instform_contentModal_data">
              {Object.keys(schedule).map((day) => (
                <div key={day} className="schedule-day">
                  <h4 className="schedule-day-title">{day}</h4>
                  {schedule[day].slots.map((slot, index) => (
                    <div
                      key={index}
                      className="schedule-slot"
                      style={{ marginTop: "10px" }}
                    >
                      <TimePicker
                        value={slot.start ? dayjs(slot.start, "HH:mm") : null}
                        placeholder="Start Time"
                        format="HH:mm"
                        disabled
                        allowClear={false}
                      />
                      <TimePicker
                        value={slot.end ? dayjs(slot.end, "HH:mm") : null}
                        placeholder="End Time"
                        format="HH:mm"
                        disabled
                        allowClear={false}
                      />
                      <Button
                        type={isSlotSelected(slot.id) ? "default" : "primary"}
                        onClick={() => handleToggleSlot(day, slot)}
                      >
                        {isSlotSelected(slot.id) ? "Unselect" : "Select"}
                      </Button>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </TabPane>
          ;{/* Tab 3: Add New Slots */}
          <TabPane tab="Add New Slots" key="3">
            <p>Add new slots to the class:</p>
            <div className="instform_contentModal_data">
              {Object.keys(schedule).map((day) => (
                <div key={day} className="schedule-day">
                  <div className="schedule-header">
                    <Switch
                      checked={schedule[day].isActive}
                      onChange={() => handleToggle(day)}
                    />
                    <h4 className="schedule-day-title">{day}</h4>
                  </div>
                  {schedule[day].isActive ? (
                    <>
                      <div
                        className="schedule-slots"
                        style={{ marginTop: "10px" }}
                      >
                        {schedule[day].slots.map((slot, index) => (
                          <div key={index} className="schedule-slot">
                            <TimePicker
                              value={
                                slot.start ? dayjs(slot.start, "HH:mm") : null
                              }
                              placeholder="Start Time"
                              format="HH:mm"
                              onChange={(time) =>
                                handleTimeChange(day, index, time, "start")
                              }
                              allowClear={false}
                            />
                            <TimePicker
                              value={slot.end ? dayjs(slot.end, "HH:mm") : null}
                              placeholder="End Time"
                              format="HH:mm"
                              onChange={(time) =>
                                handleTimeChange(day, index, time, "end")
                              }
                              allowClear={false}
                            />
                            {index > 0 && (
                              <Button
                                type="danger"
                                icon={<DeleteOutlined />}
                                onClick={() => removeTimeSlot(day, index)}
                                style={{
                                  backgroundColor: "#ff4d4f",
                                  borderRadius: "10%",
                                  width: "30px",
                                  height: "30px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  border: "none",
                                  color: "white",
                                }}
                              />
                            )}
                          </div>
                        ))}
                      </div>
                      <Button
                        type="dashed"
                        icon={<PlusOutlined />}
                        onClick={() => addTimeSlot(day)}
                      >
                        Add Slot
                      </Button>
                    </>
                  ) : (
                    <p>Unavailable</p>
                  )}
                </div>
              ))}
            </div>
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
};

export default ClassManagementForm;
