import React from "react";
import { TimePicker } from "antd";
import dayjs from "dayjs";

const format = "HH:mm";

const TimeSelector = () => (
  <TimePicker
    defaultValue={dayjs("00:00", format)}
    format={format}
    allowClear={false}
    suffixIcon={false}
    className="w-[60px]"
  />
);

export default TimeSelector;