import React, { useEffect, useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import { ArrowLeftOutlined, LeftCircleOutlined } from "@ant-design/icons";
import "react-image-crop/dist/ReactCrop.css";
import "./CategoryManagementForm.scss";
import Buttons from "../../../Components/Buttons/Buttons";
import FormInputs from "../../../Components/FormInputs/FormInputs";
import { Card } from "antd";
import TextArea from "antd/es/input/TextArea";
import Loader from "../../../Components/Loader/Loader";
import { postApi, putApi } from "../../../Utlis/apiService";
import { ADD_NEW_CATEGORY, UPDATE_CATEGORY } from "../../../Utlis/apiPath";
import { errorToast, successToast } from "../../../Services/ToastHelper";
import { BackArrowIcon } from "../../../Components/Icons/BackArrowIcon";

const CategoryManagementForm = ({
  setAddCategoryForm,
  editData,
  fetchAllCategorys,
}) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [croppedImages, setCroppedImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const fileInputRef = useRef(null);
  const [removedImageIds, setRemovedImageIds] = useState([]);
  const [errors, setErrors] = useState({});
  const [crop, setCrop] = useState({
    unit: "px", // Use pixels
    width: 200, // Set fixed width
    height: 200, // Set fixed height
    x: 50,
    y: 50,
  });

  useEffect(() => {
    if (selectedImage) {
      setCrop({
        unit: "px",
        width: 300,
        height: 300,
        x: 50,
        y: 50,
      });
    }
  }, [selectedImage]);

  useEffect(() => {
    if (editData) {
      setTitle(editData.name || "");
      setDescription(editData.description || "");

      // Map existing images into croppedImages array with a flag
      setCroppedImages(
        (editData.images || []).map((imageUrl) => ({
          type: "existing",
          url: imageUrl,
        }))
      );
    } else {
      setTitle("");
      setDescription("");
      setCroppedImages([]);
    }
  }, [editData]);

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
        fileInputRef.current.value = ""; // Reset file input to allow re-selection of same file
      };
      reader.readAsDataURL(file);
    }
  };

  const getCroppedImg = (sourceImage, crop) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = sourceImage;
      image.crossOrigin = "anonymous"; // Avoid CORS issues if needed

      image.onload = () => {
        const canvas = document.createElement("canvas");
        const scaleX = image.width / image.naturalWidth;
        const scaleY = image.height / image.naturalHeight;

        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );

        canvas.toBlob(
          (blob) => {
            if (!blob) {
              reject(new Error("Canvas is empty"));
              return;
            }
            resolve(blob);
          },
          "image/jpeg",
          1
        );
      };

      image.onerror = (err) => reject(err);
    });
  };

  const handleCropComplete = async () => {
    try {
      const croppedBlob = await getCroppedImg(selectedImage, crop);

      setCroppedImages((prev) => [...prev, { type: "new", blob: croppedBlob }]);
      setSelectedImage(null);
    } catch (e) {
      console.error("Error cropping image:", e);
    }
  };

  const handleRemoveImage = (index) => {
    setCroppedImages((prev) => {
      const updatedImages = [...prev];
      const removedImage = updatedImages.splice(index, 1)[0];

      if (removedImage.type === "existing") {
        setRemovedImageIds((prevIds) => [...prevIds, removedImage.url.id]); // Add ID to removedImageIds
      }

      return updatedImages;
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!title) newErrors.title = "Title is required";
    if (!description) newErrors.description = "Description is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validateForm()) return;

    if (
      croppedImages.length === 0 &&
      (!editData?.images || editData.images.length === 0)
    ) {
      errorToast("Please add at least one image before saving.");
      return;
    }
    setIsLoading(true);

    const formData = new FormData();
    formData.append("name", title);
    formData.append("description", description);

    const isUpdate = Boolean(editData?.id);

    if (isUpdate) {
      formData.append("id", editData.id);
      const previousImageIds = [...new Set(removedImageIds)].join(",");
      if (previousImageIds) {
        formData.append("previousImageIds", previousImageIds);
      }
    }

    croppedImages.forEach((image, index) => {
      if (image.type === "new") {
        formData.append(`images`, image.blob, `image_${index}.jpeg`);
      }
    });

    try {
      const apiUrl = isUpdate ? UPDATE_CATEGORY : ADD_NEW_CATEGORY;
      const response = await (isUpdate
        ? putApi(apiUrl, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
        : postApi(apiUrl, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          }));

      const { status, message } = response;

      if (status === 200) {
        successToast(message);
        setAddCategoryForm(false);
        fetchAllCategorys();
      } else {
        errorToast(message);
      }
    } catch (error) {
      errorToast("An error occurred while saving the category.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="addcls">
      {isLoading && <Loader />}
      <div className="addcls_cont">
        <div className="addcls_cont_header">
          <h2>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setAddCategoryForm(false)}
            >
              <BackArrowIcon />
            </div>

            {editData ? "Edit Category" : "Add Category"}
          </h2>
          <Buttons variant="primary" onClick={handleSave}>
            {editData ? "Update" : "Save"}
          </Buttons>
        </div>

        <Card style={{ marginBottom: "10px" }} className="addcls_cont_form">
          <div className="form-group">
            <FormInputs
              title="Category Title"
              type="text"
              placeholder="Enter category title"
              className="title-input"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
                if (errors.title) {
                  setErrors((prev) => ({ ...prev, title: "" }));
                }
              }}
            />
            {errors.title && <span className="error-text">{errors.title}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="description">Category Description</label>
            <TextArea
              id="description"
              placeholder="Enter category description here"
              rows={4}
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
                if (errors.description) {
                  setErrors((prev) => ({ ...prev, description: "" }));
                }
              }}
              style={{
                fontWeight: 600,
                color: "#717171",
                fontSize: "16px",
              }}
            />
            {errors.description && (
              <span className="error-text">{errors.description}</span>
            )}
          </div>
          <div className="form-group">
            <label>Media</label>
            <div className="media-section">
              <div className="media-preview">
                {croppedImages.map((image, index) => (
                  <div key={index} className="image-preview-cont">
                    <div className="uploaded-image">
                      {image.type === "existing" ? (
                        <img src={image?.url?.url} alt="Preview" />
                      ) : (
                        <img
                          src={URL.createObjectURL(image.blob)}
                          alt="Preview"
                        />
                      )}
                    </div>
                    <button
                      className="remove-image"
                      onClick={() => handleRemoveImage(index)}
                      aria-label="Remove Image"
                    >
                      {/* <i className="fas fa-times"></i>  */}X
                    </button>
                  </div>
                ))}
              </div>
              <div className="media-upload">
                {croppedImages.length < 3 && (
                  <>
                    <input
                      ref={fileInputRef}
                      type="file"
                      accept="image/*"
                      onChange={handleFileSelect}
                      style={{ display: "none" }}
                    />
                    <button
                      className="upload-button"
                      onClick={() => fileInputRef.current?.click()}
                    >
                      +
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
          {selectedImage && (
            <div
              style={{
                maxWidth: "100%", // Ensure it doesn't exceed the container width
                maxHeight: "100vh", // Set a max height for scrolling
                overflowY: "auto", // Enable vertical scrolling
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="image-cropper-modal"
            >
              <ReactCrop
                crop={crop}
                onChange={(newCrop) => setCrop(newCrop)}
                aspect={1} // Ensures square crop
                minWidth={100} // Prevents tiny crops
                minHeight={100}
              >
                <img
                  style={{
                    maxWidth: "100%", // Limit image width
                    maxHeight: "100%", // Limit image height
                    objectFit: "contain", // Avoid stretching
                    overscrollBehavior: "scroll",
                  }}
                  src={selectedImage}
                  alt="Crop preview"
                />
              </ReactCrop>
              <div className="cropper-actions">
                <button onClick={handleCropComplete} className="save-button">
                  Save
                </button>
                <button
                  onClick={() => setSelectedImage(null)}
                  className="cancel-button"
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </Card>
      </div>
    </div>
  );
};

export default CategoryManagementForm;
