import { Select } from "antd";

const { Option } = Select;

const MultiSelectInput = ({ title, options, value, onChange, placeholder }) => {
  return (
    <div>
      <label className="font-semibold">{title}</label>
      <Select
        mode="tags" // Allows both selecting & entering new values
        style={{ width: "100%", marginTop: "8px" }}
        placeholder={placeholder}
        value={value || []} // Ensure it's always an array
        onChange={onChange}
        tokenSeparators={[","]} // Allows comma-separated input
        allowClear // Adds clear button
      >
        {options.map((option) => (
          <Option key={option} value={option}>
            {option}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default MultiSelectInput;
