import React, { useEffect, useState } from "react";
import "./Reschedule.scss";
import {
  SearchOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from "@ant-design/icons";
import {
  Table,
  Button,
  Input,
  Modal,
  Card,
  Typography,
  Divider,
  Row,
  Col,
} from "antd";

import Buttons from "../../Components/Buttons/Buttons";
import { getApi, postApi } from "../../Utlis/apiService";
import {
  APPROVE_INSTRUCTOR,
  APPROVED_RESCHEDULE_CLASS,
  GET_ALL_INSTRUCTORS,
  GET_ALL_RESCHEDULELIST,
  GET_INSTRUCTOR_SLOTS,
} from "../../Utlis/apiPath";
import { EyeOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import Loader from "../../Components/Loader/Loader";
import TextArea from "antd/es/input/TextArea";
import { successToast } from "../../Services/ToastHelper";
import { useNavigate } from "react-router-dom";
import moment from "moment";
const { Title, Text } = Typography;

const Reschedule = () => {
  const [activeTab, setActiveTab] = useState("2");
  const [showAddInstructorForm, setShowAddInstructorForm] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [isApproved, setIsApproved] = useState(false);
  const [isDenied, setIsDenied] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [denialMessage, setDenialMessage] = useState("");
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [updateData, setUpdateData] = useState();
  const Navigate = useNavigate();
  const [isAscending, setIsAscending] = useState("");
  const [pagination, setPagination] = useState({
    current: 1, // Ensure it starts from 1
    pageSize: 10,
    total: 0,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReschedule, setSelectedReschedule] = useState(null);

  const getPageSizeOptions = () => {
    return totalRecords <= 50 ? ["10", "20", "50"] : ["15", "25", "50", "100"];
  };

  const handleAddNewInstructor = () => {
    setShowAddInstructorForm(true);
  };

  const handleCloseForm = () => {
    setShowAddInstructorForm(false);
  };
  const fetchTableData = async (page = 1, pageSize = 10, searchValue = "") => {
    setIsLoading(true);
    try {
      const pageIndex1 = page - 1; // Assuming API uses 0-based indexing
      const search1 = searchValue !== undefined ? searchValue : search;
      const response = await getApi(GET_ALL_RESCHEDULELIST, {
        params: {
          pageSize,
          search: search1,
          pageIndex: pageIndex1,
          isApproved,
          isAscending,
        },
      });

      if (response.status === 200) {
        setTableData(response.data?.classes || []);
        setTotalRecords(response.data?.totalRecords);
      } else if (response.status === 404) {
        setTableData([]);
        setTotalRecords(0);
        console.warn("No data found for the given criteria.");
      }
    } catch (error) {
      setTableData([]);
      console.error("Error fetching table data:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInstructorAccept = async (record, isApproved) => {
    setIsLoading(true);
    try {
      const { status } = await postApi(APPROVED_RESCHEDULE_CLASS, {
        rescheduleId: record.rescheduleId,
      });

      if (status === 200) {
        fetchTableData(1, 10);
      }
    } catch (error) {
      console.error("Error updating instructor status:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const showDenialModal = (record) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setDenialMessage("");
  };

  const handleModalSubmit = async () => {
    if (!denialMessage.trim()) {
      alert("Please enter a denial message.");
      return;
    }

    setIsModalVisible(false);
    setIsLoading(true);
    try {
      const { status, message } = await postApi(APPROVE_INSTRUCTOR, {
        instructorId: selectedRecord.instructorId,
        isApproved: false,
        isDenied: true,
        message: denialMessage,
      });

      if (status === 200) {
        successToast(message);
        fetchTableData(1, 10);
        handleModalClose();
      }
    } catch (error) {
      console.error("Error denying instructor:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setTableData([]);
    fetchTableData(1, 10);
  }, [isAscending, isApproved]);

  const handleSorting = () => {
    setIsAscending((prevState) => (prevState === "" ? false : !prevState));
  };

  const handleSearch = debounce((value) => {
    setSearchTerm(value);
  }, 300);

  const onSearchChange = (e) => {
    handleSearch(e.target.value);
  };

  const debouncedFetchClasses = debounce((searchValue) => {
    setSearch(searchValue);
    const page = 1;
    setPageIndex(1);
    fetchTableData(page, pageSize, searchValue);
  }, 500);

  const handleTableChange = (pagination) => {
    // setTableData();
    const { current, pageSize } = pagination;
    setPageIndex(current);
    setPageSize(pageSize);
    fetchTableData(current, pageSize);
  };

  const handleTabChange = (tabKey) => {
    setTableData([]);
    setPageIndex(1);
    setActiveTab(tabKey);

    if (tabKey === "2") {
      setIsApproved(false);
    } else if (tabKey === "3") {
      setIsApproved(true);
    }
    // setTimeout(() => {
    //   fetchTableData(1, pageSize);
    // }, 0);
  };

  const showRescheduleDetails = (record) => {
    setSelectedReschedule(record);
    setIsModalOpen(true);
  };

  const handleManageInstructor = async (record) => {
    Navigate(`/manage-instructor/${record.instructorId}`);
  };

  const instructorListColumns = [
    {
      title: "SL No",
      key: "slNo",
      render: (text, record, index) => (pageIndex - 1) * pageSize + index + 1,
    },
    { title: "Instructor", dataIndex: "instructor", key: "instructor" },
    { title: "Class Name", dataIndex: "name", key: "name" },
    {
      title: "Previous Slot Date",
      dataIndex: "previousSlotDate",
      key: "previousSlotDate",
    },
    {
      title: "Reschedule Date",
      dataIndex: "rescheduleDate",
      key: "rescheduleDate",
    },
    {
      title: "New Start Time",
      dataIndex: "newSlotStartTime",
      key: "newSlotStartTime",
    },
    {
      title: "New End Time",
      dataIndex: "newSlotEndTime",
      key: "newSlotEndTime",
    },
  ];

  const RescheduleRequestList = [
    {
      title: "SL No",
      key: "slNo",
      render: (text, record, index) => (pageIndex - 1) * pageSize + index + 1,
    },
    { title: "Instructor", dataIndex: "instructor", key: "instructor" },
    { title: "Class Name", dataIndex: "name", key: "name" },
    {
      title: "Previous Slot Date",
      dataIndex: "previousSlotDate",
      key: "previousSlotDate",
      render: (date) => moment(date).format("YYYY-MM-DD"),
    },
    {
      title: "Reschedule Date",
      dataIndex: "rescheduleDate",
      key: "rescheduleDate",
      render: (date) => moment(date).format("YYYY-MM-DD"),
    },
    {
      title: "New Start Time",
      dataIndex: "newSlotStartTime",
      key: "newSlotStartTime",
    },
    {
      title: "New End Time",
      dataIndex: "newSlotEndTime",
      key: "newSlotEndTime",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <>
          <Button
            size="small"
            type="primary"
            style={{ marginRight: "8px" }}
            onClick={() => handleInstructorAccept(record, true)}
          >
            Accept
          </Button>
          <Button
            size="small"
            type="danger"
            onClick={() => showDenialModal(record)}
          >
            Deny
          </Button>
          <div
            style={{
              display: "inline-block",
              padding: "4px 8px",
              border: "2px solid #1890ff",
              borderRadius: "4px",
              cursor: "pointer",
            }}
            onClick={() => showRescheduleDetails(record)}
          >
            <EyeOutlined
              style={{
                fontSize: "16px",
                color: "#1890ff",
              }}
            />
          </div>
        </>
      ),
    },
  ];

  const RescheduleApprovedList = [
    {
      title: "SL No",
      key: "slNo",
      render: (text, record, index) => (pageIndex - 1) * pageSize + index + 1,
    },
    { title: "Instructor", dataIndex: "instructor", key: "instructor" },
    { title: "Class Name", dataIndex: "name", key: "name" },
    {
      title: "Previous Slot Date",
      dataIndex: "previousSlotDate",
      key: "previousSlotDate",
      render: (date) => moment(date).format("YYYY-MM-DD"),
    },
    {
      title: "Reschedule Date",
      dataIndex: "rescheduleDate",
      key: "rescheduleDate",
      render: (date) => moment(date).format("YYYY-MM-DD"),
    },
    {
      title: "New Start Time",
      dataIndex: "newSlotStartTime",
      key: "newSlotStartTime",
    },
    {
      title: "New End Time",
      dataIndex: "newSlotEndTime",
      key: "newSlotEndTime",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <>
          <div
            style={{
              display: "inline-block",
              padding: "4px 8px",
              border: "2px solid #1890ff",
              borderRadius: "4px",
              cursor: "pointer",
            }}
            onClick={() => showRescheduleDetails(record)}
          >
            <EyeOutlined
              style={{
                fontSize: "16px",
                color: "#1890ff",
              }}
            />
          </div>
        </>
      ),
    },
  ];

  return (
    <div className="inst">
      {isLoading && <Loader />}
      {!showAddInstructorForm && (
        <>
          <div className="inst_header">
            <h2>Reschedule list</h2>
          </div>

          <div className="inst_content">
            <div className="inst_content_custom">
              <div
                className={activeTab === "2" ? "tab selected" : "tab"}
                onClick={() => handleTabChange("2")}
              >
                Reschedule Request list
              </div>
              <div
                className={activeTab === "3" ? "tab selected" : "tab"}
                onClick={() => handleTabChange("3")}
              >
                Approved List
              </div>
            </div>

            <div className="inst_content_search">
              <Input
                placeholder="Search"
                prefix={<SearchOutlined />}
                onChange={(e) => debouncedFetchClasses(e.target.value)}
              />
              <Button
                icon={
                  isAscending === true ? (
                    <SortAscendingOutlined />
                  ) : (
                    <SortDescendingOutlined />
                  )
                }
                className="sortButton"
                onClick={handleSorting}
              />
            </div>
          </div>

          <div className="inst_table">
            <Table
              columns={
                activeTab === "2"
                  ? RescheduleRequestList
                  : RescheduleApprovedList
              }
              dataSource={tableData}
              pagination={{
                // current: pageIndex,
                pageSize,
                total: totalRecords,
                showSizeChanger: true,
                pageSizeOptions: getPageSizeOptions(),
              }}
              loading={loading}
              onChange={handleTableChange}
              scroll={{ x: "max-content" }}
            />
          </div>
        </>
      )}

      <Modal
        title="Deny Instructor Request"
        visible={isModalVisible}
        onCancel={handleModalClose}
        onOk={handleModalSubmit}
        okText="Submit"
        cancelText="Cancel"
      >
        <TextArea
          rows={4}
          placeholder="Enter denial reason"
          value={denialMessage}
          onChange={(e) => setDenialMessage(e.target.value)}
        />
      </Modal>
      <Modal
        title="Reschedule Details"
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        centered
        width={500}
      >
        {selectedReschedule && (
          <Card bordered={false} style={{ textAlign: "center", padding: 20 }}>
            <Title level={4}>{selectedReschedule.name}</Title>
            <Text type="secondary">
              Instructor: {selectedReschedule.instructor}
            </Text>
            <Divider />
            <Row gutter={[16, 8]}>
              <Col span={12}>
                <Text>
                  <strong>📅 Previous Slot Date:</strong>{" "}
                  {moment(selectedReschedule.previousSlotDate).format(
                    "YYYY-MM-DD"
                  )}
                </Text>
              </Col>
              <Col span={12}>
                <Text>
                  <strong>📅 Reschedule Date:</strong>{" "}
                  {moment(selectedReschedule.rescheduleDate).format(
                    "YYYY-MM-DD"
                  )}
                </Text>
              </Col>
              <Col span={12}>
                <Text>
                  <strong>⏰ New Start Time:</strong>{" "}
                  {selectedReschedule.newSlotStartTime}
                </Text>
              </Col>
              <Col span={12}>
                <Text>
                  <strong>⏰ New End Time:</strong>{" "}
                  {selectedReschedule.newSlotEndTime}
                </Text>
              </Col>
              <Col span={24}>
                <Text>
                  <strong>📌 Categories:</strong>{" "}
                  {selectedReschedule.categories.join(", ")}
                </Text>
              </Col>
              <Col span={24}>
                <Text>
                  <strong>💬 Message:</strong> {selectedReschedule.message}
                </Text>
              </Col>
              <Col span={24}>
                <Text>
                  <strong>✅ Status:</strong>{" "}
                  {selectedReschedule.isApproved ? "Approved" : "Pending"}
                </Text>
              </Col>
            </Row>
          </Card>
        )}
      </Modal>
    </div>
  );
};

export default Reschedule;
