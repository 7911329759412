import React, { useEffect, useState } from "react";
import { Button, Card, Table, Tabs } from "antd";
import "./ManageInstructorForm.scss";
import Buttons from "../../../Components/Buttons/Buttons";
import FormInputs from "../../../Components/FormInputs/FormInputs";
import TextArea from "antd/es/input/TextArea";
import { LeftCircleOutlined } from "@ant-design/icons";
import SelectInputs from "../../../Components/SelectInputs/SelectInputs";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { getApi, postApi } from "../../../Utlis/apiService";
import {
  ADD_NEW_INSTRUCTOR,
  GET_ALL_INSTRUCTORS,
  GET_ASSIGNED_CLASSES,
  GET_INSTRUCTOR_SLOTS,
  UPDATE_INSTRUCTOR_SLOTS,
  UPDATE_INSTRUCTORS,
} from "../../../Utlis/apiPath";
import { errorToast, successToast } from "../../../Services/ToastHelper";
import Loader from "../../../Components/Loader/Loader";
import { Checkbox, Select, Switch } from "antd";
import { TimePicker } from "antd";
import dayjs from "dayjs";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { BackArrowIcon } from "../../../Components/Icons/BackArrowIcon";
import { useNavigate, useParams } from "react-router-dom";
import MultiSelectInput from "../../../Components/MultiSelectInput/MultiSelectInput";

const { TabPane } = Tabs;

const initialValues = {
  firstName: "",
  lastName: "",
  emailId: "",
  mobileNumber: "",
  countryCode: "+1",
  aboutMe: "",
  gender: "",
  education: "",
  experience: "",
  expertise: "",
};
const ManageInstructorForm = ({ setShowAddInstructorForm, fetchTableData }) => {
  const [formData, setFormData] = useState(initialValues);
  const [selectedValue, setSelectedValue] = useState(null);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const Navigate = useNavigate();
  const [updateData, setUpdateData] = useState();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [isApproved, setIsApproved] = useState(true);
  const [isDenied, setIsDenied] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const { id } = useParams();
  const [assignedClasses, setAssignedClasses] = useState();
  const [removedIds, setRemovedIds] = useState([]);
  const [removedSlotIds, setRemovedSlotIds] = useState([]);
  const [newlyAddedSlots, setNewlyAddedSlots] = useState([]);
  const [phoneValue, setPhoneValue] = useState();
  const initialSchedule = {
    Monday: { isActive: false, slots: [] },
    Tuesday: { isActive: false, slots: [] },
    Wednesday: { isActive: false, slots: [] },
    Thursday: { isActive: false, slots: [] },
    Friday: { isActive: false, slots: [] },
    Saturday: { isActive: false, slots: [] },
    Sunday: { isActive: false, slots: [] },
  };

  const [schedule, setSchedule] = useState({
    Monday: { isActive: false, slots: [] },
    Tuesday: { isActive: false, slots: [] },
    Wednesday: { isActive: false, slots: [] },
    Thursday: { isActive: false, slots: [] },
    Friday: { isActive: false, slots: [] },
    Saturday: { isActive: false, slots: [] },
    Sunday: { isActive: false, slots: [] },
  });

  const [prevSchedule, setPrevSchedule] = useState(schedule);

  useEffect(() => {
    const newRemovedIds = [];
    Object.keys(schedule).forEach((day) => {
      const currentSlots = schedule[day].slots;
      const previousSlots = prevSchedule[day]?.slots || [];

      const removedIds = previousSlots.filter(
        (id) => !currentSlots.includes(id)
      );
      newRemovedIds.push(...removedIds);
    });
    if (newRemovedIds.length > 0) {
      setRemovedIds((prev) => [...prev, ...newRemovedIds]);
    }
    setPrevSchedule(schedule);
  }, [schedule]);

  const patchFormData = (updateData) => {
    const updatedValues = {
      firstName: updateData.firstName || "",
      lastName: updateData.lastName || "",
      emailId: updateData.mailId || "",
      mobileNumber: updateData.mobileNo || "",
      countryCode: updateData.countryCode || "+1",
      aboutMe: updateData.aboutMe || "",
      gender: updateData.gender || "",
      education: updateData.education || "",
      experience: updateData.experience || "",
      expertise: updateData.expertise || "",
    };

    setFormData((prevState) => ({
      ...prevState,
      ...updatedValues,
    }));
  };

  useEffect(() => {
    setPhoneValue(`${formData.countryCode}${formData.mobileNumber}`);
  }, [formData.countryCode, formData.mobileNumber]);

  useEffect(() => {
    handleManageInstructor(id);
  }, [id]);

  const handleManageInstructor = async (id) => {
    setIsLoading(true);
    try {
      const response = await getApi(GET_ALL_INSTRUCTORS, {
        params: {
          instructorId: id,
          pageIndex,
          pageSize,
          search: searchTerm,
          isApproved,
          isDenied,
        },
      });

      if (response.status === 200) {
        setUpdateData(response.data?.instructors || []);
        setTotalRecords(response.data?.totalRecords);
      } else if (response.status === 404) {
        setTotalRecords(0);
        console.warn("No data found for the given criteria.");
      }
    } catch (error) {
      console.error("Error fetching table data:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchInstructorSlots(id);
  }, [id]);

  const patchScheduleData = (slotsData) => {
    const updatedSchedule = { ...initialSchedule };

    slotsData.forEach((slot) => {
      const day = slot.day;

      if (updatedSchedule[day] && slot.id) {
        const slotExists = updatedSchedule[day].slots.some(
          (existingSlot) => existingSlot.id === slot.id
        );

        if (!slotExists) {
          updatedSchedule[day].isActive = true;
          updatedSchedule[day].slots.push({
            id: slot.id,
            start: slot.startTime,
            end: slot.endTime,
            isPatched: true,
          });
        }
      }
    });

    setSchedule(updatedSchedule);
  };

  const fetchInstructorSlots = async (id) => {
    setIsLoading(true);
    const params = {
      instructorId: id,
    };
    const { status, data } = await getApi(GET_INSTRUCTOR_SLOTS, {
      params,
    });
    if (status === 200) {
      patchScheduleData(data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const UpdateInstructorSlots = async (id) => {
    setIsLoading(true);

    try {
      const payload = {
        instructorId: id,
        slots: newlyAddedSlots,
        prevSlotIds: removedSlotIds,
      };

      const { status, data } = await postApi(UPDATE_INSTRUCTOR_SLOTS, payload);

      if (status === 200) {
        setNewlyAddedSlots();
        setRemovedSlotIds();
      }
    } catch (error) {
      console.error("Error updating instructor slots:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleAssignedClasses(id);
  }, [id]);

  const handleAssignedClasses = async (id) => {
    setIsLoading(true);
    const params = {
      instructorId: id,
    };
    const { status, data } = await getApi(GET_ASSIGNED_CLASSES, {
      params,
    });
    if (status === 200) {
      setAssignedClasses(data?.classes);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  // Call the function with the first entry in updateData
  useEffect(() => {
    if (updateData?.length > 0) {
      patchFormData(updateData[0]);
    }
  }, [updateData]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleToggle = (day) => {
    setSchedule((prev) => {
      const isCurrentlyActive = prev[day].isActive;

      if (isCurrentlyActive) {
        const slotIds = prev[day].slots.map((slot) => slot.id);
        setRemovedSlotIds((prevIds) => [...prevIds, ...slotIds]); // Track removed slot IDs
      }

      return {
        ...prev,
        [day]: {
          ...prev[day],
          isActive: !isCurrentlyActive,
          slots: isCurrentlyActive ? [] : prev[day].slots, // Clear slots if toggled off
        },
      };
    });
  };

  const handleTimeChange = (day, index, time, type) => {
    setSchedule((prev) => {
      const updatedSlots = [...prev[day].slots];
      const formattedTime = time ? time.format("HH:mm") : "";

      // Update the selected slot
      updatedSlots[index] = { ...updatedSlots[index], [type]: formattedTime };

      // Sort slots to maintain chronological order
      updatedSlots.sort((a, b) =>
        dayjs(a.start, "HH:mm").diff(dayjs(b.start, "HH:mm"))
      );

      // Check for overlapping slots
      for (let i = 0; i < updatedSlots.length - 1; i++) {
        let currentEnd = dayjs(updatedSlots[i].end, "HH:mm");
        let nextStart = dayjs(updatedSlots[i + 1].start, "HH:mm");

        if (currentEnd.isAfter(nextStart)) {
          errorToast("Slots cannot overlap. Please adjust the timing.");
          return prev;
        }
      }

      return { ...prev, [day]: { ...prev[day], slots: updatedSlots } };
    });
  };

  const addTimeSlot = (day) => {
    setSchedule((prev) => {
      const existingSlots = prev[day].slots;

      // Sort existing slots before adding a new one
      existingSlots.sort((a, b) =>
        dayjs(a.start, "HH:mm").diff(dayjs(b.start, "HH:mm"))
      );

      let lastSlot =
        existingSlots.length > 0
          ? existingSlots[existingSlots.length - 1]
          : null;
      let newStart = lastSlot
        ? dayjs(lastSlot.end, "HH:mm").add(1, "minute")
        : dayjs("00:00", "HH:mm");
      let newEnd = newStart.add(1, "hour");

      // Ensure the new slot does not exceed 24 hours
      if (newEnd.isAfter(dayjs("23:59", "HH:mm"))) {
        errorToast("Cannot add slot beyond 24-hour range.");
        return prev;
      }

      return {
        ...prev,
        [day]: {
          ...prev[day],
          slots: [
            ...existingSlots,
            { start: newStart.format("HH:mm"), end: newEnd.format("HH:mm") },
          ],
        },
      };
    });
  };

  const removeTimeSlot = (day, index) => {
    setSchedule((prev) => {
      const slotToRemove = prev[day].slots[index];
      const updatedSlots = prev[day].slots.filter((_, i) => i !== index);

      if (slotToRemove?.id) {
        setRemovedSlotIds((prevIds) => [...prevIds, slotToRemove.id]);
      }

      return { ...prev, [day]: { ...prev[day], slots: updatedSlots } };
    });
  };

  useEffect(() => {
    const formattedArray = Object.entries(schedule).reduce(
      (acc, [day, data]) => {
        if (data.slots.length > 0) {
          const filteredSlots = data.slots.filter((slot) => !slot.id); // Filter out slots that have an id

          if (filteredSlots.length > 0) {
            acc.push({
              days: day,
              startEndTime: filteredSlots.map((slot) => ({
                startTime: slot.start,
                endTime: slot.end,
              })),
            });
          }
        }
        return acc;
      },
      []
    );

    setNewlyAddedSlots(formattedArray, null, 2);
  }, [schedule]);

  const handlePhoneChange = (value, country) => {
    setFormData({
      ...formData,
      mobileNumber: value.slice(country.dialCode.length),
      countryCode: `+${country.dialCode}`,
    });
  };

  const validateFields = () => {
    let errObj = {};

    if (!formData?.firstName.trim()) {
      errObj.firstName = "First Name is required";
    }
    if (!formData?.lastName.trim()) {
      errObj.lastName = "Last Name is required";
    }
    if (!formData?.emailId.trim()) {
      errObj.emailId = "Email is required";
    } else if (/\s/.test(formData?.emailId)) {
      errObj.emailId = "Email should not contain spaces";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData?.emailId)) {
      errObj.emailId = "Please enter a valid email address";
    }
    if (!formData?.mobileNumber.trim()) {
      errObj.mobileNumber = "Mobile Number is required";
    }
    // if (!formData?.aboutMe.trim()) {
    //   errObj.aboutMe = "This field is required";
    // }
    if (!formData?.gender.trim()) {
      errObj.gender = "Gender is required";
    }
    if (!formData?.education.trim()) {
      errObj.education = "Education is required";
    }
    if (!formData.experience && formData.experience !== 0) {
      errObj.experience = "Experience is required";
    }
    // if (!formData?.expertise.trim()) {
    //   errObj.expertise = "Expertise is required";
    // }

    const isValid = Object.keys(errObj).length === 0;

    setErrors(errObj);
    return isValid;
  };

  const handleUpdate = async () => {
    if (validateFields()) {
      const mappedSlots = Object.keys(schedule)
        .map((day) => {
          if (schedule[day].isActive && schedule[day].slots.length > 0) {
            return {
              days: day,
              startEndTime: schedule[day].slots.map((slot) => ({
                startTime: slot.start,
                endTime: slot.end,
              })),
            };
          }
          return null;
        })
        .filter((slot) => slot !== null);
      const payload = {
        instructorId: updateData[0]?.instructorId,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.emailId,
        countryCode: formData.countryCode,
        phoneNumber: formData.mobileNumber,
        gender: formData.gender,
        aboutMe: formData.aboutMe,
        education: formData.education,
        experienceYears: formData.experience,
        experties: JSON.stringify(formData.expertise),
        slots: mappedSlots,
      };
      setIsLoading(true);
      try {
        const { status, message } = await postApi(UPDATE_INSTRUCTORS, payload);
        if (status === 200) {
          Navigate("/instructor");
          UpdateInstructorSlots(id);
          handleManageInstructor(id);
          successToast(message);
          fetchTableData();
        } else {
          errorToast(message);
        }
      } catch (error) {
        console.error("Error adding instructor:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const hanldeBack = () => {
    Navigate("/instructor");
  };

  const handleSelectChange = (value) => {
    setSelectedValue(value);
  };

  const options = [
    { value: "1", label: "YinYoga" },
    { value: "2", label: "Meditation" },
    { value: "3", label: "Hatha" },
    { value: "4", label: "Vinyasa Flow" },
  ];

  const columns = [
    {
      title: "Sl. No",
      dataIndex: "slNo",
      key: "slNo",
    },
    {
      title: "Class Name",
      dataIndex: "className",
      key: "className",
    },
    {
      title: "Class Type",
      dataIndex: "classType",
      key: "classType",
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Booking Capacity",
      dataIndex: "capacity",
      key: "capacity",
    },
    {
      title: "Mode",
      dataIndex: "mode",
      key: "mode",
    },
  ];

  const data = assignedClasses?.map((item, index) => ({
    key: item.id, // Unique key for each row
    slNo: index + 1,
    className: item.name,
    classType: item.type,
    time: item.duration,
    capacity: item.capacity,
    mode: item.mode,
  }));

  return (
    <div className="Manageinstructor">
      <div className="instform">
        {isLoading && <Loader />}
        <div className="instform_header">
          <h2
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
            onClick={hanldeBack}
          >
            <BackArrowIcon />
            Instructor
          </h2>

          <Buttons variant="primary" onClick={handleUpdate}>
            Update
          </Buttons>
        </div>

        <Tabs defaultActiveKey="1">
          <TabPane tab="Personal Information" key="1">
            <div className="instform_content">
              <div className="instform_content_details">
                {/* <div className="instform_content_details_top_profile">
                <div className="instform_content_details_top_profile_imge">
                  {imageUrl ? (
                    <img src={imageUrl} alt="Profile" />
                  ) : (
                   
                  )}
                   <h2>JP</h2>
                </div>
                <button className="instform_content_details_top_profile_button upload">
                  Upload
                </button>
                <button className="instform_content_details_top_profile_button remove">
                  Remove
                </button>
              </div> */}
              </div>
              <div className="instform_wrapper">
                <div className="instform_content_details">
                  <div className="instform_content_details_top">
                    <div className="form_field">
                      <FormInputs
                        title="First Name"
                        placeholder="Enter First Name"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                      />
                      {errors.firstName && (
                        <span className="error">{errors.firstName}</span>
                      )}
                    </div>
                    <div className="form_field">
                      <FormInputs
                        title="Last Name"
                        placeholder="Enter Last Name"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                      />
                      {errors.lastName && (
                        <span className="error">{errors.lastName}</span>
                      )}
                    </div>
                    <div className="form_field">
                      <FormInputs
                        title="Email Id"
                        placeholder="Enter Email Id"
                        name="emailId"
                        value={formData.emailId}
                        onChange={handleChange}
                      />
                      {errors.emailId && (
                        <span className="error">{errors.emailId}</span>
                      )}
                    </div>
                    <div className="form_field phone-input-container">
                      <label>Mobile Number</label>
                      <PhoneInput
                        country={"us"}
                        value={phoneValue}
                        onChange={handlePhoneChange}
                        inputStyle={{
                          width: "100%",
                          borderRadius: "5px",
                          borderColor: "#d9d9d9",
                        }}
                      />
                      {errors.mobileNumber && (
                        <span className="error">{errors.mobileNumber}</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="instform_content_textarea">
                  <label htmlFor="notes">About</label>
                  <TextArea
                    id="notes"
                    placeholder="Enter notes here"
                    rows={4}
                    name="aboutMe"
                    value={formData.aboutMe}
                    onChange={handleChange}
                    style={{
                      fontWeight: 600,
                      color: "#717171",
                      fontSize: "16px",
                    }}
                  />
                  {/* {errors.aboutMe && (
                    <span className="error">{errors.aboutMe}</span>
                  )} */}
                </div>
                <div className="instform_content_details">
                  <div className="instform_content_details_top">
                    <div className="form_field">
                      <SelectInputs
                        title="Gender"
                        name="gender"
                        placeholder="Select Gender"
                        options={[
                          { label: "Male", value: "Male" },
                          { label: "Female", value: "Female" },
                        ]}
                        value={formData.gender}
                        onChange={(value) =>
                          setFormData({ ...formData, gender: value })
                        }
                        size="large"
                        style={{ width: "100%" }}
                      />
                      {errors.gender && (
                        <span className="error">{errors.gender}</span>
                      )}
                    </div>
                    <div className="form_field">
                      <FormInputs
                        title="Education"
                        placeholder="Enter Education"
                        name="education"
                        value={formData.education}
                        onChange={handleChange}
                      />
                      {errors.education && (
                        <span className="error">{errors.education}</span>
                      )}
                    </div>
                    <div className="form_field">
                      <FormInputs
                        type="number"
                        title="Experience"
                        placeholder="Enter Experience"
                        name="experience"
                        value={formData?.experience}
                        onChange={handleChange}
                      />
                      {errors.experience && (
                        <span className="error">{errors.experience}</span>
                      )}
                    </div>
                    <div className="form_field">
                      <MultiSelectInput
                        title="Expertise*"
                        options={[
                          "Yin Yoga",
                          "Meditation",
                          "Hatha",
                          "Vinyasa Flow",
                        ]} // Predefined options
                        value={formData.expertise || []} // Ensure it's always an array
                        onChange={(selectedValues) => {
                          setFormData((prev) => ({
                            ...prev,
                            expertise: selectedValues, // Updates expertise with selected & entered values
                          }));

                          // Clear error when expertise is selected
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            expertise:
                              selectedValues.length > 0
                                ? ""
                                : prevErrors.expertise,
                          }));
                        }}
                        placeholder="Select or enter your expertise"
                      />
                      {/* {errors.expertise && (
                        <span className="error">{errors.expertise}</span>
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Card className="instform_contentModal_data">
              <div className="weekly_schedule_container">
                <h2>Weekly Schedule</h2>
                <p>Set your Weekly hours</p>
                {Object.keys(schedule).map((day) => (
                  <div key={day} className="weekly_schedule_day">
                    <div className="weekly_schedule_day_header">
                      <Switch
                        checked={schedule[day].isActive}
                        onChange={() => handleToggle(day)}
                      />
                      <h4 className="day_name">{day}</h4>
                    </div>
                    {schedule[day].isActive ? (
                      <div className="time_slots">
                        {schedule[day].slots.map((slot, index) => (
                          <div key={index} className="time_slot">
                            <TimePicker
                              value={
                                slot.start ? dayjs(slot.start, "HH:mm") : null
                              }
                              placeholder="Start Time"
                              format="HH:mm"
                              onChange={(time) =>
                                handleTimeChange(day, index, time, "start")
                              }
                              disabled={slot.isPatched} // Disable for patched slots
                              style={
                                slot.isPatched
                                  ? {
                                      cursor: "not-allowed",
                                      backgroundColor: "#f5f5f5",
                                    }
                                  : {}
                              }
                            />
                            <TimePicker
                              value={slot.end ? dayjs(slot.end, "HH:mm") : null}
                              placeholder="End Time"
                              format="HH:mm"
                              onChange={(time) =>
                                handleTimeChange(day, index, time, "end")
                              }
                              disabled={slot.isPatched} // Disable for patched slots
                              style={
                                slot.isPatched
                                  ? {
                                      cursor: "not-allowed",
                                      backgroundColor: "#f5f5f5",
                                    }
                                  : {}
                              }
                            />
                            <Button
                              type="danger"
                              icon={<DeleteOutlined />}
                              onClick={() => removeTimeSlot(day, index)}
                              style={{
                                backgroundColor: "#ff4d4f",
                                borderRadius: "10%",
                                width: "30px",
                                height: "30px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                border: "none",
                                color: "white",
                              }}
                            />
                          </div>
                        ))}
                        <Button
                          type="dashed"
                          icon={<PlusOutlined />}
                          onClick={() => addTimeSlot(day)}
                          className="add_slot_button"
                        >
                          Add Slot
                        </Button>
                      </div>
                    ) : (
                      <p>Unavailable</p>
                    )}
                  </div>
                ))}
              </div>
            </Card>
          </TabPane>

          {/* <TabPane tab="Bank Details" key="2">
            <div className="instform_content">
              <div className="instform_content_bank">
                <FormInputs title="Bank Holder Name" placeholder="Enter Name" />
                <FormInputs
                  title="Account Number"
                  placeholder="Enter Account Number"
                />
                <FormInputs title="IFSC Code" placeholder="Enter IFSC Code" />
                <FormInputs title="Branch Name" placeholder="Branch Name" />
              </div>
            </div>
          </TabPane> */}
          {/* <TabPane tab="Assigned Classes list" key="3">
            <Table
              columns={columns}
              dataSource={data}
              scroll={{ y: 300 }}
              style={{ height: "400px", overflow: "hidden" }}
              pagination={false}
            />
          </TabPane> */}
        </Tabs>
      </div>
    </div>
  );
};

export default ManageInstructorForm;
