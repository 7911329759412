import React, { useEffect, useState } from "react";
import { Button, Card, Table, Tabs } from "antd";
import "./ViewInstructorClass.scss";
import Buttons from "../../../Components/Buttons/Buttons";
import "react-phone-input-2/lib/style.css";
import { getApi, postApi } from "../../../Utlis/apiService";
import {
  GET_ALL_INSTRUCTORS,
  GET_ASSIGNED_CLASSES,
  GET_INSTRUCTOR_SLOTS,
  UPDATE_INSTRUCTOR_SLOTS,
  UPDATE_INSTRUCTORS,
} from "../../../Utlis/apiPath";
import { errorToast, successToast } from "../../../Services/ToastHelper";
import Loader from "../../../Components/Loader/Loader";
import { BackArrowIcon } from "../../../Components/Icons/BackArrowIcon";
import { useNavigate, useParams } from "react-router-dom";

const { TabPane } = Tabs;

const initialValues = {
  firstName: "",
  lastName: "",
  emailId: "",
  mobileNumber: "",
  countryCode: "+1",
  aboutMe: "",
  gender: "",
  education: "",
  experience: "",
  expertise: "",
};
const ViewInstructorClass = ({ setShowAddInstructorForm, fetchTableData }) => {
  const [formData, setFormData] = useState(initialValues);
  const [selectedValue, setSelectedValue] = useState(null);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const Navigate = useNavigate();
  const [updateData, setUpdateData] = useState();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [isApproved, setIsApproved] = useState(true);
  const [isDenied, setIsDenied] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const { id } = useParams();
  const [assignedClasses, setAssignedClasses] = useState();

  useEffect(() => {
    handleManageInstructor(id);
  }, [id]);

  const handleManageInstructor = async (id) => {
    setIsLoading(true);
    try {
      const response = await getApi(GET_ALL_INSTRUCTORS, {
        params: {
          instructorId: id,
          pageIndex,
          pageSize,
          search: searchTerm,
          isApproved,
          isDenied,
        },
      });

      if (response.status === 200) {
        setUpdateData(response.data?.instructors || []);
        setTotalRecords(response.data?.totalRecords);
      } else if (response.status === 404) {
        setTotalRecords(0);
        console.warn("No data found for the given criteria.");
      }
    } catch (error) {
      console.error("Error fetching table data:", error.message);
    } finally {
      setIsLoading(false);
    }
  };



  useEffect(() => {
    handleAssignedClasses(id);
  }, [id]);

  const handleAssignedClasses = async (id) => {
    setIsLoading(true);
    const params = {
      instructorId: id,
    };
    const { status, data } = await getApi(GET_ASSIGNED_CLASSES, {
      params,
    });
    if (status === 200) {
      setAssignedClasses(data?.classes);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  // Call the function with the first entry in updateData

  const hanldeBack = () => {
    Navigate("/instructor");
  };

  const handleSelectChange = (value) => {
    setSelectedValue(value);
  };

  const columns = [
    {
      title: "Sl. No",
      dataIndex: "slNo",
      key: "slNo",
    },
    {
      title: "Class Name",
      dataIndex: "className",
      key: "className",
    },
    {
      title: "Class Type",
      dataIndex: "classType",
      key: "classType",
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Booking Capacity",
      dataIndex: "capacity",
      key: "capacity",
    },
    {
      title: "Mode",
      dataIndex: "mode",
      key: "mode",
    },
  ];

  const data = assignedClasses?.map((item, index) => ({
    key: item.id,
    slNo: index + 1,
    className: item.name,
    classType: item.classType,
    time: item.duration,
    capacity: item.capacity,
    mode: item.mode,
  }));

  return (
    <div className="Manageinstructor">
      <div className="instform">
        {isLoading && <Loader />}
        <div className="instform_header">
          <h2
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
            onClick={hanldeBack}
          >
            <BackArrowIcon />
            Instructor
          </h2>
        </div>

        <Tabs defaultActiveKey="1">
          <TabPane tab="Assigned Classes list" key="3">
            <Table
              columns={columns}
              dataSource={data}
              scroll={{ y: 300 }}
              style={{ height: "400px", overflow: "hidden" }}
              pagination={false}
            />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default ViewInstructorClass;
