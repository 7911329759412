import React, { useState, useEffect } from "react";
import "./Bookings.scss";
import { SearchOutlined, SortAscendingOutlined } from "@ant-design/icons";
import {
  Table,
  Button,
  Input,
  Modal,
  DatePicker,
  Form,
  TimePicker,
} from "antd";
import { getApi } from "../../Utlis/apiService";
import { GET_ALL_BOOKINGS, GET_ALL_CLASSESS_COUNT } from "../../Utlis/apiPath";

const { RangePicker } = DatePicker;

const Bookings = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: 10,
    total: 0,
  });

  const [classCount, setClassCount] = useState({
    totalBookedClasses: 0,
    groupClasses: 0,
    oneOnOneClasses: 0,
    rescheduledClasses: 0,
    cancelledClasses: 0,
  });
  const [dateRange, setDateRange] = useState([]);

  useEffect(() => {
    fetchAllBookings();
  }, [search, pagination.current, pagination.pageSize, dateRange]);

  const fetchAllBookings = async () => {
    setIsLoading(true);
    try {
      const params = {
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
        search,
        startDate: dateRange[0]?.format("YYYY-MM-DD"),
        endDate: dateRange[1]?.format("YYYY-MM-DD"),
      };

      const { status, data } = await getApi(GET_ALL_BOOKINGS, { params });

      if (status === 200) {
        const currentDate = new Date(); // Get current date & time

        const formattedData = data.bookings.map((booking, index) => {
          let status = "Completed"; // Default status

          if (booking.nextClass) {
            const nextClassStartTime = new Date(
              `${booking.nextClass.date} ${booking.nextClass.startTime}`
            );
            const nextClassEndTime = new Date(
              `${booking.nextClass.date} ${booking.nextClass.endTime}`
            );

            if (currentDate < nextClassStartTime) {
              status = "Upcoming"; // Future class
            } else if (
              currentDate >= nextClassStartTime &&
              currentDate <= nextClassEndTime
            ) {
              status = "Ongoing"; // Class is currently happening
            } else {
              status = "Completed"; // Class is over
            }
          }

          return {
            key: index + 1,
            classDetails: booking.classTitle,
            dateTime: `${booking.classDate} ${booking.classStartTime}-${booking.classEndTime}`,
            nextClass: booking.nextClass
              ? `${booking.nextClass.date} ${booking.nextClass.startTime}-${booking.nextClass.endTime}`
              : "N/A",
            classType: booking.classType,
            studentsCount: booking.studentCount,
            mode: booking.mode,
            status, // Dynamically set status
            instructor: booking.instructor,
            rescheduleRequest: booking.rescheduledRequest ? "View" : "N/A",
            earnings: `₹${booking.earnings}`,
          };
        });

        setTableData(formattedData);
        setPagination({
          ...pagination,
          total: data.totalRecords,
        });
      } else {
        setTableData([]);
      }
    } catch (error) {
      console.error("Error fetching bookings:", error);
      setTableData([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllClassCount();
  }, []);

  const fetchAllClassCount = async () => {
    setIsLoading(true);
    try {
      const { status, data } = await getApi(GET_ALL_CLASSESS_COUNT);
      if (status === 200) {
        setClassCount(data);
      } else {
        setClassCount({
          totalBookedClasses: 0,
          groupClasses: 0,
          oneOnOneClasses: 0,
          rescheduledClasses: 0,
          cancelledClasses: 0,
        });
      }
    } catch (error) {
      console.error("Error fetching classes:", error);
      setClassCount({
        totalBookedClasses: 0,
        groupClasses: 0,
        oneOnOneClasses: 0,
        rescheduledClasses: 0,
        cancelledClasses: 0,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination,
      current: pagination.current,
    });
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setPagination({
      ...pagination,
      current: 0,
    });
  };

  const handleDateChange = (dates) => {
    setDateRange(dates);
    setPagination({
      ...pagination,
      current: 0,
    });
  };

  const showModal = (record) => {
    // Update the modal data with the record, ensuring `nextClass` is included
    setModalData({
      ...record,
      nextClass: record.nextClass, // Ensure nextClass is passed
    });

    // Show the modal
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: "SL No",
      key: "slNo",
      render: (text, record, index) =>
        pagination.current * pagination.pageSize + (index + 1),
    },
    { title: "Class Details", dataIndex: "classDetails" },
    {
      title: "Date & Time",
      dataIndex: "nextClass", // Updated to use nextClass
      render: (nextClass) => nextClass || "N/A", // Display formatted nextClass data
    },
    { title: "Class Type", dataIndex: "classType" },
    { title: "Students Count", dataIndex: "studentsCount" },
    { title: "Mode", dataIndex: "mode" },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => (
        <span
          style={{
            color:
              status === "Upcoming"
                ? "#52c41a"
                : status === "Cancelled"
                ? "#f5222d"
                : "#1890ff",
          }}
        >
          {status}
        </span>
      ),
    },
    { title: "Instructor", dataIndex: "instructor" },
    // {
    //   title: "Reschedule Request",
    //   dataIndex: "rescheduleRequest",
    //   render: (_, record) => (
    //     <Button type="link" onClick={() => showModal(record)}>
    //       View
    //     </Button>
    //   ),
    // },
    // {
    //   title: "Earnings",
    //   dataIndex: "earnings",
    //   render: (earnings) => `${earnings}`,
    // },
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   render: () => (
    //     <Button type="primary" size="small">
    //       Manage
    //     </Button>
    //   ),
    // },
  ];

  return (
    <div className="booking">
      <div className="booking_header">
        <h2>Bookings</h2>
      </div>

      <div className="booking_sec">
        <div className="booking_sec_card">
          <div className="booking_sec_card_data">
            <div className="content">
              <h3>Total Bookings</h3>
              <h2>{classCount.totalBookedClasses}</h2>
            </div>
          </div>
          <div className="booking_sec_card_data">
            <div className="content">
              <h3>Group Classes</h3>
              <h2>{classCount.groupClasses}</h2>
            </div>
          </div>
          <div className="booking_sec_card_data">
            <div className="content">
              <h3>One-on-One Classes</h3>
              <h2>{classCount.oneOnOneClasses}</h2>
            </div>
          </div>
          <div className="booking_sec_card_data">
            <div className="content">
              <h3>Rescheduled Class</h3>
              <h2>{classCount.rescheduledClasses}</h2>
            </div>
          </div>
          <div className="booking_sec_card_data">
            <div className="content">
              <h3>Cancelled Classes</h3>
              <h2>{classCount.cancelledClasses}</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="booking_header">
        <h2>List of all class</h2>
        <RangePicker onChange={handleDateChange} />
      </div>

      <div className="booking_content">
        <div className="booking_content_custom">
          <div
            className={activeTab === "1" ? "tab selected" : "tab"}
            onClick={() => setActiveTab("1")}
          >
            All
          </div>
          <div
            className={activeTab === "2" ? "tab selected" : "tab"}
            // onClick={() => setActiveTab("2")}
          >
            Past
          </div>
          {/* <div
            className={activeTab === "3" ? "tab selected" : "tab"}
            onClick={() => setActiveTab("3")}
          >
            Rescheduled
          </div> */}
          <div
            className={activeTab === "4" ? "tab selected" : "tab"}
            // onClick={() => setActiveTab("4")}
          >
            Cancelled
          </div>
        </div>

        <div className="booking_content_search">
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            value={search}
            onChange={handleSearch}
          />
          <Button icon={<SortAscendingOutlined />} />
        </div>
      </div>

      <Table
        columns={columns}
        dataSource={tableData}
        loading={isLoading}
        pagination={
          {
            // current: pagination.current,
            // pageSize: pagination.pageSize,
            // total: pagination.total,
          }
        }
        onChange={handleTableChange}
      />

      <Modal
        title="Rescheduled Request"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={500}
        footer={[
          <Button key="deny" onClick={handleCancel}>
            Deny
          </Button>,
          <Button key="accept" type="primary" onClick={handleOk}>
            Accept
          </Button>,
        ]}
      >
        {modalData && (
          <div>
            <h3>Instructor Name: {modalData.instructor}</h3>
            <div className="class-details">
              <h3>Class Details</h3>
              <div className="detail-row">
                <span className="label">Class Name:</span>
                <span>{modalData.classDetails}</span>
              </div>
              <div className="detail-row">
                <span className="label">Date:</span>
                <span>{modalData.nextClass}</span>
              </div>
              <div className="detail-row">
                <span className="label">Class Type:</span>
                <span>{modalData.classType}</span>
              </div>

              {/* Add Next Class Details */}
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Bookings;
