import React, { useEffect, useState } from "react";
import {
  Layout,
  Input,
  Button,
  Empty,
  Space,
  Typography,
  Table,
  Popconfirm,
  Row,
  Col,
  Card,
  Tooltip,
} from "antd";

import {
  EyeOutlined,
  PlusOutlined,
  SearchOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from "@ant-design/icons";
import "./ClassManagement.scss";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import ClassManagement from "./ClassManagentForm/ClassManagementForm";
import ClassManagementForm from "./ClassManagentForm/ClassManagementForm";
import Buttons from "../../Components/Buttons/Buttons";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { DELETE_CLASS_BY_ID, GET_ALL_CLASSESS } from "../../Utlis/apiPath";
import { deleteApi, getApi } from "../../Utlis/apiService";
import { errorToast, successToast } from "../../Services/ToastHelper";
import Loader from "../../Components/Loader/Loader";
import { debounce } from "lodash";
import { Modal, List } from "antd";
const { Content } = Layout;
const { Paragraph, Text, Title } = Typography;

const ClassesManagement = () => {
  const [addClassForm, setAddClassForm] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [classEditedData, setClassEditedData] = useState();
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [isAscending, setIsAscending] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  console.log(classEditedData, "classEditedData");

  const navigate = useNavigate();

  const handleAddClass = () => {
    setClassEditedData("");
    setAddClassForm(true);
  };

  const debouncedFetchClasses = debounce((searchValue) => {
    setSearch(searchValue);
    const page = 1;
    setPageIndex(1);
    fetchAllClasses(page, pageSize, searchValue);
  }, 500);

  function convertUtcToLocalTime(utcTime) {
    const date = new Date(utcTime);
    return date.toLocaleString();
  }

  const handleSorting = () => {
    setIsAscending((prevState) => (prevState === "" ? false : !prevState));
  };
  const getPageSizeOptions = () => {
    if (totalRecords <= 50) {
      return ["10", "20", "50"];
    } else {
      return ["15", "25", "50", "100"];
    }
  };

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    setPageIndex(current);
    setPageSize(pageSize);
    fetchAllClasses(current, pageSize);
  };

  function convertUtcToRegionalTime(utcTime, locale, timeZone) {
    const date = new Date(utcTime);
    const options = {
      timeZone,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    return new Intl.DateTimeFormat(locale, options).format(date);
  }

  const utcTime = "2024-12-20T07:04:11Z";

  function convertPriceToRegional(priceInINR, locale, currency, exchangeRate) {
    const convertedPrice = priceInINR * exchangeRate; // Convert price
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
    }).format(convertedPrice); // Format as currency
  }

  const priceInINR = 1000;

  const usdPrice = convertPriceToRegional(priceInINR, "en-US", "USD", 0.012);

  const eurPrice = convertPriceToRegional(priceInINR, "fr-FR", "EUR", 0.011);

  const jpyPrice = convertPriceToRegional(priceInINR, "ja-JP", "JPY", 1.68);

  const columns = [
    {
      title: "SL No",
      key: "slNo",
      render: (text, record, index) => (pageIndex - 1) * pageSize + (index + 1),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Category",
      dataIndex: "categories",
      key: "category",
      width: 200, // Fixed width for truncation
      render: (categories) => {
        const categoryNames =
          categories && categories.length > 0
            ? categories.map((cat) => cat.name).join(", ")
            : "N/A";

        // If text is longer than 20 characters, truncate it
        const shouldTruncate = categoryNames.length > 20;
        const displayText = shouldTruncate
          ? `${categoryNames.slice(0, 20)}...`
          : categoryNames;

        return shouldTruncate ? (
          <Tooltip title={categoryNames}>
            <span>{displayText}</span>
          </Tooltip>
        ) : (
          <span>{categoryNames}</span>
        );
      },
    },

    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      render: (text, record) => {
        // Combine duration and buffer times to format the display
        const hours = record.durationHour || 0;
        const minutes = record.durationMunite || 0;
        return `${hours}h ${minutes}m`;
      },
    },
    {
      title: "Class Type",
      dataIndex: "classType",
      key: "classType",
    },
    {
      title: "Booking Capacity",
      dataIndex: "totalAttendees",
      key: "totalAttendees",
    },
    {
      title: "Mode",
      dataIndex: "joinType",
      key: "joinType",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <span
          style={{
            color: status === "upcoming" ? "green" : "red",
            fontWeight: "bold",
          }}
        >
          {status.charAt(0).toUpperCase() + status.slice(1)}
        </span>
      ),
    },
    {
      title: "Assigned To",
      dataIndex: "instructor",
      key: "assignedTo",
      render: (instructor) => (instructor && instructor.name) || "Unassigned",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <div style={{ display: "flex", gap: "20px" }}>
          <EyeOutlined
            style={{ cursor: "pointer", color: "green", fontSize: "20px" }}
            onClick={() => handleView(record)}
          />
          <EditOutlined
            onClick={() => handleEDit(record)}
            style={{ cursor: "pointer", color: "blue", fontSize: "20px" }}
          />
          <Popconfirm
            title="Are you sure you want to delete this class?"
            onConfirm={() => handleDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined
              style={{ cursor: "pointer", color: "red", fontSize: "20px" }}
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const handleEdit = (record) => {
    setEditData(record);
  };

  useEffect(() => {
    fetchAllClasses(1, 10);
  }, [isAscending]);

  const fetchAllClasses = async (page = 1, pageSize = 10, searchValue = "") => {
    setIsLoading(true);
    try {
      const pageIndex1 = page - 1;
      const search1 = searchValue !== undefined ? searchValue : search;

      const id = "";
      const params = {
        id,
        pageSize,
        search: search1,
        pageIndex: pageIndex1,
        isAscending,
      };

      const { status, message, data } = await getApi(GET_ALL_CLASSESS, {
        params,
      });
      if (status === 200) {
        setTableData(data.formattedClasses);
        setTotalRecords(data?.totalRecords);
      } else {
        setTableData([]);
      }
    } catch (error) {
      console.error("Error fetching classes:", error);
      setTableData([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleView = async (record) => {
    setClassEditedData(record);
    setIsModalVisible(true);
  };

  const showModal = () => {};

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleEDit = async (record) => {
    setClassEditedData(record);
    setIsLoading(true);
    try {
      const id = record.id;
      const params = {
        id,
      };

      const { status, message, data } = await getApi(GET_ALL_CLASSESS, {
        params,
      });
      if (status === 200) {
        setClassEditedData(data.formattedClasses);
      } else {
        setClassEditedData([]);
      }
    } catch (error) {
      console.error("Error fetching classes:", error);
      setTableData([]);
    } finally {
      setIsLoading(false);
    }
    setAddClassForm(true);
  };

  const handleDelete = async (ClassId) => {
    try {
      const { status, message } = await deleteApi(
        `${DELETE_CLASS_BY_ID}?id=${ClassId}`
      );
      if (status === 200) {
        successToast(message);
        fetchAllClasses(1, 10);
      } else {
        errorToast(message);
      }
    } catch (error) {
      console.error(error);
      errorToast("An error occurred while deleting the category.");
    }
  };

  return (
    <div>
      {isLoading && <Loader />}
      {!addClassForm && (
        <div className="headers">
          <Space
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Title level={3} style={{ margin: 0 }}>
              Classes Management
            </Title>
            <Buttons variant="primary" onClick={handleAddClass}>
              <PlusOutlined /> Add Classes
            </Buttons>
          </Space>
        </div>
      )}

      <div>
        {addClassForm ? (
          <ClassManagementForm
            setAddClassForm={setAddClassForm}
            fetchAllClasses={fetchAllClasses}
            classEditedData={classEditedData}
          />
        ) : (
          <div className="contentWrapper">
            <div className="contentWrapper_main">
              <Input
                placeholder="Search"
                prefix={<SearchOutlined />}
                className="searchInput"
                onChange={(e) => debouncedFetchClasses(e.target.value)}
              />
              <Button
                icon={
                  isAscending ? (
                    <SortAscendingOutlined />
                  ) : (
                    <SortDescendingOutlined />
                  )
                }
                className="sortButton"
                onClick={handleSorting}
              />
            </div>

            <div className="table-data">
              <Table
                columns={columns}
                dataSource={tableData.length > 0 ? tableData : []} // Ensure table remains
                pagination={{
                  pageSize,
                  total: totalRecords,
                  showSizeChanger: true,
                  pageSizeOptions: getPageSizeOptions(),
                }}
                onChange={handleTableChange}
                locale={{
                  emptyText:
                    tableData.length === 0 ? (
                      <Empty
                        description={
                          <div>
                            <Title level={4}>No Data Found</Title>
                            <Text>
                              Please try searching with a different keyword.
                            </Text>
                          </div>
                        }
                        imageStyle={{ height: 60 }}
                      />
                    ) : undefined,
                }}
                scroll={{ x: "max-content" }}
              />
            </div>
          </div>
        )}
      </div>

      <Modal
        title="Class Details"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={900}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Close
          </Button>,
        ]}
      >
        <div
          style={{
            border: "1px solid black",
            padding: "20px",
            borderRadius: "10px",
          }}
        >
          {/* Title and Description */}
          <Title level={3} style={{ textAlign: "center", color: "#1890ff" }}>
            {classEditedData?.title}
          </Title>
          <Paragraph>
            <span
              dangerouslySetInnerHTML={{ __html: classEditedData?.description }}
            />
          </Paragraph>

          {/* Class Information */}
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Paragraph>
                <Text strong>Price:</Text>₹{classEditedData?.price}
              </Paragraph>
              <Paragraph>
                <Text strong>USD Price:</Text> $
                {classEditedData?.compareUsdPrice}
              </Paragraph>
              <Paragraph>
                <Text strong>Join Type:</Text> {classEditedData?.joinType}
              </Paragraph>
              <Paragraph>
                <Text strong>Class Type:</Text> {classEditedData?.classType}
              </Paragraph>
              <Paragraph>
                <Text strong>Booking Type:</Text> {classEditedData?.bookingType}
              </Paragraph>
              <Paragraph>
                <Text strong>Start Date:</Text>{" "}
                {moment(classEditedData?.hourlystartdate).format(
                  "MMMM D, YYYY"
                )}
              </Paragraph>
              <Paragraph>
                <Text strong>End Date:</Text>{" "}
                {moment(classEditedData?.hourlyenddate).format("MMMM D, YYYY")}
              </Paragraph>
            </Col>
            <Col span={12}>
              <Paragraph>
                <Text strong>Status:</Text> {classEditedData?.status}
              </Paragraph>
              <Paragraph>
                <Text strong>Total Attendees:</Text>{" "}
                {classEditedData?.totalAttendees}
              </Paragraph>
              <Paragraph>
                <Text strong>Subscription:</Text>{" "}
                {classEditedData?.subscription ? "Yes" : "No"}
              </Paragraph>
              <Paragraph>
                <Text strong>Duration:</Text> {classEditedData?.durationHour}h{" "}
                {classEditedData?.durationMunite}m
              </Paragraph>
            </Col>
          </Row>

          {/* Joining Link */}
          {classEditedData?.joiningLink && (
            <Paragraph>
              <Text strong>Joining Link:</Text>{" "}
              <a
                href={classEditedData?.joiningLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                Join Class
              </a>
            </Paragraph>
          )}

          {/* Instructor Section */}
          <Card
            style={{ marginTop: "20px", textAlign: "center", padding: "10px" }}
          >
            <Title level={4}>Instructor</Title>
            <img
              src={classEditedData?.instructor?.profileImage}
              alt="Instructor"
              style={{
                width: "100px",
                borderRadius: "50%",
                marginBottom: "10px",
              }}
            />
            <Paragraph>
              <Text strong>{classEditedData?.instructor?.name}</Text>
            </Paragraph>
          </Card>

          {/* Categories */}
          <Title level={4} style={{ marginTop: "20px" }}>
            Categories
          </Title>
          <List
            grid={{ gutter: 16, column: 3 }}
            dataSource={classEditedData?.categories}
            renderItem={(category) => (
              <List.Item>
                <div
                  style={{
                    boxSizing: "border-box",
                    padding: "10px",
                    backgroundColor: "#f5f5f5", // Light background for visibility
                    borderRadius: "5px", // Slightly rounded corners
                    fontWeight: "bold", // Emphasize category name
                    textAlign: "center", // Center align text
                  }}
                >
                  {category.name}
                </div>
              </List.Item>
            )}
          />

          <Title level={4} style={{ marginTop: "20px" }}>
            Class Images
          </Title>
          <List
            grid={{ gutter: 16, column: 3 }} // Adjust columns as needed
            dataSource={classEditedData?.classImages}
            renderItem={(img) => (
              <List.Item>
                <Card bordered>
                  <img
                    key={img.id}
                    src={img.url}
                    alt="Class"
                    style={{ width: "100%", marginTop: "10px" }}
                  />
                </Card>
              </List.Item>
            )}
          />

          {/* Slots */}
          <Title level={4} style={{ marginTop: "20px" }}>
            Assigned Slots
          </Title>
          <List
            bordered
            dataSource={classEditedData?.slots}
            renderItem={(slot) => (
              <List.Item>
                <Text strong>{slot.day}</Text>: {slot.startTime} -{" "}
                {slot.endTime}
              </List.Item>
            )}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ClassesManagement;
